.quicklinks__list--loading .quicklinks-modal__container {
    // Loading state variables
    // Margin is only representative of single value (left / right / top / bottom) values that include both left and right will have margin * 2 to represent both sides of the margin
    $margin: 5px;
    $width: 92px;
    $height: 92px;
    $padding-left: 10px;
    $padding-top: 10px;
    $border-width: 2px;
    $circle-radius: 20px;

    $title-background: (
        // Gray box 17px high
        'background-item': linear-gradient($grey-10 17px, transparent 0),
        // Put the box in the center horizontally, and 5px under the circle (including padding, margin, borders etc)
        'background-position': center $padding-top + $margin + $border-width +
            $circle-radius * 2 + 5px,
        // Make the box 17px high and take the full width of the box minus the padding, border and margins
        'background-size':
            calc(100% - $padding-left * 2 - $border-width * 2 - $margin * 2)
            17px
    );

    $circle: (
        // Gray circle with the circle radius
        'background-item':
            radial-gradient(circle $circle-radius, $grey-10 99%, transparent 0),
        // Put the circle in the center horizontally and vertically under the box border with padding
        'background-position': center $padding-top + $margin + $border-width,
        // Size of the circle is 2 * radius to get the diameter
        'background-size': $circle-radius * 2 $circle-radius * 2
    );

    $inner: (
        // Height of the box should be the height of the entire card minus the margin and border width
        'background-item':
            linear-gradient(
                $white $height - 2 * ($margin + $border-width),
                transparent 0
            ),
        // Put the box in the center both vertically and horizontally
        'background-position': center center,
        // Size of the box should be the inner size of the card
        'background-size': $width - $margin * 2 - $border-width * 2 $height -
            $margin * 2 - $border-width * 2
    );

    $outline: (
        // Outline should be gray and 100% of the background size
        'background-item': linear-gradient($grey-10 100%, transparent 0),
        // Center the border
        'background-position': center,
        // Make the border take up the full size of the card minus the margins
        'background-size': $width - 2 * $margin $height - 2 * $margin
    );

    // Loading Background
    $loading-backgrounds: ($title-background, $circle, $inner, $outline);

    @include pnp-loading($width, $height, $loading-backgrounds);
}
