@mixin slider {
    $grandparent: &;
    @include pnp-print-hide;
    position: relative;
    width: 100%;
    min-width: 100%;
    max-width: 100vw;
    overflow: hidden;

    &--bg-fixed {
        @include pnp-background-fixed(600px);
    }

    &__inner {
        position: relative;
        left: -100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        &--transitioning {
            transition: transform var(--slider-animation-duration);
            @include pnp-media-prefers-reduced-motion {
                transition: none;
            }
        }

        &--transition-left {
            transform: translateX(-100%);
        }

        &--transition-right {
            transform: translateX(100%);
        }

        &--transitioning-any {
            #{$grandparent}-item {
                opacity: 0;

                &--active {
                    opacity: 1;
                }
            }
        }
    }

    &__icon-text {
        @include pnp-visuallyhidden;
    }
}

@mixin slider-item {
    z-index: $carousel-nonactive-slide;
    flex-shrink: 0;
    width: 100%;
    color: $white;
    background: $primary-a;
    transition: opacity var(--slider-animation-duration);

    @include pnp-media-prefers-reduced-motion {
        transition: none;
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $carousel-overlay;
        width: 100%;
        height: 100%;
        content: '';
    }

    &--active {
        z-index: $carousel-item;
        opacity: 1;
    }

    &:not(&--active) {
        a {
            visibility: hidden;
        }

        button {
            visibility: hidden;
        }
    }

    &__contents-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0 15px;
        overflow: hidden;
    }

    &__contents {
        z-index: $carousel-content;
        padding: 32px 45px;
    }

    // Generate our order selectors for up to 10 slides
    // stylelint-disable-next-line order/order
    $slide-number: 0;
    @while $slide-number <= 10 {
        &[data-slide-order='#{$slide-number}'] {
            order: $slide-number;
        }
        // stylelint-disable-next-line order/order
        $slide-number: $slide-number + 1;
    }
}

@mixin slider-button {
    @include pnp-reset-button;
    z-index: $carousel-controls;
    padding: 0.25rem;
    cursor: pointer;
    pointer-events: auto;

    @include pnp-media-breakpoint-up(md) {
        padding: 0.5rem;
    }

    @include pnp-media-breakpoint-up(xl) {
        padding: 1rem;
    }

    svg {
        width: 32px;
        height: 32px;
        fill: $white;
    }

    &--previous {
        transform: scale(-1);
    }

    &-group {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        height: 100%;
    }
}
