.autocomplete-search {
    &__combobox-wrapper {
        position: relative;
        display: inline-block;
    }

    &__status {
        @include pnp-visuallyhidden;

        &[data-active='false'] {
            display: none;
        }
    }
}

.autocomplete-search-listbox {
    @include pnp-list-reset;
    position: absolute;
    z-index: $autocomplete-listbox;
    display: none;
    width: 100%;
    min-width: 280px;
    background: $white;
    box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.15);

    &[data-active='true'] {
        display: block;
    }

    &__item {
        @include pnp-typeface(text-16, false);
        margin: 0;
        padding: 0.25rem 0.5rem;
        cursor: default;

        @include pnp-media-breakpoint-up(lg) {
            @include pnp-typeface(text-18, false);
        }

        &:hover,
        &:focus,
        &[aria-selected='true'] {
            background: $primary-a-light;
        }
    }
}

.autocomplete-search[data-emphasis='query'] .autocomplete-search-listbox {
    &__query {
        @include pnp-typeface(text-16-bold, false);

        @include pnp-media-breakpoint-up(lg) {
            @include pnp-typeface(text-18-bold, false);
        }
    }
}

.autocomplete-search[data-emphasis='notquery'] .autocomplete-search-listbox {
    &__item {
        @include pnp-typeface(text-16-bold, false);

        @include pnp-media-breakpoint-up(lg) {
            @include pnp-typeface(text-18-bold, false);
        }
    }

    &__query {
        @include pnp-typeface(text-16, false);

        @include pnp-media-breakpoint-up(lg) {
            @include pnp-typeface(text-18, false);
        }
    }
}
