@use 'sass:math';

.mega-menu-wrapper {
    width: 100vw;
    margin-top: 5px;
    background: $grey-10;

    // See below for style variations
}

.mega-menu {
    @include pnp-svg-icon-classes;

    display: flex;
    max-width: 1400px;
    margin: auto;

    &__image-wrapper {
        display: flex;
        flex-shrink: 0;
        align-items: flex-start;
        width: 300px;
        margin-right: 2rem;
    }

    &__image {
        width: 100%;
        height: auto;
        max-height: 300px;
        object-fit: contain;
    }

    &__content {
        flex-shrink: 0;
        width: 300px;
        margin-right: 2rem;
    }

    &__title {
        @include pnp-typeface(heading-32, true);
    }

    &__description {
        @include pnp-typeface(text-16, true);
    }

    &__cta {
        @include pnp-button;
        @include pnp-typeface(text-16, false);
        display: inline-flex;
        align-items: center;
        margin-top: 2rem;

        &[href=''] {
            display: none;
        }

        .svg-icon {
            margin-left: 0.5rem;
        }
    }

    &__nav-list {
        @include pnp-list-reset;
    }

    &__nav-item {
        color: $primary-a;
        break-inside: avoid;
    }

    &__nav-link {
        @include pnp-reset-button();
        @include pnp-typeface(text-16, false);

        display: block;
        color: $primary-a;
        text-decoration: none;

        &:focus {
            outline: solid 2px $primary-a;
            outline-offset: -2px;
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

// Standard single teir version
.mega-menu-wrapper--single-teir .mega-menu {
    padding: 2.5rem 0;

    &__nav-list {
        column-count: 3;
        column-fill: balance;
    }

    &__nav-item {
        position: relative;

        .svg-icon {
            position: absolute;
            top: 23px;
            left: 0;
        }
    }

    &__nav-link {
        padding: 20px;
        padding-left: 28px;
    }
}

// Double teir menu variation
.mega-menu-wrapper--double-teir,
.mega-menu-wrapper--triple-teir {
    overflow: hidden;

    .mega-menu__nav-list {
        min-height: 100%;
    }

    .mega-menu__nav-list--level-1 {
        position: relative;
        width: math.div($max-width, 6);
        min-height: var(--subNavHeightNeeded);
        background: $primary-a-light;
        transition: min-height 0.25s 0s linear;

        @include pnp-media-prefers-reduced-motion {
            transition: none;
            animation: none;
        }

        > .mega-menu__nav-item {
            &:hover,
            &--active,
            &:focus {
                background: $grey-10;

                .mega-menu__nav-list--level-2 {
                    display: block;
                }
            }

            > .mega-menu__nav-link {
                color: $black;
            }

            .mega-menu__nav-link {
                position: relative;
                width: 100%;
                padding: 0.5rem 1.75rem 0.5rem 1rem;
            }
        }
    }

    .mega-menu__nav-list--level-2 {
        > .mega-menu__nav-item {
            &:hover,
            &--active,
            &:focus {
                background: $primary-a-light;

                .mega-menu__nav-list--level-3 {
                    display: block;
                }
            }
        }
    }

    .mega-menu {
        &__nav-link {
            padding: 1rem;
        }
    }

    .mega-menu__link-wrapper {
        position: relative;
        display: flex;
        align-items: center;
    }

    .mega-menu__nav-expand {
        @include pnp-reset-button();

        position: absolute;
        top: 50%;
        right: 10px;
        transform: translate3d(0, -50%, 0);
        pointer-events: none;

        &:focus {
            text-decoration: underline;
        }
    }

    .mega-menu__nav-link {
        .svg-icon {
            display: none;
        }
    }
}

.mega-menu-wrapper--double-teir {
    .mega-menu__nav-list--level-2 {
        position: absolute;
        top: 0;
        left: math.div($max-width, 6);
        display: none;
        width: math.div($max-width, 3);
        column-count: 2;
        column-fill: balance;
        column-gap: 0;
    }
}

.mega-menu-wrapper--triple-teir {
    .mega-menu__nav-list--level-2 {
        position: absolute;
        top: 0;
        left: math.div($max-width, 6);
        display: none;
        width: math.div($max-width, 6);
        column-count: 1;
        column-fill: balance;
        column-gap: 0;
    }

    .mega-menu__nav-list--level-3 {
        position: absolute;
        top: 0;
        left: math.div($max-width, 6);
        display: none;
        width: math.div($max-width, 6);
        column-count: 1;
        column-fill: balance;
        column-gap: 0;
    }
}

// Compact menu variation
.primary-nav-wrapper--compact {
    .mega-menu-wrapper {
        width: auto;
        min-width: math.div($max-width, 2);
        margin-top: 15px;

        .mega-menu {
            padding: 0;
        }
    }

    .mega-menu-wrapper--single-teir {
        padding: 0.75rem 2rem;
    }

    .mega-menu-wrapper.mega-menu-wrapper--level-2-empty {
        min-width: 0;
    }
}
