.grouped-results {
    margin-bottom: pxToRem(50);

    &__navigation {
        display: flex;
        justify-content: space-between;
        margin-bottom: pxToRem(20);
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-18, false);
        text-transform: uppercase;
    }

    &__link {
        @include pnp-typeface(text-18, no-margin);
        color: $black;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__count {
        padding-left: pxToRem(3);
    }
}
