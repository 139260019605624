@import '../../modal/css/global';

.notifications-loader {
    @include pnp-svg-icon-classes;
    @include pnp-button;
}

.notifications-wrapper {
    @include pnp-svg-icon-classes;

    &__action {
        @include pnp-button;
        position: relative;
        padding: 8px 12px;
    }

    &__unread {
        @include pnp-typeface(text-14, false);
        position: absolute;
        top: 6px;
        right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        color: $black;
        background: $utility-light;
        border-radius: 16px;
    }
}

.notification-popover {
    &__dialog {
        z-index: $popover;

        @include pnp-media-breakpoint-down(md) {
            left: 0 !important;
        }
    }
}

.notifications {
    @include pnp-svg-icon-classes;
    @include pnp-typeface(text-14, false);
    @include pnp-standard-box-shadow;

    width: 375px;
    background: $white;
    border: 1px solid $grey-20;

    @include pnp-media-breakpoint-down(md) {
        width: calc(100vw - 24px);
        margin: 0 12px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid $grey-20;
    }

    &__header-button {
        @include pnp-reset-button;
        @include pnp-svg-icon-classes;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $grey-70;
    }

    &__header-button-icon {
        width: 24px;
        height: 24px;
        fill: currentColor;
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-18-bold, false);
    }

    &__body {
        @include pnp-scroll-y;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-height: calc(var(--popover-maxHeight) - 106px);
        padding-bottom: 20px;

        @include pnp-media-breakpoint-down(md) {
            max-height: calc(var(--popover-maxHeight) - 106px);
        }
    }

    &__list {
        @include pnp-list-reset;
        width: calc(100% - 25px);
        margin-right: 25px;
        padding: 20px;
        padding-bottom: 0;
    }

    &__expand-pinned {
        @include pnp-button;
        @include pnp-typeface(text-14, false);
    }

    &__more {
        @include pnp-reset-button;
        @include pnp-link-button;
        display: flex;
        align-items: center;
    }

    &__expand-pinned {
        @include pnp-svg-icon-classes;
        display: flex;
        align-items: center;
        color: $primary-a;
        background: transparent;
        border: unset;

        &:hover,
        &:focus {
            color: $black;
            background: $grey-10;
        }
    }
}

.notification-item {
    &__body {
        position: relative;
    }

    &__pinned,
    &__distance {
        @include pnp-typeface(text-14-bold, false);
        margin-bottom: 10px;
        text-transform: uppercase;
    }

    &__icon {
        @include pnp-reset-button;
        position: absolute;
        top: 0;
        right: -35px;
        color: $grey-20;
        color: $primary-a;
        transform-origin: center;

        &--pinned {
            color: $primary-a;
        }
    }
}

.notification-content {
    margin-bottom: 10px;
    padding: 16px;
    word-break: break-word;
    background: $grey-10;

    &--unread {
        background: rgba($primary-a, 0.2);
        border: 1px solid $primary-a;
    }

    &__header {
        @include pnp-typeface(text-14, true);
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        color: $utility-dark;
    }

    .svg-icon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }

    &__title {
        @include pnp-reset-heading;
    }

    &__subtitle {
        @include pnp-typeface(text-14-bold, false);
        margin-bottom: 4px;
    }

    &__description {
        @include pnp-typeface(text-14, false);
    }
}

.notification-content-modal {
    @include pnp-svg-icon-classes;
    @include pnp-typeface(text-14, false);
    min-width: 300px;
    max-width: 400px;
    padding-bottom: 24px;
    word-break: break-word;

    &__header {
        display: flex;
        align-items: center;
        padding: 24px;
        padding-bottom: 16px;
    }

    .svg-icon {
        margin-right: 8px;
    }

    .svg-icon--small {
        margin-right: 6px;
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-20-bold, false);
    }

    &__body {
        padding: 0 24px;
    }

    &__date-distance {
        @include pnp-typeface(text-14-bold, false);
        margin-bottom: 8px;
        color: $grey-70;
    }

    &__subtitle {
        @include pnp-typeface(text-14-bold, false);
        margin-bottom: 4px;
    }

    &__link {
        display: block;
        margin-top: 12px;
        color: $grey-70;
        text-decoration: none;

        &:focus,
        &:hover {
            text-decoration: underline;
        }
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        padding: 24px;
        padding-bottom: 0;
    }

    &__pin {
        @include pnp-button;
        @include pnp-typeface(text-14, false);
        display: flex;
        align-items: center;
    }
}
