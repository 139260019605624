.search-spelling-suggestions {
    @include pnp-svg-icon-classes;

    margin-bottom: pxToRem(20);

    &__link {
        margin-left: pxToRem(5);
        color: $primary-a;
    }

    svg {
        margin-right: pxToRem(5);
        color: $grey-70;
    }
}
