@mixin pnp-palette-colours($palette) {
    // color can be accessed via currentColor but no such thing exist for the background
    --pnp-palette-bg-color: #{map-get(map-get($pnp-palettes, $palette), bg)};
    /* stylelint-disable prettier/prettier */
    --pnp-palette-bg-color-rgb: #{hexToRGB(        
        map-get(map-get($pnp-palettes, $palette), bg)
    )};
    /* stylelint-enable prettier/prettier */

    color: map-get(map-get($pnp-palettes, $palette), text);
    background: map-get(map-get($pnp-palettes, $palette), bg);
}

@mixin pnp-palette-button($palette) {
    color: map-get(map-get(map-get($pnp-palettes, $palette), buttons), text);
    background: map-get(map-get(map-get($pnp-palettes, $palette), buttons), bg);
    border-color: map-get(
        map-get(map-get($pnp-palettes, $palette), buttons),
        border
    );

    &:hover,
    &:focus {
        color: map-get(
            map-get(map-get($pnp-palettes, $palette), buttons),
            hover-text
        );
        background: map-get(
            map-get(map-get($pnp-palettes, $palette), buttons),
            hover-bg
        );
        border-color: map-get(
            map-get(map-get($pnp-palettes, $palette), buttons),
            hover-border
        );
    }

    &:disabled {
        color: map-get(
            map-get(map-get($pnp-palettes, $palette), buttons),
            disabled-text
        );
    }
}
