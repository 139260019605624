.autocomplete-concierge {
    @include pnp-media-breakpoint-up(md) {
        margin: 0;
    }

    &__input {
        padding: 0.75rem 1.75em;
        border-radius: 10px 0 0 10px;
    }

    &__submit {
        border-radius: 0 10px 10px 0;

        svg {
            width: 32px;
            height: 32px;
        }
    }

    &__form-wrapper {
        border: 0;
    }

    &__form {
        border: 0;
    }

    &__results {
        position: absolute;
        z-index: $auto-complete-concierge;
        width: 50vw;
        min-width: fit-content;
        text-align: left;
        border-radius: 1rem;

        @include pnp-media-breakpoint-up(md) {
            max-width: pxToRem(700);
        }
    }

    &__section {
        padding-top: pxToRem(10);
    }

    // Need to ensure that the styling from global is overridden
    // which is why we added the element as a secondary rule.
    &__section-title,
    .autocomplete-concierge &__section-title {
        @include pnp-typeface(text-16-bold, no-margin);
        color: $primary-a;
        text-transform: uppercase;
    }

    &__search-result-item {
        cursor: pointer;
    }

    .title {
        @include pnp-reset-heading();
        @include pnp-typeface(text-16-bold);
        color: $black;
    }

    .autocomplete-results {
        &__email {
            color: $black;
        }

        &__metadata {
            color: $grey-80;
        }
    }
}
