.cookie-banner {
    @include pnp-standard-box-shadow();
    @include pnp-svg-icon-classes;
    @include pnp-typeface(text-16, false);
    @include pnp-print-hide;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $cookie-banner;
    color: $black;
    background: $grey-10;

    &__contents {
        display: flex;
        flex-direction: column;
        margin: 15px 30px;

        @include pnp-media-breakpoint-up(lg) {
            flex-direction: row-reverse;
            justify-content: space-between;
        }
    }

    &__button {
        @include pnp-button;
        display: flex;
        align-items: center;
        align-self: center;
        color: $primary-a;
        background: $white;

        &:hover,
        &:focus {
            color: $primary-a;
            background: $white;
        }

        .svg-icon {
            width: 26px;
            height: 26px;
        }
    }

    &--palette {
        &-a {
            @include pnp-palette-colours(palette-a);

            .cookie-banner__button {
                @include pnp-palette-button(palette-a);
            }
        }

        &-b {
            @include pnp-palette-colours(palette-b);

            .cookie-banner__button {
                @include pnp-palette-button(palette-b);
            }
        }
    }
}
