.pagination {
    &__item {
        color: $grey-80;
        background-color: $white;

        &:hover,
        &:focus,
        &--current {
            color: $white;
            background-color: $grey-80;
        }
    }
}
