$hamburger-menu: 9999;
$mega-menu: 9999;

$modal-content: 8001;
$modal: 8000;
$popover: 8000;
$popover-background: 7999;

$feature-branch-alert: 7999;
$matrix-warning: 7999;
$fixed-header: 7000;
$fixed-side-nav: 7000;

$feature-branch-picker: 1000;

// Component Level
// This is added to allow for overlapping of components while allowing transitions between the two overlapping components
$component: 2;

$card-text-infront: 3;
$card-image-behind: 2;

$carousel-content: 5;
$carousel-controls: 4;
$carousel-item: 3;
$carousel-nonactive-slide: 2;
$carousel-overlay: 2;

$hero-banner-contents: 5;
$hero-banner-overlay: 2;
$hero-banner-overlap-bottom: 2;

$page-banner-breadcrumb: 4;
$page-banner-contents: 3;
$page-banner-overlay: 2;

$video-modal-trigger-overlay: 2;
$video-result-image-overlay: 2;

$autocomplete-listbox: 10000;

$cookie-banner: 5;
$min-z-index: 1;

$anchor-nav: 4;
$drawer-overlay: 7001;
$widget-grid-mobile-nav: 999;

// React Components
$button-menu: 9999;

// Calendar React App
$calendar-banner: 10;
$calendar-date-picker: 9999;
$calendar-event-focus: 1000;

// Auto complete - Concierge
$auto-complete-concierge: 9999;

// Tabs
$stencils-tabs: 4;

$calendar-loading: 10000;
