.listing {
    @include pnp-svg-icon-classes;

    &--linked-item {
        .listing-item {
            padding: 0;
        }
    }

    &__intro--sr-only {
        @include pnp-visuallyhidden;
    }

    @media print {
        .listing-item {
            border: solid 1px $black;
        }
    }
}

.listing-item {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;
    overflow: hidden;
    color: $black;
    background: $grey-10;

    @include pnp-media-breakpoint-up(lg) {
        flex-direction: row;
    }

    &__link {
        display: flex;
        flex-direction: row;
        width: 100%;
        color: inherit;
        text-decoration: none;
    }

    &__image-wrapper {
        @include pnp-print-hide;
        flex-shrink: 0;
        width: 100%;
        height: 16.25rem;

        @include pnp-media-breakpoint-up(lg) {
            width: 16.25rem;
            height: auto;
        }

        .svg-icon {
            width: 100%;
            height: 100%;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include pnp-media-breakpoint-up(lg) {
            max-width: 16.25rem;
            min-height: 16.25rem;
        }
    }

    &__content {
        flex: 1 1 0;
        width: 100%;
        padding: 1.25rem;
        word-break: break-word;

        @include pnp-media-breakpoint-up(sm) {
            padding: 1.25rem 1.75rem;
        }
    }

    &__title-link {
        display: block;
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    // Header related elements below
    &__header {
        margin-bottom: 1rem;
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-20-bold, false);
        word-break: break-word;

        @include pnp-media-breakpoint-up(lg) {
            @include pnp-typeface(heading-26-bold, false);
        }
    }

    &__subtitle {
        @include pnp-typeface(text-16, false);
        display: flex;
        align-items: center;
        word-break: break-word;

        @include pnp-media-breakpoint-up(lg) {
            @include pnp-typeface(text-18, false);
        }

        &-link {
            color: inherit;
            text-decoration: none;

            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }

        &--highlight {
            color: $primary-a;
        }

        svg {
            margin-right: 0.25rem;
            color: $primary-a;
        }
    }

    &__subtitle-block {
        display: flex;
        align-items: center;
        color: inherit;

        @include pnp-media-breakpoint-up(sm) {
            margin-right: 1rem;
        }

        &--highlight {
            color: $primary-a;
        }

        svg {
            margin-right: 0.25rem;
            color: $primary-a;
        }
    }

    &__date {
        color: $primary-a;
        @include pnp-media-breakpoint-up(lg) {
            position: absolute;
            bottom: 0.75rem;
            left: 0.75rem;
            padding: 0.75rem 1.25rem;
            text-align: center;
            background-color: rgba($grey-10, 0.7);
            backdrop-filter: blur(10px);

            &--top {
                top: 0.75rem;
                bottom: unset;
            }
        }
        @media print {
            position: relative;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: start;
            margin: 0;
            padding: 0;
            color: $black;
            background: transparent;
        }
    }

    &__month {
        margin-right: 0.25rem;
        @include pnp-media-breakpoint-up(lg) {
            @include pnp-typeface(text-14-bold, false);
            display: block;
            margin-right: 0;
        }
    }

    &__day {
        @include pnp-media-breakpoint-up(lg) {
            @include pnp-typeface(text-24, false);
            color: $black;
        }
    }

    // Body related elements below
    &__body {
        @include pnp-typeface(text-16, false);
        margin-bottom: 0.5rem;

        @include pnp-media-breakpoint-up(lg) {
            @include pnp-typeface(text-18, false);
        }
    }

    &__summary {
        min-height: 2rem;
        margin-bottom: 1.5rem;
    }

    &__tags {
        @include pnp-list-reset;
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    &__tag {
        @include pnp-typeface(text-14, false);
        margin: 0 0.5rem 0.5rem 0;
        padding: 0.25rem 1rem;
        color: $primary-a;
        background-color: $white;
    }

    &__action {
        @include pnp-button;
        @include pnp-typeface(text-14, false);

        @include pnp-media-breakpoint-up(lg) {
            @include pnp-typeface(text-16, false);
        }

        &--right {
            float: right;
        }
    }

    // Footer related elements below
    &__footer {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1.5rem;
        padding-top: 1rem;
        border-top: 1px solid $grey-20;
    }

    &__footer-block {
        @include pnp-typeface(text-14, false);
        display: flex;
        align-items: center;
        margin-right: 3rem;
        margin-bottom: 0.25rem;
        color: inherit;

        @include pnp-media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        &--highlight {
            color: $primary-a;
        }

        svg {
            margin-right: 0.25rem;
            color: $primary-a;
        }
    }
}

.listing-item--event {
    @include pnp-media-breakpoint-down(lg) {
        .listing-item__image-wrapper {
            display: none;
        }
    }
    // If the event has no image move the content across the same width as the image
    .listing-item__content {
        margin-left: 16.25rem;

        @include pnp-media-breakpoint-down(lg) {
            margin-left: 0;
        }
    }

    // If it does have an image, dont move it because the image already does that
    .listing-item__image-wrapper + .listing-item__content {
        margin-left: 0;
    }
}

.listing-item--person {
    @include pnp-media-breakpoint-down(lg) {
        .listing-item__image-wrapper {
            position: absolute;
            top: 1.5rem;
            left: 2.5rem;
            width: 5rem;
            height: 5rem;
        }

        .listing-item__image {
            width: 5rem;
            min-height: 5rem;
        }

        .listing-item__header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 5rem;
            margin-left: 6.5rem;
        }

        .listing-item__summary {
            min-height: auto;
        }
    }
    @include pnp-media-breakpoint-down(sm) {
        .listing-item__image-wrapper {
            left: 1.5rem;
        }

        .listing-item__header {
            margin-left: 6rem;
        }
    }
}

.listing-item--social-media,
.listing-item--document {
    .listing-item__image-wrapper {
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        width: 5rem;
        height: 5rem;

        @include pnp-media-breakpoint-up(sm) {
            left: 1.75rem;
        }
    }

    .listing-item__image {
        width: 5rem;
        min-height: 5rem;
    }

    // prettier-ignore
    .listing-item__image-wrapper + .listing-item__content .listing-item__header {
        margin-left: 6rem;
    }

    .listing-item__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 5rem;
    }

    .listing-item__subtitle {
        @include pnp-typeface(text-14, false);
    }
}
