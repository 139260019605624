@import './loading';

@mixin item-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 21px;
}

@mixin item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: calc(9px / 2) 0;
}

.my-courses {
    @include pnp-typeface(text-14, true);
    height: 100%;
    color: $grey-70;

    &__container {
        @include pnp-scroll-y;
        height: calc(100% - 54px);
        background-color: $grey-10;
        border-right: 1px solid $grey-20;
        border-bottom: 1px solid $grey-20;
        border-left: 1px solid $grey-20;
    }

    &__heading {
        @include pnp-component-heading;
    }

    &__card {
        margin: 13px;
        border: 1px solid $grey-10;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.08);

        // Overwrite for height
        &.collapsible-card {
            height: auto;
        }

        .my-courses__content {
            padding: 0;
        }
    }

    &__content {
        // Add border bottom to each of the panels except the last
        > div:not(:last-of-type) {
            border-bottom: 1px solid $grey-10;
        }
    }

    &__show-more-button {
        @include pnp-typeface(text-14, true);
        margin: calc(9px / 2) 0;

        .react-collapse__chevron {
            padding-right: 12px;
        }

        &.react-collapse__button--open {
            order: 3;
        }
    }

    &__show-more-content {
        display: flex;
        flex-direction: column;
    }

    &__assessments {
        @include item-container;
        background-color: transparentize($grey-10, 0.5);
    }

    &__assessments-icon {
        @include pnp-svg-icon-classes;
        flex-shrink: 1;
        padding-right: 12.5px;
        color: $primary-a;
    }

    &__assessments-title-wrapper {
        @include item;
        @include pnp-typeface(text-14-bold, true);
    }

    &__assessments-title {
        flex-grow: 1;
    }

    &__assessment-item {
        @include item;
    }

    &__assessment-name {
        @include pnp-reset-button;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__updates {
        @include item-container;
    }

    &__updates-title-wrapper {
        @include item;
        @include pnp-typeface(text-14-bold, true);
    }

    &__update-item {
        @include item;
    }

    &__update-icon {
        @include pnp-svg-icon-classes;
        // Shrink the icon
        flex-shrink: 1;
        padding-right: 12px;

        .svg-icon {
            width: 20px;
            height: 20px;
        }
    }

    &__update-name {
        flex-grow: 1;
    }

    &__update-link {
        @include pnp-link-text;
    }

    &__footer {
        @include item;
        display: flex;
        flex-wrap: wrap;
        margin: 16px 21px;

        a:first-of-type {
            margin-left: 0;
        }

        a:last-of-type {
            margin-right: 0;
        }
    }

    &__footer-button {
        @include pnp-button;
        @include pnp-svg-icon-classes;
        @include pnp-typeface(text-14, true);
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin: 0 8px;
        padding: 8px 12px;

        .svg-icon {
            padding-right: 8px;
        }

        &--light {
            @include pnp-button-colour-primary-b();
            @include pnp-svg-icon-classes;
            @include pnp-typeface(text-14, true);
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            margin: 0 8px;
            padding: 8px 12px;
            color: $black;
            background-color: $grey-20;

            .svg-icon {
                padding-right: 8px;
            }
        }
    }

    &__footer-link {
        @include pnp-link-text;
        flex-grow: 1;
    }

    &__empty-container {
        @include pnp-typeface(text-16, true);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        @include pnp-media-breakpoint-up(tablet) {
            max-height: 65vh;
        }
    }

    &__empty-content {
        @include pnp-svg-icon-classes;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: darken($grey-10, 2.75%);
        border-top-left-radius: 44px;
        border-bottom-left-radius: 44px;

        .svg-icon {
            width: 60px;
            height: 60px;
            fill: unset;
        }
    }

    &__svg-wrapper {
        margin: 2px;
        padding: 14px;
        background: $white;
        border-top-left-radius: 44px;
        border-top-right-radius: 44px;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 44px;
    }

    &__empty-text {
        padding: 20px 20px 20px 12px;
    }
}

.my-courses-assessment-modal {
    @include pnp-svg-icon-classes;

    &__content {
        min-width: 400px;
    }

    &__header {
        padding: 24px;
        padding-bottom: 16px;
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-20-bold, false);
    }

    &__header-close {
        @include pnp-reset-button;
        position: absolute;
        top: 12px;
        right: 12px;
    }

    &__body {
        padding: 0 24px;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;

        padding: 24px;
    }

    &__close {
        @include pnp-button;
        color: $black;
        background: $grey-10;
        border-color: $grey-10;

        &:hover,
        &:focus {
            color: $black;
            background: $grey-20;
            border-color: $grey-20;
        }
    }

    &__link {
        @include pnp-button;
        margin-left: 8px;
        outline-offset: -2px;

        &:hover,
        &:focus {
            border-color: $primary-a;
        }

        .svg-icon {
            margin-right: 8px;
        }
    }

    &__due {
        @include pnp-typeface(text-14-bold, false);
        position: relative;
        margin-bottom: 16px;
        padding: 16px;
        background: $primary-a-light;

        .svg-icon {
            position: absolute;
            top: 8px;
            right: 8px;
            color: $primary-a;
        }
    }

    &__due-title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-18-bold, false);
        margin-bottom: 4px;
    }

    &__sub-title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-18-bold, false);
        margin-bottom: 8px;
    }

    &__description {
        @include pnp-typeface(text-14, false);
    }

    &__submit-method {
        @include pnp-typeface(text-14, false);
        display: flex;
        align-items: center;
        margin-top: 20px;
        padding: 12px;
        background: $grey-10;

        svg {
            margin-right: 8px;
        }
    }

    &__submit-note {
        @include pnp-typeface(text-14, false);
        margin-top: 16px;
        font-style: italic;
    }
}
