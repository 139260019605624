.facet-breadcrumb {
    &__item {
        margin-right: pxToRem(20);
        margin-bottom: pxToRem(20);
        list-style: none;
    }

    &__link {
        display: block;
        width: fit-content;
        padding: pxToRem(20) pxToRem(20);
        color: inherit;
        text-decoration: none;
        background-color: transparent;
        border: 1px solid #b9b9b9;
        border-radius: 2.0625rem;

        .svg-icon {
            width: pxToRem(20);
            height: pxToRem(20);
            margin-right: pxToRem(5);
            color: $primary-a;
            vertical-align: middle;
            fill: currentColor;
        }
    }
}
