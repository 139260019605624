.featured-news-listing {
    &__link-all {
        @include pnp-link-text;
        display: flex;
        justify-content: flex-end;
    }

    &__list {
        @include pnp-list-reset();
        @include pnp-media-breakpoint-up(md) {
            display: grid;
            grid-auto-rows: minmax(340px, auto);
            grid-template-columns: repeat(2, 1fr);
            gap: 1.25em;
        }

        @include pnp-media-breakpoint-up(xl) {
            grid-template-columns: repeat(3, 1fr);
        }

        + .pagination {
            margin-top: 1.25rem;
        }
    }
}

.featured-news-listing--fixed-height {
    .featured-news-listing__list {
        grid-auto-rows: 520px;
    }

    .featured-news-listing-item {
        &__tags {
            flex-wrap: nowrap;
            max-height: 32px; // 1 lines
            overflow: hidden;
        }

        &__title {
            max-height: 108px; // 3 Lines
            overflow: hidden;
        }

        &__content {
            max-height: 78px; // 3 lines
            overflow: hidden;
        }
    }

    // If no image is being shown more lines can be allowed
    .featured-news-listing-item--image-none,
    .featured-news-listing-item--image-right,
    .featured-news-listing-item--image-left {
        .featured-news-listing-item {
            &__title {
                max-height: 180px; // 5 lines
            }

            &__content {
                max-height: 182px; // 7 lines
            }
        }
    }
}

.featured-news-listing-item {
    @include pnp-svg-icon-classes;
    margin-bottom: 1.25rem;
    overflow: hidden;
    color: $black;
    background-color: $grey-10;

    @include pnp-media-breakpoint-up(md) {
        margin: 0;
    }

    &:hover,
    &:focus-within {
        //background: $black;

        .featured-news-listing-item__inner {
            background: rgba(var(--pnp-palette-bg-color-rgb), 0.8);
        }
    }

    // Show six by default
    &:nth-child(n + 6) {
        display: none;
        // On desktop show all (limit via source)
        @include pnp-media-breakpoint-up(xl) {
            display: block;
        }
    }
    // On tablet extend the visibility to first seven
    &:nth-child(-n + 7) {
        @include pnp-media-breakpoint-up(md) {
            display: block;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        cursor: pointer;

        &:hover,
        &:focus-within {
            .featured-news-listing-item__image {
                transform: scale(1.05);
            }

            .featured-news-listing-item__title {
                text-decoration: underline;
            }
        }
    }

    &__image-wrapper {
        flex-shrink: 0;
        height: 210px;
        overflow: hidden;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s ease;

        @include pnp-media-prefers-reduced-motion {
            transition: none;
            animation: none;
        }
    }

    &__body {
        position: relative;
        flex: 1 1 0;
        padding: 0.75rem 1.25rem 1.25rem;
    }

    &__top-bar {
        @include pnp-typeface(text-14, false);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 0.75rem;

        .svg-icon {
            margin-right: 0.25rem;
        }
    }

    &__published {
        display: flex;
        align-items: center;
        margin-top: 0.5rem;
    }

    &__tags {
        @include pnp-list-reset;
        display: flex;
        flex-wrap: wrap;
        margin-left: -0.5rem;
    }

    &__tag {
        @include pnp-typeface(text-14-bold, false);
        display: flex;
        flex-wrap: wrap;
        height: 1.625rem;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        padding: 0.25rem;
        overflow: hidden;
        background-color: $utility-light;
    }

    &__link {
        color: inherit;
        text-decoration: none;
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-26-bold, false);
        margin-bottom: 0.75rem;
    }

    &__content {
        @include pnp-typeface(text-18, true);

        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__cta-spacer {
        margin-bottom: 2.75rem;
    }

    &__cta {
        @include pnp-print-hide;
        @include pnp-typeface(text-18, false);
        position: absolute;
        bottom: 1.25rem;
        text-decoration: underline;
    }

    &--palette {
        &-a {
            @include pnp-palette-colours(palette-a);
        }

        &-b {
            @include pnp-palette-colours(palette-b);
        }
    }
}

.featured-news-listing-item:first-child {
    position: relative;
    @include pnp-media-breakpoint-up(md) {
        grid-row: 1 / 3;
        grid-column: 1 / 3;
    }

    .featured-news-listing-item {
        &__inner {
            padding: 0;
        }

        &__image-wrapper {
            width: 100%;
            height: 100%;
        }

        &__body {
            position: absolute;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            width: 100%;
            height: 50%;
            margin-bottom: 0;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 24.85%,
                #000 100%
            );
        }

        &__top-bar {
            width: 100%;
        }

        &__published {
            color: $white;
        }

        &__title {
            margin-bottom: 0;
            color: $white;
        }

        &__content,
        &__cta-spacer,
        &__cta {
            display: none;
        }
    }
}

.featured-news-listing-item--image-left:not(.featured-news-listing-item--featured) {
    @include pnp-media-breakpoint-up(md) {
        .featured-news-listing-item__inner {
            flex-direction: row;
        }

        .featured-news-listing-item {
            &__image-wrapper {
                flex: 1 1 0;
                min-width: 50%;
                height: 100%;

                + .featured-news-listing-item__body {
                    max-width: 50%;
                    overflow: hidden;
                }
            }
        }
    }
}

.featured-news-listing-item--image-right:not(.featured-news-listing-item--featured) {
    @include pnp-media-breakpoint-up(md) {
        .featured-news-listing-item__inner {
            flex-direction: row-reverse;
        }

        .featured-news-listing-item {
            &__image-wrapper {
                flex: 1 1 0;
                min-width: 50%;
                height: 100%;

                + .featured-news-listing-item__body {
                    max-width: 50%;
                    overflow: hidden;
                }
            }
        }
    }
}

.featured-news-listing-item--image-none {
    .featured-news-listing-item__image-wrapper {
        display: none;
    }
}

@media print {
    .featured-news-listing-item:first-child {
        grid-row: 1;
        grid-column: 1;

        .featured-news-listing-item__body {
            position: relative;
            justify-content: flex-start;
            height: auto;
        }
    }
}
