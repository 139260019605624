// Options
//
// Quickly modify global styling by enabling or disabling optional features.

//...
$pnp-enable-negative-margins: false !default;
//...

// Prefix for :root CSS variables

$pnp-variable-prefix: pnp- !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$pnp-spacer: 1rem !default;
$pnp-spacers: (
    0: 0,
    1: $pnp-spacer * 0.25,
    2: $pnp-spacer * 0.5,
    3: $pnp-spacer,
    4: $pnp-spacer * 1.5,
    5: $pnp-spacer * 3,
) !default;

$pnp-negative-spacers: if(
    $pnp-enable-negative-margins,
    pnp-negativify-map($pnp-spacers),
    null
) !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$pnp-grid-breakpoints: (
    xs: 0,
    zoom: 340px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px,
) !default;

@include pnp-_assert-ascending($pnp-grid-breakpoints, '$pnp-grid-breakpoints');
@include pnp-_assert-starts-at-zero(
    $pnp-grid-breakpoints,
    '$pnp-grid-breakpoints'
);

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$pnp-grid-columns: 12 !default;
$pnp-grid-gutter-width: 1.5rem !default;
$pnp-grid-row-columns: 6 !default;

$pnp-gutters: $pnp-spacers !default;

// Container padding

$pnp-container-padding-x: $pnp-grid-gutter-width * 0.5 !default;
