.widget-grid-wrapper {
    @include pnp-svg-icon-classes;

    &--non-default-tab-active {
        @include pnp-media-breakpoint-down(md) {
            .widget-grid {
                display: block;
            }

            .widget-grid__container {
                display: none;

                &--show-mobile-tab {
                    display: block;
                    width: auto;
                    height: 100%;
                }
            }
        }
    }
}

.widget-grid {
    display: grid;
    grid-auto-rows: 0; // Disable implicit rows
    grid-auto-columns: 0; // Disable implicit columns
    grid-template-rows: repeat(6, auto);
    grid-template-columns: 100%;
    row-gap: 10px;

    @include pnp-media-breakpoint-up(md) {
        grid-template-rows: repeat(6, minmax(160px, auto));
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
    }

    @include pnp-media-breakpoint-up(xl) {
        grid-template-rows: repeat(6, minmax(160px, 1fr));
        grid-template-columns: repeat(3, 1fr);
    }

    &--has-tablist {
        @include pnp-media-breakpoint-down(md) {
            margin-bottom: 45px;
        }
    }
}

.widget-grid__container {
    @include pnp-media-breakpoint-down(md) {
        @for $i from 1 to 10 {
            &--startRowMobile-#{$i} {
                grid-row-start: #{$i};
            }
        }

        @for $i from 1 to 10 {
            &--endRowMobile-#{$i} {
                grid-row-end: #{$i};
            }
        }
    }

    @include pnp-media-breakpoint-up(md) {
        @for $i from 1 to 3 {
            &--startColumnTablet-#{$i} {
                grid-column-start: #{$i};
            }
        }

        @for $i from 1 to 3 {
            &--endColumnTablet-#{$i} {
                grid-column-end: #{$i};
            }
        }

        @for $i from 1 to 10 {
            &--startRowTablet-#{$i} {
                grid-row-start: #{$i};
            }
        }

        @for $i from 1 to 10 {
            &--endRowTablet-#{$i} {
                grid-row-end: #{$i};
            }
        }
    }

    @include pnp-media-breakpoint-up(xl) {
        @for $i from 1 to 4 {
            &--startColumnDesktop-#{$i} {
                grid-column-start: #{$i};
            }
        }

        @for $i from 1 to 4 {
            &--endColumnDesktop-#{$i} {
                grid-column-end: #{$i};
            }
        }

        @for $i from 1 to 10 {
            &--startRowDesktop-#{$i} {
                grid-row-start: #{$i};
            }
        }

        @for $i from 1 to 10 {
            &--endRowDesktop-#{$i} {
                grid-row-end: #{$i};
            }
        }
    }

    // Cuff off any overflow and force content to stick to its provided grid size
    > * {
        height: 100%;
        overflow: hidden;
    }

    // Hide the widget container only on mobile
    &--mobile-grid-hidden {
        display: block;
        width: auto;
        @include pnp-media-breakpoint-between(0, md) {
            display: none;
            width: 0;
        }
    }

    // Hide the widget grid only on tablet
    &--tablet-grid-hidden {
        display: block;
        width: auto;
        @include pnp-media-breakpoint-between(md, xl) {
            display: none;
            width: 0;
        }
    }

    // Hide the widget grid only on desktop up
    &--tablet-grid-hidden {
        display: block;
        width: auto;
        @include pnp-media-breakpoint-up(xl) {
            display: none;
            width: 0;
        }
    }
}
