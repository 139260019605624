// These placehoder classes are here so they can be easily added to where you need them, idea being that this module is used elsewhere and not as a stand alone component (although you could if you really wanted to)
@mixin pnp-collapse-title() {
    padding: 0;
    overflow: visible;
    color: inherit;
    font: inherit;
    line-height: inherit;
    text-align: inherit;
    background: none;
    border: 0;
    cursor: pointer;
}

@mixin pnp-collapse-content() {
    position: absolute;
    visibility: hidden;
    transition: height 0.35s ease-in;

    &--open {
        position: static;
        visibility: visible;
    }

    &--collapsing {
        height: 0 !important;
        overflow: hidden;

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }

    &--expanding {
        height: 0;
        overflow: hidden;
        transition: height 0.35s ease-out;

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }
}
