.modal-active {
    // Class to apply to body to prevent scrolling the background while modal open
    overflow: hidden;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $modal;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0);

    &::before {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $modal;
        background: rgba(0, 0, 0, 0.25);
        transition: opacity 0.2s ease-in-out;
        content: '';

        @include pnp-media-prefers-reduced-motion {
            transition: none;
            animation: none;
        }
    }

    &[hidden] {
        display: block;
        transform: translate3d(0, -1px, 0) scale(0);
        visibility: hidden;
        transition: visibility 0s linear 0.2s, transform 0s linear 0.2s;
        @include pnp-media-prefers-reduced-motion {
            transition: none;
            animation: none;
        }
    }

    &[hidden],
    &[hidden]::before {
        display: block;
        transform: translate3d(0, -1px, 0) scale(0);
        visibility: hidden;
        transition: visibility 0s linear 0.2s, transform 0s linear 0.2s;
        @include pnp-media-prefers-reduced-motion {
            transition: none;
            animation: none;
        }
    }

    &[hidden]::before {
        opacity: 0;
        transition: opacity 0.2s ease-in-out, visibility 0s linear 0.2s,
            transform 0s linear 0.2s;
        @include pnp-media-prefers-reduced-motion {
            transition: none;
            animation: none;
        }
    }

    &__content {
        position: relative;
        z-index: $modal-content;
        background: $white;

        /* zoom in from the center */
        //transition: transform 0.2s ease-in-out;
        transition: transform 0.6s cubic-bezier(0.22, 1, 0.36, 1);
        @include pnp-media-prefers-reduced-motion {
            transition: none;
            animation: none;
        }
    }

    &[hidden] .modal__content {
        transform: translate3d(0, -1px, 0) scale(0);
        transition: transform 0s ease-in-out, visibility 0s linear;
        @include pnp-media-prefers-reduced-motion {
            transition: none;
            animation: none;
        }
    }
}
