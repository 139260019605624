/* stylelint-disable selector-no-qualifying-type */

//.custom-form class required on any Custom Form Asset in Matrix to inherit these styles
.custom-form {
    @include pnp-print-hide;

    padding: 1rem;

    input[type='number'],
    input[type='text'],
    input[type='search'],
    input[type='email'],
    textarea,
    select {
        @include pnp-typeface(text-18, true);
        width: 100%;
        max-width: 404px;
        padding: 10px;
        color: $black;
        background: transparent;
        border: 1px solid $grey-80;

        @include pnp-media-breakpoint-up(md) {
            width: 404px;
        }

        &:focus {
            outline: 2px solid $utility-focus;
        }

        &:disabled {
            background: $grey-10;
        }
    }

    input[type='file'] {
        &:focus {
            outline: 2px solid $utility-focus;
            outline-offset: 2px;
        }
    }

    select:not([multiple]) {
        @include pnp-custom-select;
        background-color: transparent;
    }

    select[multiple] {
        @include pnp-typeface(text-18, true);
        color: $black;
    }

    input[type='checkbox']:not(.switch__input) {
        @include pnp-custom-checkbox;
    }

    input[type='radio'] {
        @include pnp-custom-radio-button;
    }

    label {
        @include pnp-typeface(text-18-bold, true);
    }

    ::placeholder {
        @include pnp-typeface(text-18, true);
        color: $grey-70;
    }

    //SQ-Form specific
    .sq-form-question.sq-form-question-file-upload {
        input[type='checkbox'] {
            position: unset;
            width: unset;
            height: unset;
            cursor: unset;
            opacity: unset;
        }
    }

    .sq-form-question-datetime {
        .sq-form-question-answer {
            select {
                width: initial;
                margin-right: 10px;
                margin-bottom: 10px;
                margin-left: 10px;
            }
        }
    }

    .sq-form-question-answer {
        ul {
            @include pnp-list-reset;

            li {
                margin-bottom: 0;
            }
        }
    }

    .sq-form-error {
        display: none;
    }

    .sq-form-question-title {
        @include pnp-typeface(text-16-bold, true);
        display: block;
    }

    .sq-form-question {
        margin-top: 32px;
    }

    .sq-form-question-note,
    .sq-form-required-field-note {
        @include pnp-typeface(text-14, true);
        display: block;
        color: $grey-70;
    }

    .sq-form-question.sq-form-question-error {
        input,
        textarea,
        select {
            color: $utility-alert;
            border-color: $utility-alert;
            outline-color: $utility-alert;
        }

        .sq-form-error {
            @include pnp-typeface(text-14, true);
            display: block;
            margin-top: 3px;
            color: $utility-alert;
            line-height: 18px;
        }
    }

    .sq-form-section {
        margin: 1rem 0;
        padding: 1rem;
        border: 1px solid $black;

        .sq-form-question {
            margin-top: 0;
        }
    }

    .sq-form-section-title {
        @include pnp-typeface(text-24, false);
        margin-top: 1rem;
    }

    button,
    .sq-form-submit,
    ::file-selector-button {
        @include pnp-button;
    }

    // Make sure parent position is relative.
    .input-with-reset {
        @include pnp-reset-button;
        position: absolute;
        top: calc(50% - 1.3rem);
        right: 60px;
        display: none;
        color: $primary-a;
        background-color: $white;
        cursor: pointer;
    }

    .switch {
        // Sizing
        --switch-width: 38px;
        --switch-height: 20px;
        --switch-padding: 2px;

        // animation
        --switch-animation-duration: 0.2s;

        position: relative;
        display: inline-flex;
        flex-shrink: 0;
        align-items: center;
        width: var(--switch-width);
        height: var(--switch-height);
        padding: var(--switch-padding) 0;
        border-radius: 50em;

        &__input,
        &__label {
            position: absolute;
            top: 0;
            left: 0;
        }

        &__input {
            width: 0;
            height: 0;
            margin: 0;
            padding: 0;
            opacity: 0;
            pointer-events: none;

            &:checked + .switch__label {
                background-color: $primary-a;
            }

            &:checked + .switch__label + .switch__marker {
                left: calc(100% - var(--switch-height) + var(--switch-padding));
            }

            &:focus + .switch__label {
                outline: 2px solid $utility-focus;
                outline-offset: 2px;
            }
        }

        &__label {
            z-index: $min-z-index;
            width: 100%;
            height: 100%;
            overflow: hidden;
            color: transparent;
            background-color: $grey-20;
            border-radius: inherit;
            cursor: pointer;
            transition: var(--switch-animation-duration);
            user-select: none;
            @media (prefers-reduced-motion: reduce) {
                transition: none;
            }
        }

        &__marker {
            @include pnp-standard-box-shadow;

            position: relative;
            left: var(--switch-padding);
            z-index: $component;
            width: calc(var(--switch-height) - var(--switch-padding) * 2);
            height: calc(var(--switch-height) - var(--switch-padding) * 2);
            background-color: $white;
            border-radius: 50%;
            transition: left var(--switch-animation-duration);
            pointer-events: none;
            will-change: left;
            @media (prefers-reduced-motion: reduce) {
                transition: none;
            }
        }
    }
}
