/* stylelint-disable selector-max-compound-selectors, max-nesting-depth, selector-no-qualifying-type */
.funnelback-search {
    @include pnp-max-width-container();
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    &__header {
        @include pnp-typeface(text-16, no-margin);
        display: flex;
        flex: 0 0 100%;
        justify-content: flex-end;
        margin-bottom: 20px;
        padding: 12px 20px;
    }

    &__filters-button {
        @include pnp-reset-button();
        @include pnp-svg-icon-classes();
        @include pnp-pull-right();
        justify-content: flex-end;

        .svg-icon {
            margin-left: 6px;
        }
    }

    &__body {
        flex-grow: 1;
    }

    &__side {
        @include pnp-print-hide;
        flex-shrink: 0;
        width: $sidebar-width;
        margin-right: $sidebar-margin;

        @include pnp-media-breakpoint-down(md) {
            width: 100%;
            margin: 0;
        }
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-26-bold, true);
    }

    &__stats-container {
        display: none;
    }

    .custom-form {
        padding: 0;

        select {
            @include pnp-media-breakpoint-up(md) {
                width: 200px;
            }
        }

        .sq-form-field {
            margin-left: 10px;
        }
    }

    .drawer {
        .facet {
            max-width: 100%;
        }
    }

    .drawer__controllers {
        @include pnp-svg-icon-classes;
        display: flex;
        justify-content: space-between;
        padding: 9px 27px;
        background-color: $primary-a-light;

        h2 {
            @include pnp-reset-heading;
            @include pnp-typeface(heading-20-bold, no-margin);
        }
    }

    .drawer__body {
        height: 90%;
    }

    .drawer__close {
        @include pnp-reset-button();
    }
}

.funnelback-search-no-results {
    margin-top: 1rem;

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-20-bold, true);
    }
}

.funnelback-search-result-spellcheck {
    @include pnp-reset-heading;
    @include pnp-typeface(heading-20-bold, true);

    a,
    a:visited {
        color: $primary-a;
    }
}

.funnelback-search-facets-breadcrumb {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0.5rem;

    &__label {
        @include pnp-typeface(text-16, false);
    }

    &__facets-applied {
        display: flex;
        margin: 0;
        margin-left: 0.75rem;
        padding: 0;
        list-style-type: none;
    }

    &__facet {
        display: inline-flex;
        align-items: center;
    }

    &__button {
        @include pnp-reset-button;
        @include pnp-svg-icon-classes;
        @include pnp-typeface(text-14-bold, false);
        padding: 2px 8px;
        color: $black;
        text-decoration: none;
        background-color: $primary-a-light;

        // Added class for raptor merge
        .svg-icon {
            margin-right: 0;
        }
    }

    &__button-all {
        color: $white;
        background-color: $primary-a;
    }

    &__button-reset {
        margin-left: 16px;
        color: $black;
        background-color: $grey-40;
    }
}

.funnelback-search-contextual-navigation {
    @include pnp-media-breakpoint-up(md) {
        @include pnp-make-container();
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-20-bold, true);
    }

    &__sections {
        @include pnp-media-breakpoint-up(md) {
            @include pnp-make-row();
        }
    }

    ul,
    ol {
        margin: 0.75rem 0;
        padding: 0;
        list-style: none;

        @include pnp-media-breakpoint-up(md) {
            margin: 1.5rem 0;
        }
    }

    li {
        @include pnp-typeface(text-18, true);
        position: relative;
        padding-left: 1.25rem;

        @include pnp-media-breakpoint-up(md) {
            padding-left: 2rem;
        }

        &::before {
            position: absolute;
            left: 0.25rem;
            color: $primary-a;

            @include pnp-media-breakpoint-up(md) {
                left: 0.75rem;
            }
        }
    }

    ul {
        li {
            &::before {
                content: '\25CF'; //●
            }

            > ul > li::before {
                content: '\25CB'; //○
            }

            > ul > li > ul > li::before {
                content: '\25CF'; //●
            }

            > ul > li > ul > li > ul > li::before {
                content: '\25CB'; //○
            }
        }
    }
}

.funnelback-search-contextual-navigation-section {
    @include pnp-media-breakpoint-up(md) {
        @include pnp-make-col-ready();
        @include pnp-make-col(4);
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-18-bold, true);
    }
}

.funnelback-search__freestanding-facets {
    @include pnp-media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: center;
    }

    .funnelback-search__header {
        display: none;
    }

    .drawer {
        position: relative;
        visibility: visible;
    }

    .drawer__content {
        position: relative;
        max-width: 100%;
        box-shadow: none;
    }

    .drawer--open-right .drawer__content {
        transform: none;
    }

    .drawer--is-visible {
        transform: none;
    }

    .drawer__controllers {
        display: none;
    }
}
