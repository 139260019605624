.az-index {
    margin-bottom: pxToRem(32);
    border: pxToRem(1) solid $black;

    &__list {
        @include pnp-media-breakpoint-down(lg) {
            justify-content: center;
        }

        &-item {
            width: fit-content;
            min-width: pxToRem(45);
            margin: pxToRem(5);
            text-transform: uppercase;
        }
    }

    &__link {
        padding: pxToRem(16);
        color: $black;
        border: pxToRem(1) solid $black;

        &:hover,
        &:focus {
            color: $white;
            background: $black;
        }
    }

    .active {
        color: $white;
        background: $black;
    }

    .disabled {
        &:hover,
        &:focus {
            color: $black;
        }
    }
}
