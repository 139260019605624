.search-history {
    @include pnp-typeface(text-16, false);
    display: flex;

    @include pnp-media-breakpoint-down(sm) {
        flex-direction: column;
    }

    &__container {
        @include pnp-svg-icon-classes;
        @include pnp-list-reset;

        display: flex;
        flex: 1;
        flex-flow: column wrap;
        align-content: space-between;
        width: auto;
        margin: 10px;
        padding: 10px;
        border: solid 1px $grey-20;
    }

    &__heading {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-20-bold, false);

        .svg-icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
        }
    }

    &__list {
        @include pnp-list-reset;

        display: flex;
        flex: auto;
        flex-flow: column wrap;
        padding: 20px 0;

        &-item {
            padding: 10px 0;
        }
    }

    &__button-clear {
        @include pnp-button;

        max-width: 150px;

        &:hover,
        &:focus {
            @include pnp-button-hover-focus();
        }
    }
}
