:root {
    --drawer-width: 20%;
    --drawer-height: 100%;
    --drawer-transition-speed: 0.3s;
}

.drawer {
    position: fixed;
    top: 0;
    z-index: $drawer-overlay;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0);
    visibility: hidden;
    transition: background-color var(--drawer-transition-speed),
        visibility 0s var(--drawer-transition-speed);

    &--is-visible {
        background-color: rgba($black, 0.75);
        visibility: visible;
        transition: background-color var(--drawer-transition-speed);

        @include pnp-media-prefers-reduced-motion {
            transition: none;
        }
    }

    &--open-left {
        right: auto;
        left: 0;
    }

    &--open-right {
        right: 0;
        left: auto;
    }

    &--open-bottom {
        top: auto;
        bottom: 0;
        left: 0;
    }
}

.drawer__content {
    @include pnp-standard-box-shadow();
    position: absolute;
    top: auto;
    width: 100%;
    max-width: var(--drawer-width);
    height: 100%;
    max-height: var(--drawer-height);
    overflow: hidden;
    background-color: $white;
    transition: transform var(--drawer-transition-speed);
    transition-timing-function: $pnp-ease-in-out;

    @include pnp-media-breakpoint-down(md) {
        max-width: 100%;
    }

    @include pnp-media-prefers-reduced-motion {
        transition: none;
    }

    .drawer--open-left & {
        left: 0;
        transform: translateX(-100%);
    }

    .drawer--open-right & {
        right: 0;
        transform: translateX(100%);
    }

    .drawer--open-bottom & {
        bottom: 0;
        transform: translateY(100%);
    }

    .drawer--is-visible & {
        transform: translateX(0);
        transform: translateY(0);
    }
}

.drawer__controllers {
    display: flex;
    align-items: center;
    padding: pxToRem(10);
    border-bottom: 1px solid $grey-20;
}

.drawer__body {
    @include pnp-scroll-y();
    @include pnp-scroll-x();
    height: 100%;
    padding: pxToRem(10);
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch; // smooth scroll on iOS
}

.drawer__close {
    @include pnp-link-button-size;
    color: inherit;
}
