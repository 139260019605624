@import './loading';

.emails-listing {
    &__heading {
        @include pnp-reset-heading;
        @include pnp-typeface(text-14-bold, false);
        margin-bottom: 10px;
        color: $grey-80;
        text-transform: uppercase;
    }

    &__footer-button {
        @include pnp-svg-icon-classes;
        @include pnp-typeface(text-14, false);
        @include pnp-link-button;
        float: right;
        margin-top: 5px;
        text-decoration: none;

        .svg-icon {
            margin-right: 6px;
        }
    }

    .emails-list {
        @include pnp-list-reset;

        &__item {
            margin-bottom: 10px;

            // Reversing; collapsible have title rendered after chevron, we want to revert.
            &--show-more-button {
                display: flex;
                flex-direction: row-reverse;
                align-items: flex-start;
                justify-content: space-between;
                width: 100%;
            }
        }
    }

    .email-heading {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-width: 0;

        &__title {
            @include pnp-typeface(text-14, false);
            flex-basis: 50%;
            flex-grow: 1;
            overflow: hidden;
            color: $grey-80;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__subtitle {
            @include pnp-typeface(text-12, false);
            flex-basis: 100%;
            overflow: hidden;
            color: $grey-70;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &__from {
            @include pnp-typeface(text-12-bold, false);
            margin-right: 10px;
            padding: 4px;
            color: $grey-70;
            background-color: $grey-10;
        }

        &--not-read {
            &::before {
                color: $utility-focus;
                content: '\25CF'; //●
            }

            .email-heading__from {
                color: $primary-a;
                background-color: rgba(93, 154, 248, 0.15);
            }
        }

        &__link {
            @include pnp-svg-icon-classes;
            @include pnp-typeface(text-14, false);
            @include pnp-link-button;
            color: $grey-70;
        }
    }

    .email-content {
        @include pnp-typeface(text-12, false);
        padding: 10px 0;
    }
}
