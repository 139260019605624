$calendar-loading-header: (
    // Header block
    'background-item':
        linear-gradient(lighten($grey-10, 3%) 100%, transparent 0),
    'background-position': 2px 2px,
    'background-size': calc(100% - 4px) calc(100% - 4px)
);
$calendar-loading-inner: (
    // Inner
    'background-item': linear-gradient($white 100%, transparent 0),
    'background-position': 2px 2px,
    'background-size': calc(100% - 4px) calc(100% - 4px)
);
$calendar-loading-outer: (
    // Outer
    'background-item': linear-gradient($grey-10 100%, transparent 0),
    'background-position': 0 0,
    'background-size': 100% 100%
);
$calendar-loading-date: (
    // Date
    'background-item':
        linear-gradient(lighten($grey-10, 2%) 100%, transparent 0),
    'background-position': calc(100% - 2px) 2px,
    'background-size': 40px 40px
);

$calendar-loading-event-1: (
    // Event line
    'background-item': linear-gradient($grey-10 100%, transparent 0),
    'background-position': 10px 70px,
    'background-size': calc(100% - 20px) 20px
);
$calendar-loading-event-2: (
    // Event line
    'background-item': linear-gradient($grey-10 100%, transparent 0),
    'background-position': 10px 95px,
    'background-size': calc(100% - 50px) 20px
);
$calendar-loading-event-3: (
    // Event line
    'background-item': linear-gradient($grey-10 100%, transparent 0),
    'background-position': 10px 120px,
    'background-size': calc(100% - 70px) 20px
);

.calendar-loading {
    height: 100%;

    &__label {
        @include pnp-visuallyhidden;
    }

    &__card {
        margin-bottom: 12px;
        background: $white;
    }

    &__row {
        display: flex;
        height: calc(20% - 18px);
        min-height: 190px;

        &--header {
            height: auto;
            min-height: 0;
        }
    }

    &__shadow-header {
        $shadow-header-loading-backgrounds: (
            $calendar-loading-header,
            $calendar-loading-outer
        );

        @include pnp-loading(100%, 35px, $shadow-header-loading-backgrounds);
        animation: none;
    }

    &__shadow {
        &:nth-child(odd) {
            $shadow-backgrounds-odd: (
                $calendar-loading-event-1,
                $calendar-loading-event-2,
                $calendar-loading-event-3,
                $calendar-loading-date,
                $calendar-loading-inner,
                $calendar-loading-outer
            );

            @include pnp-loading(100%, 100%, $shadow-backgrounds-odd);
            animation: none;
        }

        &:nth-child(even) {
            $shadow-backgrounds-even: (
                $calendar-loading-event-1,
                $calendar-loading-event-2,
                $calendar-loading-date,
                $calendar-loading-inner,
                $calendar-loading-outer
            );

            @include pnp-loading(100%, 100%, $shadow-backgrounds-even);
            animation: none;
        }
    }

    &__shadow--empty {
        $shadow-backgrounds-empty: (
            $calendar-loading-inner,
            $calendar-loading-outer
        );

        @include pnp-loading(100%, 100%, $shadow-backgrounds-empty);
        animation: none;
    }
}
