@import './loading';

[data-component='bookmarks'] {
    height: 100%;
}

.quicklinks {
    &__container {
        padding: 14px;
    }

    &__list {
        @include pnp-scroll-x;
        display: flex;
    }

    &__link {
        width: 75px;
        height: 75px;

        &:not(:last-child) {
            margin-right: 15px;
        }
    }

    &__link-icon {
        display: flex;
        justify-content: center;
        padding-bottom: 10px;
    }

    &__button {
        @include pnp-reset-button;
        @include pnp-svg-icon-classes;
        @include pnp-standard-box-shadow;

        width: 100%;
        height: 100%;
        padding: 10px;
        background-color: $white;
        border: solid 1px $grey-20;

        &--dashed {
            color: $grey-70;
            text-align: center;
            border: 1px dashed $grey-20;
        }

        &:hover,
        &:focus {
            background-color: $grey-10;
        }
    }

    &__link-title {
        @include pnp-typeface(text-14-bold, false);
        overflow: hidden;
        color: $grey-70;
        white-space: nowrap;
        text-align: center;
        text-overflow: ellipsis;
    }

    .heading-icon {
        @include pnp-reset-button;
        line-height: 1;
    }
}

.quicklinks-modal {
    @include pnp-svg-icon-classes;
    @include pnp-media-breakpoint-down(sm) {
        .quicklinks-modal__content {
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        min-width: 400px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        padding-bottom: 16px;
        border-bottom: solid 1px $grey-20;
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-18-bold, false);
    }

    &__subtitle {
        @include pnp-reset-heading;
        @include pnp-typeface(text-14, false);
    }

    &__header-close {
        @include pnp-reset-button;
    }

    &__body {
        padding: 20px 29px;
    }

    &__container {
        display: flex;
        margin-bottom: 0.8rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__edit-button {
        @include pnp-reset-button;
        @include pnp-standard-box-shadow;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 2px 5px;
        background-color: $grey-10;
        border: solid 1px transparent;

        svg {
            display: none;
            width: 20px;
            margin-left: auto;
        }

        &:hover,
        &:focus {
            svg {
                display: block;
            }
        }
    }

    &__edit-button-title {
        max-width: 230px;
        padding-left: 6px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__delete-button {
        @include pnp-reset-button;
        margin: 10px;
        color: $grey-70;

        &:hover,
        &:focus {
            color: $black;
        }
    }

    &__footer-buttons-container {
        width: calc(100% - 44px);
    }

    &__footer {
        display: flex;
        justify-content: flex-start;
        margin-top: auto;
        padding: 24px;
    }

    &__footer-right-aligned .quicklinks-modal__footer-buttons-container {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    &__footer-button {
        @include pnp-reset-button;
        @include pnp-typeface(text-18, false);
        @include pnp-standard-box-shadow;
        display: flex;
        justify-content: center;
        min-width: 90px;
        padding: 13px;
        background-color: $grey-20;
        border: solid 1px transparent;

        &:first-child {
            margin-right: 8px;
        }

        &--save {
            @include pnp-button-colour-primary-a;
        }

        &--dashed {
            width: 100%;
            background-color: transparent;
            border: 1px dashed $grey-20;
        }
    }

    .custom-form {
        padding: 0;

        input,
        .sq-form-question,
        .sq-form-question-title {
            margin-bottom: 0;
            color: $grey-80;
        }

        .sq-form-question-text {
            margin: 0;
        }

        .sq-form-error {
            display: block;
            height: 22px;
            color: $utility-alert;
        }
    }
}
