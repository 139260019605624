.video-modal-wrapper {
    @include pnp-print-hide;
    @include pnp-svg-icon-classes;

    // Dont breakout if its in a content-with-nav layout
    &:not(.page-layout--content-with-nav *) {
        @include pnp-background-breakout;
    }

    &__container {
        @include pnp-max-width-container();
        display: flex;
        flex-direction: column;
        padding: 70px 45px;

        @include pnp-media-breakpoint-down(xl) {
            padding: 55px 45px;
        }

        @include pnp-media-breakpoint-down(md) {
            padding: 35px 30px;
        }

        @include pnp-media-breakpoint-up(xl) {
            flex-direction: row;
            align-items: center;
        }

        // Allows order of text / image to be swapped
        &--text-first {
            flex-direction: column-reverse;
            @include pnp-media-breakpoint-up(xl) {
                flex-direction: row-reverse;

                .video-modal-wrapper__content {
                    margin: 3.5rem 2.5rem 3.5rem 0;
                }
            }

            @include pnp-media-breakpoint-down(xl) {
                .video-modal-wrapper__content {
                    margin-top: 0;
                    margin-bottom: 2rem;
                }
            }
        }
    }

    &__teaser {
        @include pnp-media-breakpoint-up(xl) {
            flex: 1 1 0;

            // Allows the size of the image vs text to be changed
            &--size-one-third {
                + .video-modal-wrapper__content {
                    flex: 2 1 0;
                }
            }

            &--size-two-third {
                flex: 2 1 0;
            }

            &--size-full + .video-modal-wrapper__content {
                display: none;
            }
        }
    }

    &__trigger {
        @include pnp-reset-button;
        @include pnp-ratio16by9-wrapper;
        display: block;
        width: 100%;

        .svg-icon {
            position: absolute;
            top: calc(50% - 32px);
            left: calc(50% - 32px);
        }

        .svg-icon--dark {
            color: $real-black;
        }

        .svg-icon--light {
            color: $white;
        }
    }

    &__image {
        @include pnp-ratio16by9-inner();
        object-fit: cover;
    }

    &__content {
        display: flex;

        flex: 1 1 0;
        flex-direction: column;
        align-items: start;

        @include pnp-media-breakpoint-up(xl) {
            display: block;
            max-width: initial;
            margin: 3.5rem 0 3.5rem 2.5rem;
        }
    }

    &__description {
        @include pnp-typeface(text-18, true);
        max-width: 90vw;

        @include pnp-media-breakpoint-up(xl) {
            max-width: initial;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include pnp-reset-heading;
            @include pnp-typeface(heading-32-bold, true);
        }

        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 0.5rem;
    }

    &__action {
        @include pnp-typeface(text-18, true);
        color: inherit;
        text-align: center;

        @include pnp-media-breakpoint-up(md) {
            width: auto;
            margin: 0;
            margin-top: 1rem;
            margin-right: 2rem;
            text-align: inherit;
        }

        &:first-child {
            @include pnp-button;
            display: inline-flex;
            align-items: center;
            margin-right: 2rem;

            .svg-icon {
                display: inline-block;
            }
        }

        .svg-icon {
            display: none;
            margin-left: 1rem;
        }
    }

    &--palette {
        &-a {
            @include pnp-palette-colours(palette-a);

            .video-modal-wrapper__action:first-child {
                @include pnp-palette-button(palette-a);
            }
        }

        &-b {
            @include pnp-palette-colours(palette-b);

            .video-modal-wrapper__action:first-child {
                @include pnp-palette-button(palette-b);
            }
        }
    }
}

.video-modal {
    &__content {
        width: 100vw;
        max-width: calc(1.56 * 100vh);
        margin-top: 64px;
        padding: 0;

        @include pnp-media-breakpoint-up(sm) {
            width: 95vw;
            margin-top: 0;
        }
        @include pnp-media-breakpoint-up(xl) {
            width: 80vw;
        }
    }

    &__header {
        @include pnp-visuallyhidden;
    }

    &__body {
        color: $white;
        background: $black;
    }

    &__video-wrapper {
        @include pnp-ratio16by9-wrapper();

        // Adjust the size for phones in landscape
        @media (orientation: landscape) and (max-height: $sm - 0.02px) {
            height: 65vh;
            padding-bottom: 0;
        }
    }

    &__video {
        @include pnp-ratio16by9-inner();
    }

    &__wysiwyg {
        @include pnp-typeface(text-18, false);
        padding: 1rem 1.5rem;

        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }

        a {
            color: inherit;
        }
    }

    &__audio-description-link {
        @include pnp-typeface(text-18, false);
        padding: 1rem 1.5rem;
        color: inherit;
    }

    &__close {
        @include pnp-reset-button;
        position: absolute;
        top: -64px;
        right: 0;
        color: $white;

        @include pnp-media-breakpoint-up(xl) {
            top: 0;
            right: -64px;
        }
    }
}
