.related-links {
    margin: pxToRem(50) 0;
    box-shadow: none;

    &__heading {
        @include pnp-typeface(heading-20-bold, no-margin);
        color: $black;
        text-align: center;
    }

    &__list {
        max-width: 80%;
        margin: pxToRem(50) auto 0;
        text-align: left;
        @include pnp-media-breakpoint-up(lg) {
            position: relative;
            column-count: 2;

            &::before {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                width: 1px;
                background-color: $grey-20;
                content: '';
            }
        }
    }

    &__item {
        list-style: none;
    }

    &__link {
        @include pnp-typeface(text-18, no-margin);
        color: $black;
        line-height: pxToRem(50);
        text-decoration: none;
        @include pnp-media-breakpoint-up(lg) {
            padding-left: 6.25rem;
        }

        strong {
            display: contents;
        }
    }
}
