.collapsible-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    background-color: $white;

    &__header {
        @include pnp-typeface(text-14, false);
        @include pnp-component-heading;
    }

    &__header-text--sub-title {
        margin-right: 14px;
    }

    &__more-menu-icon {
        color: $grey-80;
        transform: rotate(-90deg);
    }

    &__collapsed {
        transform: rotate(90deg);
    }

    &__contents {
        flex-grow: 1;
        padding: 14px;
        border: 1px solid $grey-20;
        border-top: 0;
    }

    &__contents--scrollable {
        @include pnp-scroll-y;
    }
}
