.search-form {
    @include pnp-svg-icon-classes;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    &__search-field {
        position: relative;
        display: inline-flex;
        align-self: flex-end;
        width: 100%;
        @include pnp-media-breakpoint-up(md) {
            display: flex;
            width: auto;
            margin-right: 12px;
        }

        .sq-form-submit {
            margin-bottom: 12px;
        }
    }

    &__sort {
        width: 100%;
        @include pnp-media-breakpoint-up(md) {
            display: flex;
            width: 20%;
        }
    }

    &--has-input {
        .input-with-reset {
            display: block;
        }
    }

    &__sort {
        input,
        select {
            vertical-align: top;
        }
    }
}
