@mixin button {
    @include pnp-reset-button;
    @include pnp-button(no-style);
    @include pnp-svg-icon-classes;
    padding: 9px 17.5px;
    transition: background-color 0.35s ease-in-out,
        border-color 0.35s ease-in-out, color 0.35s ease-in-out;

    &:disabled {
        cursor: not-allowed;
    }

    .svg-icon {
        &:first-of-type {
            margin-right: 13.5px;
        }

        &:last-of-type {
            margin-left: 13.5px;
        }
    }
}

@mixin pnp-button-dark-bg {
    @include button;
    @include pnp-button-colour-primary-a;
}

@mixin pnp-button-light-bg {
    @include button;
    color: $primary-a;
    background-color: $white;

    // Hover class for display in storybook
    &:hover,
    &:focus,
    &.hover {
        color: $primary-c;
        background-color: $primary-a-superlight;
    }

    &:disabled {
        color: $primary-a;
        background-color: $white;

        &:hover,
        &:focus,
        &.hover {
            color: inherit;
            background-color: inherit;
        }
    }
}

@mixin pnp-button-dark-ghost {
    @include button;
    color: $primary-a;
    background-color: transparent;
    border: 1px solid $primary-a;

    // Hover class for display in storybook
    &:hover,
    &:focus,
    &.hover {
        color: $primary-c;
        border-color: $primary-c;
    }

    &:disabled {
        color: $grey-70;
        border-color: $grey-70;

        &:hover,
        &:focus,
        &.hover {
            color: $grey-70;
            border-color: $grey-70;
        }
    }
}

@mixin pnp-button-light-ghost {
    @include button;
    color: $white;
    background-color: transparent;
    border: 1px solid $white;

    // Hover class for display in storybook
    &:hover,
    &:focus,
    &.hover {
        color: $primary-a-light;
        border-color: $primary-a-light;
    }

    &:disabled {
        color: $grey-20;
        border-color: $grey-20;

        &:hover,
        &:focus,
        &.hover {
            color: $grey-20;
            border-color: $grey-20;
        }
    }
}

@mixin pnp-button-dark-borderless {
    @include button;
    color: $primary-a;
    background-color: transparent;
    border: unset;

    // Hover class for display in storybook
    &:hover,
    &:focus,
    &.hover {
        color: $primary-c;
    }

    &:disabled {
        color: $grey-70;

        &:hover,
        &:focus,
        &.hover {
            color: $grey-70;
        }
    }
}

@mixin pnp-button-light-borderless {
    @include button;
    color: $white;
    background-color: transparent;
    border: unset;

    // Hover class for display in storybook
    &:hover,
    &:focus,
    &.hover {
        color: $primary-a-light;
    }

    &:disabled {
        color: $grey-20;

        &:hover,
        &:focus,
        &.hover {
            color: $grey-20;
        }
    }
}

@mixin pnp-button-colour-primary-a {
    @include button;
    color: $white;
    background-color: $primary-a;

    // Hover class for display in storybook
    &:hover,
    &:focus,
    &.hover {
        color: $white;
        background-color: $primary-c;
    }

    &:disabled {
        color: $grey-40;

        &:hover,
        &:focus,
        &.hover {
            color: $grey-40;
            background-color: $primary-a;
        }
    }
}

@mixin pnp-button-colour-primary-b {
    @include button;
    color: $primary-c;
    background-color: $primary-b;

    // Hover class for display in storybook
    &:hover,
    &:focus,
    &.hover {
        color: $primary-c;
        background-color: $white;
    }

    &:disabled {
        color: $grey-70;

        &:hover,
        &:focus,
        &.hover {
            color: $grey-70;
            background-color: $primary-b;
        }
    }
}

@mixin pnp-button-colour-primary-c {
    @include button;
    color: $white;
    background-color: $primary-c;

    // Hover class for display in storybook
    &:hover,
    &:focus,
    &.hover {
        color: $white;
        background-color: $primary-a;
    }

    &:disabled {
        color: $grey-40;

        &:hover,
        &:focus,
        &.hover {
            color: $grey-40;
            background-color: $primary-c;
        }
    }
}
