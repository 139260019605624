.pagination {
    @include pnp-print-hide;
    @include pnp-svg-icon-classes;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 1.25rem;

    &__list {
        @include pnp-list-reset;
        display: none;

        @include pnp-media-breakpoint-up(md) {
            display: flex;
        }

        &--mobile {
            display: flex;

            @include pnp-media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    &__item {
        @include pnp-typeface(text-14, false);
        min-width: 2.25rem;
        min-height: 2.25rem;
        margin: 0 0.1rem;
        color: $primary-a;
        background-color: $grey-10;
        transition: background-color ease-in 0.2s, color ease-in 0.2s;

        @include pnp-media-breakpoint-up(md) {
            @include pnp-typeface(text-18, false);
            min-width: 2.75rem;
            min-height: 2.75rem;
        }

        &:hover,
        &:focus,
        &--current {
            color: $white;
            background-color: $primary-a;
        }

        &--previous {
            .pagination__link {
                padding: 0 0.75rem;
            }

            .pagination__label {
                margin-left: 0.25rem;
            }

            .svg-icon {
                transform: rotate(180deg);
            }
        }

        &--next {
            .pagination__link {
                padding: 0 0.75rem;
            }

            .pagination__label {
                margin-right: 0.25rem;
            }
        }

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: inherit;
        text-decoration: none;
    }
}
