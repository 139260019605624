.navbar {
    display: flex;
    flex-wrap: wrap; // Better to wrap than hide controls
    margin: 0;
    padding: 0;
    background-color: $black;

    &__item {
        @include pnp-typeface(text-18, false);
        @include pnp-svg-icon-classes;
        @include pnp-standard-transition;
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        color: $white;
        text-decoration: none;
        background-color: transparent;
        border: 0;

        &:hover,
        &:focus {
            color: $black;
            background-color: rgba(240, 240, 240, 0.8);
        }

        &--active {
            color: $black;
            background-color: $grey-10;
            border-bottom: 1px solid $grey-10;
        }

        &--disabled {
            color: $grey-10;
        }
    }

    &__icon {
        margin-right: 0.375rem;
    }

    // Overrides for when radio facets are embedded in the narbar.
    .facet-group__title {
        display: none;
    }

    .funnelback-facet {
        margin-bottom: 0;
        margin-left: auto;
        padding: 0.5rem 1rem;
    }

    .facet-group__list-item {
        margin-bottom: 0;
        color: $white;
    }

    .facet-group__results-number {
        display: none;
    }

    .funnelback-search__filters-button.navbar__item {
        margin-left: 0;
    }

    &__seperator {
        align-self: center;
    }
}
