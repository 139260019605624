.visuallyhidden {
    @extend %screen-hide;
}

// When screen hidden content is focused restore it to view
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    @extend %screen-show;
}

// Bootstrap friendly class
.sr-only {
    @extend %screen-hide;
}

// Hide from both screenreaders and browsers: h5bp.com/u
.hidden {
    display: none !important;
    visibility: hidden;
}

// Hide visually and from screenreaders, but maintain layout
.invisible {
    visibility: hidden;
}

/* stylelint-disable selector-no-qualifying-type */
// Float classes
.pull-right {
    justify-content: flex-end;
}

.pull-left {
    justify-content: flex-start;
}

img.pull-left {
    margin-right: 1em;
}

img.pull-right {
    margin-left: 1em;
}
/* stylelint-enable selector-no-qualifying-type */

// 16:9 Ratio container for embedded video
// This container is suitable for something like youtube videos
.video-container {
    position: relative;
    height: 0;
    margin-bottom: 1em;
    padding-top: 30px; // 30px controls container
    padding-bottom: 56.25%; // Video area
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
