// Best Practice Article: https://central.squiz.net/imp/best-practice/accessibility/screen-hidden-content
// Prevent an element from being displayed.
%screen-hide {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
}

// Restore to view
%screen-show {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}
