.related-links {
    @include pnp-svg-icon-classes();
    @include pnp-standard-box-shadow();

    margin: 24px 4px;
    padding: 19px;

    &__heading {
        @include pnp-typeface(heading-26-bold, false);
        margin: 3px 0 0;
        font-style: normal;
    }

    &__list {
        @include pnp-list-reset();
    }

    &__link {
        @include pnp-typeface(text-16, false);
        @include pnp-link-text();
        display: inline-block;
        align-items: center;
        padding: 7px 0;
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .svg-icon {
        margin-right: 10px;
        vertical-align: middle;
        transform: scale(0.8);
    }

    &--palette {
        &-a {
            @include pnp-palette-colours(palette-a);
        }

        &-b {
            @include pnp-palette-colours(palette-b);
        }
    }
}
