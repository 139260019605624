.multicolumn {
    position: relative;
    z-index: $component;
    width: 100%;

    &__container {
        @include pnp-make-container();
    }

    &__content-heading {
        // Empty for theme merge
    }
}

.multicolumn-item {
    &__contents {
        position: relative;
        padding: 32px 45px;

        img {
            width: 100%;
            height: auto;
        }

        &-text > * {
            margin-top: 0;
            padding-top: 0;
        }

        a,
        a:link,
        a:visited {
            color: inherit;
        }
    }
}

.multicolumn-grid--row {
    @include pnp-make-row();

    .multicolumn-item {
        @include pnp-make-col-ready();
        @include no-gutter-first-row(1);
        //margin-top: $pnp-grid-gutter-width; // Add gutter to the top as that is more predictable for removing later
    }
}

.multicolumn-grid--row-automatic .multicolumn-item {
    @include pnp-media-breakpoint-up(sm) {
        @include pnp-make-col(6);
        @include no-gutter-first-row(2);
    }

    @include pnp-media-breakpoint-up(lg) {
        @include pnp-make-col(4);
        @include no-gutter-first-row(3);
        flex-grow: 1;
    }

    @include pnp-media-breakpoint-up(xl) {
        @include pnp-make-col(3);
        @include no-gutter-first-row(4);
        flex-grow: 1;

        // prettier-ignore
        @at-root .page-layout--content, .page-layout--content-with-nav {
            .multicolumn-grid--row-automatic .multicolumn-item {
                @include pnp-make-col(4);
                @include no-gutter-first-row(3);
            }

            .multicolumn-grid--row-two .multicolumn-item {
                @include pnp-make-col(6);
                @include no-gutter-first-row(2);
            }
        }
    }
}

.multicolumn-grid--row-two .multicolumn-item {
    @include pnp-media-breakpoint-up(sm) {
        @include pnp-make-col(6);
        @include no-gutter-first-row(2);
    }
}

.multicolumn-grid--row-three .multicolumn-item {
    @include pnp-media-breakpoint-up(sm) {
        @include pnp-make-col(6);
        @include no-gutter-first-row(2);
    }

    @include pnp-media-breakpoint-up(lg) {
        @include pnp-make-col(4);
        @include no-gutter-first-row(3);
    }
}
