.analytics__progress-details--loading {
    // Definitions for loading state
    $circle: (
        // Gray circle with a radius of 35px
        'background-item':
            radial-gradient(circle 35px, $grey-10 99%, transparent 0),
        // Positioned top left
        'background-position': 0 0,
        'background-size': 70px 70px
    );
    $title: (
        // Gray box height of 18px
        'background-item': linear-gradient($grey-10 18px, transparent 0),
        // Offset the box by 70px (circle diamater) + 15px
        'background-position': 85px 0,
        // Set the width of the box to 65px
        'background-size': 65px 18px
    );

    $description: (
        // Gray box height of 14px
        'background-item': linear-gradient($grey-10 14px, transparent 0),
        // Offset the box by 70px (circle diameter) + 15px, offset the height by 20px (18px for the title) + 2px (for padding)
        'background-position': 85px 20px,
        // Make the box 45px wide 14px high
        'background-size': 45px 14px
    );

    $loading-backgrounds: ($circle, $title, $description);
    @include pnp-loading(150px, 70px, $loading-backgrounds);
}
