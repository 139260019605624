.status-message {
    @include pnp-svg-icon-classes;

    display: flex;
    align-items: center;
    justify-content: center;

    &__body {
        position: relative;
        display: flex;
        align-items: center;
        min-width: 300px;
        max-width: 350px;
        min-height: 95px;
        margin-left: 50px;
        padding: 10px 25px 10px 55px;
        background: $grey-10;
    }

    &__icon {
        position: absolute;
        top: 0;
        left: -50px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 95px;
        background: $white;

        border: 2px solid $grey-10;
        border-radius: 360px;
        border-bottom-right-radius: 180px;
        aspect-ratio: 1/1;

        .svg-icon {
            color: $grey-40;
        }

        &-block {
            position: absolute;
            right: 12px;
            bottom: 12px;
            background: $white;
        }
    }

    &__title {
        @include pnp-typeface(text-16-bold, false);
        color: $grey-70;
    }

    &__content {
        @include pnp-typeface(text-16, false);

        > a {
            @include pnp-link-text;
        }
    }
}
