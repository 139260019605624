.listing {
    margin-bottom: pxToRem(30);

    &--linked-item {
        .listing-item {
            padding: 0;
        }
    }

    &--list-view {
        width: 100%;
    }
}

.listing-item {
    // Ensure that listing items on mobile take up the full width of the screen.
    margin-right: pxToRem(-10);
    margin-left: pxToRem(-10);
    border-radius: 0;

    @include pnp-media-breakpoint-up(sm) {
        border-radius: 10px;
    }

    @include pnp-media-breakpoint-up(md) {
        // Ensure that listing items on mobile take up the full width of the screen.
        margin-right: 0;
        margin-left: 0;
    }

    &__title-link {
        width: fit-content;
    }

    &__promoted-tag {
        @include pnp-typeface(text-14-bold, no-margin);
        margin: 0 0.5rem 0 0;
        padding: 0.25rem 0.5rem;
        color: $white;
        background-color: $grey-80;
        border-radius: 4px;
    }

    &--background {
        @include pnp-generate-background-for-colour-map($pnp-primary-colours);
        @include pnp-generate-background-for-colour-map($pnp-neutral-colours);

        &-shadow {
            @include pnp-standard-box-shadow;
        }
    }

    /* 
        Auto generate text colours based on colour maps.
    */
    &--color {
        @include pnp-generate-foreground-for-colour-map($pnp-primary-colours);
        @include pnp-generate-foreground-for-colour-map($pnp-neutral-colours);
    }

    &__image-wrapper {
        display: block;
        width: 100%;
        height: pxToRem(260);
        overflow: hidden;

        @include pnp-media-breakpoint-up(lg) {
            width: pxToRem(260);
            height: inherit;
            max-height: none;
        }
    }

    &__image {
        width: 100%;
        max-width: none;
        height: 100%;
        max-height: none;
        object-fit: cover;
    }

    &__time {
        margin-right: 0.25rem;
    }

    &__content {
        display: flex;
        flex: 1 1 0;
        flex-direction: column;
        width: 100%;
        padding: pxToRem(20);
        word-break: break-word;

        @include pnp-media-breakpoint-up(sm) {
            padding: 1.25rem 1.75rem;
        }
    }

    // Header related elements below
    &__subtitle {
        svg {
            margin-right: pxToRem(5);
        }

        .fab,
        .far,
        .fas {
            margin-right: pxToRem(5);
            color: $primary-a;
        }
    }

    &__subtitle-block {
        display: flex;
        align-items: center;
        color: inherit;

        @include pnp-media-breakpoint-up(sm) {
            margin-right: 1rem;
        }

        svg {
            margin-right: pxToRem(5);
        }
    }

    &__date {
        color: $primary-a;
        @include pnp-media-breakpoint-up(lg) {
            position: absolute;
            bottom: 0.75rem;
            left: 0.75rem;
            padding: 0.75rem 1.25rem;
            text-align: center;
            background-color: rgba($grey-10, 0.7);
            backdrop-filter: blur(10px);

            &--top {
                top: 0.75rem;
                bottom: unset;
            }
        }
    }

    &__tag {
        padding: 0.375rem 0.9375rem;
        color: $primary-a;
        border-radius: 32px;
    }

    &__action {
        @include pnp-reset-heading;
        @include pnp-typeface(text-18-bold);
        margin-top: pxToRem(10);
        color: $primary-a;
        background-color: inherit;
        border: 0;
    }

    // Footer related elements below
    &__footer {
        margin-top: auto;
    }

    &__footer-block {
        margin-bottom: pxToRem(10);
        @include pnp-media-breakpoint-up(lg) {
            margin-bottom: 0;
        }

        .far,
        .fas,
        .fab {
            margin-right: pxToRem(10);
            color: $primary-a;
        }
    }
}

/**
    Provide an overlay over video results to mute the img and provide a
    subtle animation to signify that it is a special type of result.
*/
.listing-item--video {
    .listing-item__image-wrapper {
        position: relative;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: $video-result-image-overlay;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
            content: '';
        }

        &:hover .listing-item__image,
        &:focus .listing-item__image {
            transform: scale(1.2);
        }

        .listing-item__image {
            transition: transform 0.3s ease;
        }

        @media screen and (prefers-reduced-motion: reduce) {
            .listing-item__image {
                transition: none;
            }
        }
    }
}

// Styling for the card view

.listing__items {
    &--card-view {
        display: flex;
        flex-wrap: wrap;

        .listing-item {
            flex-direction: column;
            width: fill-available;
            min-width: 100%;

            @include pnp-media-breakpoint-up(md) {
                width: calc(50% - pxToRem(20));
                min-width: calc(50% - pxToRem(20));
                max-width: calc(50% - pxToRem(20));
                margin-right: pxToRem(20);
            }

            @include pnp-media-breakpoint-up(xl) {
                flex: 0 0 calc(33.333% - pxToRem(20));
                width: calc(33.333% - pxToRem(20));
                min-width: calc(33.333% - pxToRem(20));
                max-width: calc(33.333% - pxToRem(20));
            }

            &__title {
                @include pnp-media-breakpoint-up(xl) {
                    @include pnp-reset-heading;
                    @include pnp-typeface(heading-20);
                }
            }

            &__image-wrapper {
                display: block;
                width: 100%;
                height: 16.25rem;
            }

            &__footer {
                flex-direction: column;
            }

            &__footer-block {
                @include pnp-media-breakpoint-up(lg) {
                    margin-bottom: 0.625rem;
                }
            }
        }
    }
    .listing-item--social-media,
    .listing-item--document {
        .listing-item__image-wrapper {
            position: absolute;
            top: 1.5rem;
            left: 1.5rem;
            width: 5rem;
            height: 5rem;

            @include pnp-media-breakpoint-up(sm) {
                left: 1.75rem;
            }
        }

        .listing-item__image {
            width: 5rem;
            min-height: 5rem;
        }

        // prettier-ignore
        .listing-item__image-wrapper + .listing-item__content .listing-item__header {
            margin-left: 6rem;
        }

        .listing-item__header {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 5rem;
        }

        .listing-item__subtitle {
            @include pnp-typeface(text-14, no-margin);
        }
    }
}
