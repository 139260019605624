.add-to-home-modal {
    color: $grey-80;

    @include pnp-media-breakpoint-down(md) {
        .modal__content {
            width: 360px;
        }
    }

    &__header {
        @include pnp-svg-icon-classes;
        display: flex;
        justify-content: flex-end;
        padding: 12px;

        &-button {
            @include pnp-reset-button;
        }
    }

    &__section {
        @include pnp-typeface(text-16, false);
        text-align: center;

        &-heading {
            @include pnp-reset-heading();
            @include pnp-typeface(heading-26-bold, false);
            color: $utility-dark;
        }

        &-image {
            height: 132px;
            background-image: url('~src/files/images/mobile.svg');
            background-position: center;
            background-size: cover;
        }

        &-content {
            padding: 25px;
            word-wrap: break-word;
        }
    }

    &__footer {
        padding: 12px 0;
        text-align: center;
        background-color: $grey-10;

        &-button {
            @include pnp-svg-icon-classes;
            display: inline-block;
            padding: 0 10px;
            color: $primary-a;
        }
    }
}
