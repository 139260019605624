$widget-grid-mobile-nav-height: 50px;

.widget-grid-mobile-nav {
    @include pnp-standard-box-shadow;
    @include pnp-typeface(text-12, true);

    position: fixed;
    bottom: 0;
    left: 0;
    z-index: $widget-grid-mobile-nav;
    display: none;
    justify-content: space-evenly;
    width: 100%;
    height: $widget-grid-mobile-nav-height;
    margin: 0 -6px;
    background-color: $white;

    @include pnp-media-breakpoint-down(md) {
        display: flex;
    }

    &__item {
        @include pnp-reset-button;
        padding: 0 6px;
        color: $grey-70;

        &--current {
            @include pnp-typeface(text-12-bold, true);
            color: $black;

            .widget-grid-mobile-nav__item-inner {
                background-color: $grey-10;
            }
        }

        &:hover,
        &:focus {
            color: $black;

            .widget-grid-mobile-nav__item-inner {
                background: $grey-10;
            }
        }
    }

    &__item-inner {
        @include pnp-svg-icon-classes;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 4px 6px;
        border-radius: 3px;
    }
}
