// Helper function for backgrounds
@function is-map($var) {
    @return type-of($var) == 'map';
}

// Styles for loading states of components
// Select anything with the loading modifier
@mixin pnp-loading($width, $height, $backgrounds) {
    $animation-uuid: 'loading-' + unique-id();
    // Get the background image from the backgrounds list
    $background-image: linear-gradient(
        90deg,
        rgba($white, 0) 0,
        rgba($white, 0.8),
        50%,
        rgba($white, 0) 100%
    );
    // Get the background positions from the backgrounds list
    $background-position: -150% 0;
    // Get the background size from the backgrounds list
    $background-size: 20px 100%;
    // Get the background animation initial value
    $background-animation: 350% 0;

    // Loop through each of our defined backgrounds
    @each $background in $backgrounds {
        $background-image: $background-image,
            map-get($background, 'background-item');
        $background-position: $background-position,
            map-get($background, 'background-position');
        $background-size: $background-size,
            map-get($background, 'background-size');
        $background-animation: $background-animation,
            map-get($background, 'background-position');
    }

    width: $width;
    height: $height;
    background-image: $background-image;
    background-repeat: no-repeat;
    background-position: $background-position;
    background-size: $background-size;
    animation: #{$animation-uuid} 3s infinite;
    @include pnp-media-prefers-reduced-motion {
        animation: none;
    }

    @keyframes #{$animation-uuid} {
        to {
            background-position: $background-animation;
        }
    }
}
