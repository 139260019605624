.accordion {
    @include pnp-svg-icon-classes;

    &__header {
        display: flex;
        justify-content: flex-end;
    }

    &__toggle-all {
        @include pnp-button;
        margin-bottom: 0.75rem;
        color: $primary-a;
        background-color: $white;
        border-color: $primary-a;

        @include pnp-media-breakpoint-down(md) {
            @include pnp-typeface(text-16, false);
        }
    }
}

.accordion-item {
    margin-bottom: 0.75rem;

    &__header {
        @include pnp-reset-heading;
        position: relative;
        display: block;
        border: solid 1px $grey-40;
    }

    &__control {
        @include pnp-reset-button;
        @include pnp-typeface(heading-18, false);
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 1rem;

        @include pnp-media-breakpoint-down(md) {
            @include pnp-typeface(text-16, false);
        }

        &:hover,
        &:focus {
            background: $grey-10;
        }

        .svg-icon {
            flex-shrink: 0;
        }

        .svg-icon--collapse {
            display: none;
        }
    }

    &__content {
        @include pnp-collapse-content();
        @include pnp-typeface(text-18, false);
        padding: 0.75rem 1.5rem;
        border: solid 1px $grey-40;
        border-top: 0;

        @include pnp-media-breakpoint-down(md) {
            @include pnp-typeface(text-16, false);
        }

        > :first-child {
            margin-top: 0;
        }

        > :last-child {
            margin-bottom: 0;
        }
    }
}

.accordion-item--active {
    .svg-icon--expand {
        display: none;
    }

    .svg-icon--collapse {
        display: block;
    }
}

@media print {
    .accordion-item__content {
        position: inherit;
        visibility: visible;
    }
}
