.tabs {
    &--full-width {
        .tab-list__nav {
            display: flex;
        }
    }

    &--center {
        .tab-list__nav {
            display: flex;
            justify-content: center;
        }
    }

    &--left {
        .tab-list__nav {
            display: flex;
            justify-content: flex-start;
        }
    }

    &--right {
        .tab-list__nav {
            display: flex;
            justify-content: flex-end;
        }
    }
}

%tab-button {
    @include pnp-svg-icon-classes;
    @include pnp-reset-button;
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: 0 1rem;
    padding: 13px 0;
    white-space: nowrap;

    .svg-icon {
        margin-left: 1em;
    }
}

.tab {
    &__content {
        position: absolute;
        visibility: hidden;

        &--active {
            position: static;
            visibility: visible;
        }
    }

    &__button {
        @extend %tab-button;

        &::after {
            position: absolute;
            bottom: 0;
            display: block;
            width: 100%;
            height: 6px;
            background-color: transparent;
            transition: background-color 0.3s ease-in-out;
            content: '';
            @include pnp-media-prefers-reduced-motion {
                transition: none;
            }
        }

        &:hover,
        &:focus {
            &::after {
                background-color: $grey-20;
            }
        }

        &--active {
            &::after {
                background-color: $primary-a;
            }

            &:hover,
            &:focus {
                &::after {
                    background-color: $primary-a;
                }
            }
        }

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.tab-list {
    &__nav {
        display: inline-flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: auto;
        max-width: 100%;
        border-bottom: 1px solid $grey-20;
    }
}

.tab-list-nav {
    &__overflow-menu {
        position: absolute;
        right: 0;
        display: flex;
        flex-direction: column;
        max-width: 300px;
        max-height: 0;
        padding: 20px 12px;
        background: $grey-20;
        visibility: hidden;
        transition: max-height 0.3s ease-in-out;
        @include pnp-media-prefers-reduced-motion {
            transition: none;
        }

        &--open {
            @include pnp-scroll-y;
            @include pnp-scroll-x;
            max-height: 40rem;
            visibility: visible;
        }

        .tab__button {
            margin: 0;
            color: $black;
            transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out;
            @include pnp-media-prefers-reduced-motion {
                transition: none;
            }

            &:hover,
            &:focus {
                color: $black;
                text-decoration: underline;
            }
        }
    }
}

.tab-list-nav-overflow-menu {
    &__button {
        @extend %tab-button;
        margin: 0;
        padding: 13px;

        .svg-icon {
            margin: 0;
        }

        &:hover,
        &:focus,
        &--open {
            background: $grey-20;
        }
    }

    &__wrapper {
        position: relative;
        // Note we use display none here as if there is nothing in the overflow menu we don't want it shown to users or screen readers
        display: none;
        margin-left: 1rem;

        &--visible {
            display: block;
        }
    }
}
