
.hero-banner {
    &__contents {
        z-index: $hero-banner-contents;

        @include pnp-media-breakpoint-down(lg) {
            @include pnp-typeface(text-18);
            width: 100%;
        }

        @include pnp-media-breakpoint-up(lg) {
            padding: pxToRem(32) pxToRem(45);
        }
    }
    background: $black;

    &-search {
        &__bar {
            width: 100%;
        }

        &__input,
        .autocomplete-search__input {
            padding: 0.75rem 1.75em;
            border-radius: 10px 0 0 10px;
        }

        &__submit {
            color: $grey-40;
            border-radius: 0 10px 10px 0;

            &:hover,
            &:focus {
                background: $grey-80;
            }

            .svg-icon {
                width: 32px;
                height: 32px;
            }
        }
    }
}
