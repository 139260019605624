@import '../../../styles/imports/shared-imports';

.popover {
    // Removed until decided with Dom what this should look like
    // position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $popover-background;
    background-color: transparentize($real-black, 0.75);

    &__dialog {
        position: var(--popover-position);
        top: var(--popover-top);
        left: var(--popover-left);
        z-index: $popover;
        max-height: var(--popover-maxHeight);
    }
}
