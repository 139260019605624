@use 'sass:math';

.content-modal-wrapper {
    --content-modal-header-height: 0;
    --content-modal-footer-height: 0;
    @include pnp-svg-icon-classes;
}

.content-modal {
    height: calc(100% - 100px);
    margin: 50px 15px;

    &--mobile-full {
        @include pnp-media-breakpoint-down(md) {
            height: 100%;
            margin: 0;

            .content-modal__content {
                width: 100%;
            }
        }
    }

    &--max-width {
        &-25 {
            .modal__content {
                width: 100%;
                max-width: math.div($max-width, 4);
            }
        }

        &-50 {
            .modal__content {
                width: 100%;
                max-width: math.div($max-width, 2);
            }
        }

        &-75 {
            .modal__content {
                width: 100%;
                max-width: math.div($max-width, 4) * 3;
            }
        }

        &-full {
            .modal__content {
                width: 100%;
                max-width: $max-width;
            }
        }
    }

    &__content {
        @include pnp-max-width-container;
        padding: 1.5rem;
        padding-bottom: 1rem;

        &--max-width {
            &-25 {
                width: 100%;
                max-width: math.div($max-width, 4);
            }

            &-50 {
                width: 100%;
                max-width: math.div($max-width, 2);
            }

            &-75 {
                width: 100%;
                max-width: math.div($max-width, 4) * 3;
            }

            &-full {
                width: 100%;
            }
        }
    }

    &__header {
        display: flex;
        margin-right: 24px;
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-20-bold, true);
    }

    &__close {
        @include pnp-reset-button;
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        color: $black;
    }

    &__body {
        @include pnp-scroll-y;
        @include pnp-scroll-x;
        // Total height - header height - footer height - padding on body
        // prettier-ignore
        max-height: calc( var(--content-modal-content-height) - var(--content-modal-header-height) - var(--content-modal-footer-height) - 2.5rem ); // prettier-ignore
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        padding-top: 1rem;
    }

    &__link {
        @include pnp-link-button-size();
        margin-right: 1rem;
    }

    &__button {
        @include pnp-button;

        .svg-icon {
            margin-left: 0.5rem;
        }
    }
}
// If the screen is low on veritical space kill the margin, this can happen on desktop with a high zoom for accessability
@include pnp-media-breakpoint-down(md) {
    @media (min-aspect-ratio: 1/1) {
        .content-modal {
            height: 100%;
            margin: 0;
            overflow: auto;

            &__content {
                padding-top: 0.75rem;
                padding-bottom: 0.5rem;
            }

            &__title {
                margin-bottom: 8px;
            }

            &__body {
                min-height: var(--content-modal-content-height);
            }

            &__footer {
                padding-top: 8px;
            }
        }
    }
}
