.quickview {
    .modal {
        overflow: scroll;
    }

    .modal__content {
        padding: pxToRem(40);
    }

    &__header-top {
        display: flex;
        justify-content: space-between;

        .modal__close {
            @include pnp-svg-icon-classes;
            height: fit-content;
            color: $grey-40;
            background-color: transparent;
            border: 0;
        }
    }

    &__content {
        padding: pxToRem(10) pxToRem(20) pxToRem(30) pxToRem(20);
        background-color: $grey-20;

        @include pnp-media-breakpoint-up(sm) {
            padding: pxToRem(40) pxToRem(40) pxToRem(50) pxToRem(80);
        }
    }

    section {
        position: relative;
    }

    &__description-list {
        // Add a decorative line to the right on large devices.
        @include pnp-media-breakpoint-up(sm) {
            &::before {
                position: absolute;
                top: pxToRem(50);
                bottom: pxToRem(30);
                left: pxToRem(50);
                width: 3px;
                background-color: $primary-a;
                content: '';
            }
        }
        // Put the dt and the dd on the same line.
        dt {
            @include pnp-typeface(text-16-bold);
            display: inline-block;
        }

        dd {
            display: inline;
            margin-left: pxToRem(10);
        }

        dd::after {
            display: block;
            content: '';
        }
    }

    // Call to action
    &__action {
        @include pnp-svg-icon-classes;
        padding: pxToRem(5) pxToRem(15);
        color: $white;
        text-decoration: none;
        background-color: $primary-a;
        border: 0;
        border-radius: 100px;
    }

    .listing-item__title-link {
        padding: 0;
        text-align: left;
        background-color: transparent;
        border: 0;
        cursor: pointer;
    }
}
