.cta {
    @include pnp-svg-icon-classes;
    @include pnp-print-hide;
    position: relative;
    color: $white;
    //background-color: $primary-a;
    background-image: var(--background-image);
    background-repeat: no-repeat;
    background-position: left center, right center;
    background-size: contain;

    // Dont breakout if its in a content-with-nav layout
    &:not(.page-layout--content-with-nav *) {
        @include pnp-background-breakout;
    }

    &--pull-right,
    &--pull-left,
    &--pull-centered {
        .cta__contents,
        .cta__image-wrapper--internal {
            @include pnp-media-breakpoint-up(md) {
                max-width: 746px;
            }
        }
    }

    &--pull-right {
        .cta__actions,
        .cta__contents-container {
            @include pnp-pull-right;
            text-align: right;
        }
    }

    &--pull-left .cta__contents-container {
        @include pnp-media-breakpoint-up(md) {
            @include pnp-pull-left;
        }
    }

    &--pull-centered {
        @include pnp-center;

        .cta__actions {
            @include pnp-center;
            @include pnp-media-breakpoint-down(md) {
                justify-content: space-around;
            }
        }

        .cta__cta {
            @include pnp-media-breakpoint-down(md) {
                margin-right: 0;
            }
        }
    }

    &__contents-container {
        @include pnp-max-width-container;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        overflow: hidden;

        @include pnp-media-breakpoint-up(xl) {
            flex-direction: row;
        }

        @include pnp-media-breakpoint-down(xl) {
            &--image-right {
                flex-direction: column-reverse;
            }
        }

        &--image-internal {
            position: relative;
        }
    }

    &__image-wrapper {
        @include pnp-media-breakpoint-down(xl) {
            max-height: 480px;
            overflow: hidden;

            &--full:not(.page-layout--content-with-nav *) {
                // Dont breakout if its in a content-with-nav layout
                @include pnp-background-breakout;
            }

            &--full {
                width: 100%;
            }

            &--internal {
                width: 100%;
            }
        }
        @include pnp-media-breakpoint-down(md) {
            max-height: 300px;
        }

        @include pnp-media-breakpoint-up(xl) {
            position: absolute;
            flex-shrink: 0;
            width: 50%;
            height: 100%;

            &--full:not(.page-layout--content-with-nav *) {
                width: 50vw;
                margin: 0;
            }
        }

        &--internal {
            padding: 32px 45px;

            .cta__image {
                @include pnp-media-breakpoint-down(xl) {
                    max-height: calc(#{480px} - (2 * #{32px}));
                }
                @include pnp-media-breakpoint-down(md) {
                    max-height: calc(#{300px} - (2 * #{32px}));
                }
            }
        }

        @at-root .cta__contents-container--image-left & {
            @include pnp-media-breakpoint-up(xl) {
                left: 0;

                + .cta__contents {
                    margin-left: 50%;
                }

                &--full:not(.page-layout--content-with-nav *) {
                    left: calc(-1 * (100vw - 100%) / 2);
                }
            }
        }
        @at-root .cta__contents-container--image-right & {
            @include pnp-media-breakpoint-up(xl) {
                right: 0;

                + .cta__contents {
                    margin-right: 50%;
                }

                &--full :not(.page-layout--content-with-nav *) {
                    right: calc(-1 * (100vw - 100%) / 2);
                }
            }
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include pnp-media-breakpoint-down(xl) {
            max-height: 480px;
        }
        @include pnp-media-breakpoint-down(md) {
            max-height: 300px;
        }
    }

    &__contents {
        @include pnp-typeface(text-24, false);
        position: relative;

        z-index: $hero-banner-contents;
        flex: 1 1 0;
        padding: 32px 45px;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include pnp-reset-heading;
            @include pnp-typeface(heading-32-bold, false);
            margin-bottom: 1.5rem;

            @include pnp-media-breakpoint-up(md) {
                @include pnp-typeface(heading-48-bold, false);
            }
        }
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
    }

    &__cta {
        @include pnp-button;
        display: inline-flex;
        align-items: center;
        margin-right: 36px;
        //color: $primary-a;
        //background: $white;

        &:hover,
        &:focus {
            @include pnp-button-hover-focus();
            //color: $white;
            //background: $primary-a;
            //border-color: $white;
        }

        svg {
            margin-left: 0.5rem;
            fill: $white;
        }
    }

    &__link {
        @include pnp-link-button-size;
        display: inline-block;
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &--palette {
        &-a {
            @include pnp-palette-colours(palette-a);

            .cta__cta {
                @include pnp-palette-button(palette-a);
            }
        }

        &-b {
            @include pnp-palette-colours(palette-b);

            .cta__cta {
                @include pnp-palette-button(palette-b);
            }
        }
    }
}
