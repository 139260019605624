// Bring in normalize.css
@import '~normalize.css/normalize';

// Import functions, mixinx, vars. Shared with the export.scss
// DO NOT ADD ADDITIONAL IMPORTS HERE, ADD THEM TO THE SHARED IMPORT FILE
@import 'imports/shared-imports';
@import 'imports/utilities'; // Import separately as it creates classes that the export version should not include

// Import component mixins (This is required in this file as import-glob-loader doesn't glob from imported files)
/* stylelint-disable-next-line scss/at-import-partial-extension-blacklist */
@import '../modules/buttons/css/button-mixins.scss';
@import '../modules/card/css/card-mixins.scss';
@import '../modules/collapsible/css/collapsible-mixins.scss';
@import '../modules/slider/css/slider-mixins.scss';

// Import base styles (these are in a separate file to accommodate the storybook-component.scss file as well as this file)
@import 'imports/base-styles';
// Load the primary font
@import url($pnp-main-font-url);

/*
--------------------
Modules
--------------------
*/
/* stylelint-disable-next-line scss/at-import-partial-extension-blacklist */
@import '../modules/_global/css/global.scss';
@import '../modules/_integrations/git-switcher/css/global.scss';
@import '../modules/accordion/css/global.scss';
@import '../modules/anchor-nav/css/global.scss';
@import '../modules/aside-nav/css/global.scss';
@import '../modules/autocomplete-concierge/css/global.scss';
@import '../modules/autocomplete-search/css/global.scss';
@import '../modules/az-list/css/global.scss';
@import '../modules/bookmarks/css/global.scss';
@import '../modules/breadcrumb/css/global.scss';
@import '../modules/button-menu/css/global.scss';
@import '../modules/calendar/css/global.scss';
@import '../modules/call-to-action/css/global.scss';
@import '../modules/card/css/global.scss';
@import '../modules/carousel/css/global.scss';
@import '../modules/circular-progress/css/global.scss';
@import '../modules/collapsible-card/css/global.scss';
@import '../modules/collapsible/css/global.scss';
@import '../modules/combobox/css/global.scss';
@import '../modules/content-modal/css/global.scss';
@import '../modules/cookie-banner/css/global.scss';
@import '../modules/drawer/css/global.scss';
@import '../modules/edify-courses/css/global.scss';
@import '../modules/edify-credits/css/global.scss';
@import '../modules/edify-GPA/css/global.scss';
@import '../modules/edify-mobile-nav/css/global.scss';
@import '../modules/edify-pwa-install/css/global.scss';
@import '../modules/edify-user/css/global.scss';
@import '../modules/edify-widget-grid/css/global.scss';
@import '../modules/emails-listing/css/global.scss';
@import '../modules/event/css/global.scss';
@import '../modules/facet/css/global.scss';
@import '../modules/feature-news-listing/css/global.scss';
@import '../modules/footer/css/global.scss';
@import '../modules/forms/css/global.scss';
@import '../modules/funnelback-search-results/css/global.scss';
@import '../modules/hamburger-menu/css/global.scss';
@import '../modules/header-search/css/global.scss';
@import '../modules/hero-banner/css/global.scss';
@import '../modules/icon-viewer/css/global.scss';
@import '../modules/listing/css/global.scss';
@import '../modules/mega-menu/css/global.scss';
@import '../modules/modal/css/global.scss';
@import '../modules/multicolumn-content/css/global.scss';
@import '../modules/news-item/css/global.scss';
@import '../modules/notifications-settings/css/global.scss';
@import '../modules/notifications/css/global.scss';
@import '../modules/page-banner/css/global.scss';
@import '../modules/pagination/css/global.scss';
@import '../modules/personal-document-listing/css/global.scss';
@import '../modules/popover/css/global.scss';
@import '../modules/primary-nav/css/global.scss';
@import '../modules/related-links/css/global.scss';
@import '../modules/search-history/css/global.scss';
@import '../modules/search-page/css/global.scss';
@import '../modules/sectional-banner/css/global.scss';
@import '../modules/slider/css/global.scss';
@import '../modules/social-feed/css/global.scss';
@import '../modules/status-message/css/global.scss';
@import '../modules/stencils-auto-complete-concierge/css/global.scss';
@import '../modules/stencils-az-listing/css/global.scss';
@import '../modules/stencils-contextual-navigation/css/global.scss';
@import '../modules/stencils-count/css/global.scss';
@import '../modules/stencils-extra-search/css/global.scss';
@import '../modules/stencils-facet-breadcrumb-item/css/global.scss';
@import '../modules/stencils-facet-breadcrumb-list/css/global.scss';
@import '../modules/stencils-facet-drawer/css/global.scss';
@import '../modules/stencils-facet/css/global.scss';
@import '../modules/stencils-grouped-results/css/global.scss';
@import '../modules/stencils-hero-banner/css/global.scss';
@import '../modules/stencils-higher-education/css/global.scss';
@import '../modules/stencils-local-government/css/global.scss';
@import '../modules/stencils-nav-bar/css/global.scss';
@import '../modules/stencils-no-results/css/global.scss';
@import '../modules/stencils-pagination/css/global.scss';
@import '../modules/stencils-program-finder/css/global.scss';
@import '../modules/stencils-query-blending/css/global.scss';
@import '../modules/stencils-quickview/css/global.scss';
@import '../modules/stencils-result-listing/css/global.scss';
@import '../modules/stencils-search-history/css/global.scss';
@import '../modules/stencils-search-tools/css/global.scss';
@import '../modules/stencils-session-controls/css/global.scss';
@import '../modules/stencils-shortlist-drawer/css/global.scss';
@import '../modules/stencils-shortlist/css/global.scss';
@import '../modules/stencils-spelling-suggestions/css/global.scss';
@import '../modules/stencils-tabs/css/global.scss';
@import '../modules/stencils-tier-bar/css/global.scss';
@import '../modules/tabs/css/global.scss';
@import '../modules/utility-nav/css/global.scss';
@import '../modules/video-modal/css/global.scss';
