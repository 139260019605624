.facet {
    max-width: 400px;
    padding: 20px;
    border: 1px solid $grey-40;

    @include pnp-media-breakpoint-down(md) {
        max-width: 100%;
    }

    &__title {
        @include pnp-typeface(heading-26-bold, true);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .facet-groups-controller {
        display: none; // If this is updated ensure the aria-expanded default is also changed
        @include pnp-media-breakpoint-down(md) {
            display: inline-flex;
        }
    }
}

@include pnp-media-breakpoint-down(md) {
    .facet-groups {
        @include pnp-collapse-content();
    }
}

.facet-group {
    &__title {
        @include pnp-collapse-title();
        @include pnp-reset-button;
        @include pnp-svg-icon-classes;
        @include pnp-typeface(heading-20-bold, true);

        display: flex;
        flex-basis: max-content;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;

        .svg-icon {
            width: 32px;
            height: 32px;
        }
        // Assume the default class for the SVG is closed
        .svg-icon--open {
            display: none;
        }

        .svg-icon--closed {
            display: block;
        }

        &--open {
            .svg-icon--open {
                display: block;
            }

            .svg-icon--closed {
                display: none;
            }
        }
    }

    &__list {
        @include pnp-list-reset;
        @include pnp-collapse-content();
    }

    &__list-item {
        @include pnp-typeface(text-16, false);

        &--hidden {
            @include pnp-visuallyhidden();
        }
    }

    &__checkbox {
        @include pnp-custom-checkbox;
        // Nesting to overwrite default provided by mixin (default must be nested to work with Matrix form markup)
        + .facet-group__checkbox-label {
            width: 100%;
            margin: 0;
        }
    }

    &__results-number {
        @include pnp-typeface(text-14-bold, true);
        display: inline-block;
        margin-left: auto;
        padding: 2px;
        background-color: $primary-a-light;
    }

    &__show-more {
        @include pnp-reset-button;
        @include pnp-svg-icon-classes;
        display: inline-flex;
        align-items: center;
        color: $primary-a;
    }

    &-show-more__hidden-items-count {
        margin-left: 0.5em;
    }
}

.funnelback-facet {
    margin-bottom: 32px;
    // Styling targeted and Funnelback Facets
    .facet-group__list-item {
        position: relative;
        display: flex;
        align-items: flex-start;
        margin-bottom: 12px;
        margin-left: 10px;
        color: $black;
        text-decoration: none;
    }

    .facet-group__type {
        &-checkbox {
            .facet-group__list-item {
                margin-left: 36px;
                @media screen and (prefers-reduced-motion: reduce) {
                    &::before {
                        transition: none;
                    }
                }

                &::before {
                    @include pnp-standard-transition;
                    position: absolute;
                    top: 2px;
                    left: -26px;
                    width: 18px;
                    height: 18px;
                    background-color: $grey-10;
                    border: 1px solid $grey-40;
                    content: '';
                }
            }

            .facet-group__list-item-selected {
                @media screen and (prefers-reduced-motion: reduce) {
                    &::after {
                        transition: none;
                    }
                }

                &::after {
                    @include pnp-standard-transition;
                    position: absolute;
                    top: 4px;
                    left: -19px;
                    width: 5px;
                    height: 10px;
                    border: solid $grey-40;
                    border-color: $grey-70;
                    border-width: 0 1px 1px 0;
                    transform: rotate(45deg);
                    content: '';
                }
            }
        }

        &-radio-button {
            .facet-group__list-item {
                margin-left: 36px;
                @media screen and (prefers-reduced-motion: reduce) {
                    &::before {
                        transition: none;
                    }
                }

                &::before {
                    @include pnp-standard-transition;
                    position: absolute;
                    top: 2px;
                    left: -26px;
                    width: 18px;
                    height: 18px;
                    margin-right: 8px;
                    background-color: $grey-10;
                    border: 1px solid $grey-40;
                    border-color: $grey-70;
                    border-radius: 50%;
                    content: '';
                }
            }

            .facet-group__list-item-selected {
                @media screen and (prefers-reduced-motion: reduce) {
                    &::after {
                        transition: none;
                    }
                }

                &::after {
                    @include pnp-standard-transition;
                    position: absolute;
                    top: 6px;
                    left: -22px;
                    width: 10px;
                    height: 10px;
                    background-color: $grey-40;
                    border: solid $grey-10;
                    border-color: $grey-40;
                    border-radius: 50%;
                    content: '';
                }
            }
        }

        &-single-drill-down {
            .facet-group__list-item {
                &:hover,
                &:focus {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }

    .facet-group__clear {
        @include pnp-reset-button;
        @include pnp-svg-icon-classes;
        display: inline-flex;
        align-items: center;
        color: $primary-a;
        text-decoration: none;
    }
}
