.autocomplete-concierge {
    @include pnp-svg-icon-classes();
    position: relative;
    @include pnp-media-breakpoint-up(md) {
        width: auto;
    }

    &__form {
        display: flex;
        border: solid 1px $primary-a;
    }

    &__input {
        @include pnp-typeface(text-16, false);
        display: block;
        flex-grow: 2;
        width: 100%;
        padding: 8px 42px 8px 8px;
        line-height: 30px;
        border: 0;
        outline: 0;
        @include pnp-media-breakpoint-up(sm) {
            @include pnp-typeface(text-18, false);
            min-width: 192px;
            line-height: 30px;
        }
    }

    &__submit {
        @include pnp-reset-button;
        display: inline-flex;
        padding: 0.75rem 1rem;
        color: $primary-a;
        text-transform: uppercase;
        background-color: $white;
        cursor: pointer;
        transition: background-color 0.25s ease, color 0.25s ease;

        &:hover,
        &:focus {
            color: $white;
            background-color: $primary-a;
            outline-offset: -2px;
        }
        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }

    &__results {
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        min-width: 14.5rem;
        background: $white;
        outline: none;
        box-shadow: 0 20px 50px 0 rgba(0, 0, 0, 0.15);
    }

    &__section {
        @include pnp-list-reset();
        flex: 1 1 100%;
        padding: 0 22px 22px;

        @include pnp-media-breakpoint-up(md) {
            flex: 1 1 40%;

            &:first-child {
                flex: 1 1 100%;
                border-bottom: solid 1px $grey-20;
            }

            &:nth-child(2) {
                margin-right: 22px;
            }
        }

        ul {
            @include pnp-list-reset();
        }
    }

    &__section-title {
        @include pnp-typeface(text-16-bold, false);
        color: $primary-a;
        text-transform: uppercase;
    }

    &__search-result-item {
        @include pnp-svg-icon-classes;
        @include pnp-typeface(text-14, false);
        margin: 0 0 28px;
        color: $black;
        cursor: pointer;

        &:hover,
        &:focus,
        &[aria-selected='true'] {
            background: $primary-a-light;
        }

        &.organic {
            @include pnp-typeface(text-16, false);
            margin: 0;
        }

        .highlight {
            @include pnp-typeface(text-18-bold, false);
        }
    }

    @keyframes loader {
        to {
            transform: rotate(360deg);
        }
    }
    @media screen and (prefers-reduced-motion: reduce) {
        &__loader {
            width: 24px;
            height: 24px;
            margin: 12px 16px;
            animation: none;
            animation-duration: 1000ms;
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }
    }

    &__loader {
        width: 24px;
        height: 24px;
        margin: 12px 16px;
        animation-name: loader;
        animation-duration: 1000ms;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }

    .title {
        @include pnp-typeface(text-16-bold, false);
        color: $black;
        text-decoration: none;
    }

    .autocomplete-results__metadata {
        @include pnp-link-button;
        @include pnp-typeface(text-16, false);
        display: flex;
        align-items: flex-start;

        .svg-icon {
            flex-shrink: 0;
            margin-right: 10px;
            transform: scale(0.8);
        }
    }
}

// Following styles for modal version of concierge
.autocomplete-wrapper {
    @include pnp-svg-icon-classes;

    &__action {
        @include pnp-button;
        position: relative;
        padding: 8px 12px;
    }
}

.autocomplete-modal__header {
    @include pnp-svg-icon-classes;
    display: flex;
    justify-content: flex-end;
    background-color: $white;

    &-close {
        @include pnp-reset-button;
        margin: 10px;
    }
}

.modal__content.autocomplete-modal__content {
    position: fixed;
    top: 0;
    width: 940px;
    max-width: 100%;
    margin-top: 100px;
    background-color: transparent;
    outline: none;

    @include pnp-media-breakpoint-down(sm) {
        width: 100vw;
        height: 100vh;
        margin-top: 0;
        background-color: $white;
    }

    .autocomplete-concierge {
        padding: 0 40px 40px;
        background-color: $white;

        &__results {
            @include pnp-collapse-content();
            @include pnp-scroll-y;
            position: absolute;
            height: 0;
            background-color: transparent;
            box-shadow: none;

            &--open {
                height: 40vh;

                @include pnp-media-breakpoint-down(sm) {
                    height: 100vh;
                }
            }
        }

        &__section {
            padding: 0;

            @include pnp-media-breakpoint-up(md) {
                flex: 1 1 100%;

                &:first-child {
                    border-bottom: 0;
                }
            }
        }
    }
}
