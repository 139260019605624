@import './slider-mixins';

:root {
    --slider-animation-duration: 0.5s;
}

.slider {
    @include slider;
}

.slider-item {
    @include slider-item;
}

.slider-button {
    @include slider-button;
}
