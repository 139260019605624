/* stylelint-disable selector-no-qualifying-type */
/* WYSIWYG STYLING */
@import './content';

html {
    &[data-color-theme='dark'] {
    }

    &[data-color-theme='light'] {
    }
}

body {
    color: $base-font-colour;
    font-family: 'IBM Plex Sans', sans-serif;
}

// Sets black font color, removes backgrounds and borders on printers.
* {
    @include pnp-print-scheme;
}

// Hide all images and image wrappers on printers.
img,
[class*='image-wrapper'] {
    @include pnp-print-hide;
}

.body {
    @include pnp-scroll-y;
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    // This is to remove horizontal scrollbar, when 100vw is used within children.
    overflow-x: hidden;

    > .header,
    > .footer {
        width: 100%;
    }
}

.page-body {
    flex: 1;
    width: 100%;
}

.page-layout {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    &--full {
        padding: 0;
    }

    &--landing {
        @include pnp-max-width-container();
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &--landing-full {
        @include pnp-max-width-container();
        margin-top: 0;
        margin-bottom: 0;
    }

    &--transparent-nav {
        @include pnp-max-width-container();
        margin: -112px auto 2rem;
    }

    &--content {
        max-width: $content-max-width;
        margin: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &--content-with-nav {
        @include pnp-max-width-container();
        margin-top: 2rem;
        margin-bottom: 2rem;

        @include pnp-media-breakpoint-up(xl) {
            flex-direction: row;
        }

        .content {
            max-width: $content-max-width;
        }
    }

    &__body {
        flex-grow: 1;
    }

    &__side {
        @include pnp-media-breakpoint-up(xl) {
            @include pnp-print-hide;

            width: $sidebar-width;
            margin-right: 2rem;
            overflow: auto;
        }
    }
}

.content {
    width: 100%;
}

/*
    Container Template generic classes
*/

.template {
    @each $name, $value in $container-spacing-values {
        &#{'-padding-top--' + $name} {
            padding-top: $value;
        }

        &#{'-padding-bottom--' + $name} {
            padding-bottom: $value;
        }

        &#{'-margin-top--' + $name} {
            margin-top: $value;
        }

        &#{'-margin-bottom--' + $name} {
            margin-bottom: $value;
        }
    }

    &-margin-top--under-nav {
        @include pnp-negative-margin-top(112px);
    }

    &-margin-bottom--overlap-bottom {
        @include pnp-negative-margin-bottom(125px);
    }
}

@media print {
    body {
        @include pnp-scale(0.8);
        transform-origin: top left;
    }

    table {
        @include pnp-scale(0.5);
        width: 100% !important;
        overflow-x: visible !important;
        page-break-inside: auto;
    }

    tr {
        page-break-after: auto;
        page-break-inside: avoid;
    }
}
