.circular-progress-circle {
    --circle-stroke: 4;
    --circle-percentage: 0;
    --circle-scale: 100;
    --circle-cap: square;
    --circle-animation: circle-chart-fill 2s reverse;
    --width: 70px;
    --height: 70px;

    &__container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--width);
        height: var(--height);
    }

    &__chart {
        width: var(--width);
        height: var(--height);
    }

    &__description {
        @include pnp-reset-heading();
        position: absolute;
    }

    .circle {
        fill: none;
        stroke-width: var(--circle-stroke);
    }

    &__chart-background {
        stroke: $grey-20;
    }

    &__chart-progress {
        transform: rotate(-90deg);
        transform-origin: center;
        animation: var(--circle-animation);
        stroke: $primary-a;
        stroke-dasharray: var(--circle-percentage), var(--circle-scale);
        stroke-linecap: var(--circle-cap);
        @include pnp-media-prefers-reduced-motion {
            animation: none;
        }
    }

    @keyframes circle-chart-fill {
        to {
            stroke-dasharray: 0 100;
        }
    }

    @keyframes circle-chart-appear {
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
}
