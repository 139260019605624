.query-blending {
    @include pnp-svg-icon-classes;

    &__highlight {
        color: $primary-a;
    }

    svg {
        margin-right: pxToRem(5);
        color: $grey-70;
    }
}
