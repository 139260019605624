.calendar-day-view {
    // Change the background colour of the table
    --fc-today-bg-color: $calendar-background;
    --fc-now-indicator-color: #d72321;

    height: 100%;
    color: $grey-70;
    background: var(--fc-today-bg-color);

    .fc {
        padding-left: 5px;

        .fc-scroller {
            padding-top: 26px;
        }

        .fc-scrollgrid {
            border: 0;
        }

        thead {
            display: none;
        }

        &.fc-theme-standard td,
        &.fc-theme-standard th {
            // Remove borders from all table elements
            border: 0;
        }

        /*************************
        *   Time axis
        *************************/
        .fc-timegrid-slot {
            // Set the size of the timegrid slot (1AM, 2AM ect). FullCalendar eventMinHeight should also be set
            height: 48px;
        }

        .fc-timegrid-col-events {
            left: 18px;
        }

        .fc-timegrid-slot-label-frame {
            transform: translateY(-100%);
        }

        .fc-timegrid-axis-cushion,
        .fc-timegrid-slot-label-cushion {
            @include pnp-typeface(text-14, false);
            // Move the label for the time area up to sit at the middle of the event td row line
            position: relative;
            right: 8px;
            padding-left: 8px;
            //transform: translateY(-50%);
            text-transform: uppercase;
        }

        .fc-timegrid-axis-cushion {
            right: -8px;
        }

        .fc-timegrid-now-indicator-container {
            overflow: visible;
        }

        .fc-timegrid-now-indicator-arrow {
            right: -16px;
            // Make the now indicator a circle and move it to the right
            left: unset;
            margin-top: -7px;
            border: solid 8px var(--fc-now-indicator-color);
            border-radius: 50%;
        }

        /*************************
        *   Events column
        *************************/
        // Re-introduce top border for the event td area but only the major time section
        .fc-timegrid-slot-lane:not(.fc-timegrid-slot-minor) {
            border-top: 1px solid $grey-70;
        }
        /* stylelint-disable-next-line selector-max-compound-selectors */
        .fc-timegrid-body > * > table {
            // This is needed to make space for the 12AM top border ¯\_(ツ)_/¯
            border-top: 1px solid transparent;
        }

        .fc-timegrid-now-indicator-line {
            // Make now line thicker
            border-width: 1px;
        }

        /*************************
        *   Event axis
        *************************/
        .fc-daygrid-event,
        .fc-timegrid-event {
            --fc-event-bg-color: #{$white};
            --fc-event-border-color: #{$grey-20};
            --fc-event-selected-overlay-color: #{$grey-20};
            --fc-event-text-color: $grey-70;
            margin-top: 2px;
            margin-bottom: 5px;
            border-radius: 0;
            box-shadow: none;

            &:focus {
                .calendar-event {
                    &::before {
                        background: $white;
                    }
                }
            }
        }

        .fc-timegrid-event-harness {
            &:focus-within {
                z-index: $calendar-event-focus !important;
            }
        }

        .fc-event-main {
            padding: 0;
        }

        .fc-event-time {
            margin: 0;
        }
    }

    .calendar-event {
        &__time {
            @include pnp-visuallyhidden;
        }
    }
}
