$calendar-background: #{$grey-10};

@import './dayView';
@import './weekView';
@import './monthView';
@import './scheduleView';
@import './eventDetails';
@import './loading';

[data-component='calendar'],
.calendar-wrapper,
.calendar-wrapper__overlay {
    height: 100%;
}

.calendar-wrapper--loading {
    &::before {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $calendar-loading;
        background: rgba(0, 0, 0, 0.25);
        transition: opacity 0.2s ease-in-out;
        content: '';

        @include pnp-media-prefers-reduced-motion {
            transition: none;
        }
    }
}

.calendar {
    @include pnp-svg-icon-classes;
    height: 100%;
    background: $white;
    border: 1px solid $grey-20;

    &__header {
        @include pnp-component-heading;
        padding: 8px 14px;
        color: $grey-80;
        border: unset;
        border-bottom: 1px solid $grey-20;
    }

    &__body {
        // Body is 100% minus the header
        height: calc(100% - 44px);
    }

    &--sub-header {
        @include pnp-typeface(text-14, true);

        .calendar__body {
            // Minus subheader height
            height: calc(100% - 99px);
        }

        .calendar__banner ~ .calendar__body {
            // Minus subheader height AND banner height
            height: calc(100% - 137px);
        }
    }

    &__banner {
        @include pnp-reset-button;
        @include pnp-typeface(text-14-bold, false);
        //position: absolute;
        //z-index: $calendar-banner;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 9px;
        color: $white;
        background-color: $primary-a;
    }

    &__banner-count {
        margin-left: 8px;
        padding: 1px 8px;
        color: $primary-a;
        background: $white;
    }
}

.calendar-view-select {
    .button-menu__button {
        padding: 6px 8px;

        &:focus,
        &:hover {
            background-color: $grey-10;
        }
    }

    .button-menu__item-select {
        &:focus,
        &:hover {
            background-color: $grey-10;
        }
    }
}

.calendar-sub-header {
    display: flex;
    justify-content: space-between;
    padding: 4px;
    background: $calendar-background;
    border-bottom: 1px solid $grey-20;

    &__date-controls {
        @include pnp-typeface(text-14, false);
        display: flex;

        .svg-icon--previous {
            transform: rotate(180deg);
        }
    }

    &__date-action {
        @include pnp-reset-button;
        display: inline-flex;
        align-items: center;
        padding: 10px;
        text-transform: uppercase;

        &:focus,
        &:hover {
            background-color: $grey-20;
        }
    }
}

.calendar-date-picker {
    position: relative;

    &__button {
        @include pnp-reset-button;
        display: inline-flex;
        align-items: center;
        padding: 10px;

        &:focus,
        &:hover {
            background-color: $grey-20;
        }

        &-aria {
            @include pnp-visuallyhidden;
        }

        .svg-icon {
            margin-left: 4px;
            transform: rotate(90deg);
        }
    }

    &__picker {
        position: absolute;
        top: calc(100% + -2px);
        z-index: $calendar-date-picker;
        display: none;
        background-color: $white;
        border: 1px solid $grey-20;

        &--open {
            display: block;
        }
    }
}

.calendar-event {
    @include pnp-typeface(text-14, false);
    height: 100%;
    padding: 9px 15px;
    overflow: hidden;
    transition: background-color 0.3s ease-in;

    @include pnp-media-prefers-reduced-motion {
        transition: none;
    }

    &::before {
        position: absolute;
        top: 3px;
        left: 3px;
        width: 4px;

        height: calc(100% - 6px);
        background: $grey-20;
        content: '';
    }

    &--assignment::before {
        background: $primary-a-light;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
    }

    .svg-icon {
        margin-right: 8px;
        color: $primary-a;
    }

    &__title {
        color: $grey-80;
    }
}

.calendar__body--highlight-types {
    .calendar-event--highlightable {
        background-color: $primary-a-light;
    }
}

.calendar-error {
    padding: 14px;
}
