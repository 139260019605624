.news-item {
    &__published {
        @include pnp-typeface(text-18, false);
        display: block;
        margin-bottom: 1.75rem;

        &--banner {
            @include pnp-typeface(text-24, false);
            margin: 0;

            svg {
                width: 24px;
                margin-right: 9px;
            }
        }
    }

    &__content {
        margin-bottom: 70px;
    }

    &__summary {
        margin: 1.5rem 0;

        @at-root .content & * {
            @include wysiwyg-safe {
                @include pnp-typeface(text-24, false);

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__body {
        *:first-child {
            margin-top: 0;
        }

        *:last-child {
            margin-bottom: 0;
        }
    }
}
