*,
*::before,
*::after {
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
    overflow-x: hidden;
    color: $black;
    /* stylelint-disable-next-line property-disallowed-list */
    font-size: 1em;
    line-height: 1.4;
    scroll-behavior: smooth;
}

/*
--------------------
General
--------------------
*/

/*
 * Remove text-shadow in selection highlight:
 * https://twitter.com/miketaylr/status/12228805301
 *
 * Vendor-prefixed and regular ::selection selectors cannot be combined:
 * https://stackoverflow.com/a/16982510/7133471
 *
 * Customize the background color to match your design.
 */
::selection {
    text-shadow: none;
    background: $content-selection-highlight;
}

/*
 * A better looking default horizontal rule
 */

hr {
    display: block;
    height: 1px;
    margin: 1em 0;
    padding: 0;
    border: 0;
    border-top: 1px solid #ccc;
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

/*
 * Sets images by default to not overflow their container
 */

img {
    max-width: 100%;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
    resize: vertical;
}

/*
 * Reset fonts for relevant elements (taken from bootstrap)
 */

input,
button,
select,
textarea {
    /* stylelint-disable-next-line property-disallowed-list */
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

/*
 * Fix for misaligned focus 
 */

*:focus-visible {
    outline-offset: -1px;
}

/*
 * Provide an easy way for us to check the screen size in JS relative to the sass viewports
 */
html {
    --screen-size: 'xs';
    --screen-size-int: 1;
    @include pnp-media-breakpoint-up(sm) {
        --screen-size: 'sm';
        --screen-size-int: 2;
    }
    @include pnp-media-breakpoint-up(md) {
        --screen-size: 'md';
        --screen-size-int: 3;
    }
    @include pnp-media-breakpoint-up(lg) {
        --screen-size: 'lg';
        --screen-size-int: 4;
    }
    @include pnp-media-breakpoint-up(xl) {
        --screen-size: 'xl';
        --screen-size-int: 5;
    }
    @include pnp-media-breakpoint-up(xxl) {
        --screen-size: 'xxl';
        --screen-size-int: 6;
    }
}
