.calendar-schedule-view {
    --fc-neutral-bg-color: #{$grey-10};
    --fc-page-bg-color: #{$grey-10};
    $cdv-banner-height: 38px;

    position: relative;
    height: 100%;
    padding-right: 10px;
    color: $grey-70;
    background: $grey-10;

    .fc {
        .fc-scroller {
            padding-top: 10px;
        }

        .fc-list {
            border: 0;
        }

        .fc-list-table {
            padding-top: 10px;
        }

        .fc-list-day {
            position: relative;
            top: -8px;

            th {
                position: static !important;
                border: 0;
            }

            * {
                height: 0;
                padding: 0;
            }

            &-text {
                padding-left: 21px;
            }
        }

        .fc-list-day-text {
            @include pnp-typeface(text-14, false);
        }

        .fc-list-day-side-text {
            @include pnp-typeface(heading-26, false);

            &:not(.calendar-schedule-view-new-month *) {
                display: none;
            }
        }

        // New month label
        .fc-list-day-side-text {
            position: absolute;
            top: 20px;
            left: 16px;
            display: flex;
            width: 100%;
            padding-top: 16px;
            border-top: 1px solid $grey-40;
        }

        .fc-list-event {
            position: relative;
            left: 80px;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;

            td {
                padding: 0;
                border: 0;
            }

            &-time,
            &-graphic {
                display: none;
            }
        }

        /* stylelint-disable-next-line selector-max-compound-selectors */
        .fc-list-day + .fc-list-event > .fc-list-event-title {
            position: relative;
            /* stylelint-disable-next-line selector-max-compound-selectors */
            &::before {
                position: absolute;
                top: -5px;
                left: 0;
                width: 100%;
                height: 1px;
                background: $grey-40;
                content: '';
            }
        }
    }

    &__load-more {
        @include pnp-button();
        position: absolute;
        bottom: 25px;
        left: calc(50% - 62px);
    }

    .calendar-schedule-view-new-month {
        position: relative;

        .fc-list-day-cushion {
            margin-top: 100px;
        }
    }

    .calendar-event {
        width: calc(
            100% - 80px
        ); // Negative the left position on the .fc-list-event
        min-height: 62px;
        background: $white;
    }
}
