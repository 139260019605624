.facet-breadcrumb {
    &__label {
        margin-bottom: 1em;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }
}
