.facet {
    border: 0;

    @include pnp-media-breakpoint-down(xl) {
        max-width: 100%;
    }
}

.facet-group {
    &__title {
        @include pnp-typeface(heading-18-bold);
        padding: 1.4375rem 1.875rem;
        color: $white;
        background-color: $black;
    }

    &__results-number {
        padding: 0.25rem 0.5rem;
        color: $primary-a;
        background-color: rgba(228, 43, 18, 0.05);
    }

    &__show-more {
        color: $primary-a;
        cursor: pointer;
    }

    &__list-item .fas {
        height: 1.5rem;
        margin-right: 1rem;
        transform: rotate(90deg);
    }

    &-show-more__hidden-items-count {
        margin-left: 0.5em;
    }
}

.funnelback-facet {
    .facet-group__clear {
        color: $primary-a;
        cursor: pointer;
    }

    .facet-group {
        &__list-item--hidden {
            position: absolute;
        }
    }
}
