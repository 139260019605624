%footer-col-global {
    margin-bottom: 1rem;
}

.footer {
    @include pnp-print-hide;
    background: $grey-10;

    &__main {
        padding: 43px 28px 0;

        @include pnp-media-breakpoint-up(xl) {
            padding: 60px 0 0 100px;
        }
    }

    &__container {
        @include pnp-max-width-container;
        @include pnp-make-container;
    }

    &__row {
        @include pnp-make-row;

        &--sub {
            align-items: center;
            justify-content: space-between;
        }

        &--copyright-section {
            // Empty for theme merge
        }
    }

    &__col {
        &--small {
            @extend %footer-col-global;
            @include pnp-make-col-ready;
            @include pnp-make-col(12);
            @include pnp-media-breakpoint-up(md) {
                @include pnp-make-col(4);
            }

            @include pnp-media-breakpoint-up(xl) {
                @include pnp-make-col(2);
            }
        }

        &--medium {
            @extend %footer-col-global;
            @include pnp-make-col-ready;
            @include pnp-make-col(12);

            @include pnp-media-breakpoint-up(md) {
                @include pnp-make-col(4);
            }

            @include pnp-media-breakpoint-up(xl) {
                @include pnp-make-col(4);
            }
        }

        &--large {
            @extend %footer-col-global;
            @include pnp-make-col-ready;
            @include pnp-make-col(12);

            @include pnp-media-breakpoint-up(md) {
                @include pnp-make-col(8);
            }

            @include pnp-media-breakpoint-up(xl) {
                @include pnp-make-col(6);
            }
        }
    }

    &__col-heading {
        @include pnp-reset-heading();
        @include pnp-typeface(text-18-bold, true);
        margin-bottom: 14px;
        color: $black;
        text-transform: uppercase;

        &:empty {
            margin-bottom: 0;
        }

        &:empty + .footer__col-content .footer__list-item:is(:first-child) {
            @include pnp-media-breakpoint-up(md) {
                margin-top: 42px;
            }
        }
    }

    &__col-logo {
        margin-bottom: 26px;
    }

    &__logo-icon {
        width: clamp(64px, 200px, 100%);
    }

    &__list {
        @include pnp-list-reset;
    }

    &__list-item {
        margin: 14px 0;
        line-height: 24px;
    }

    &__list-link {
        @include pnp-svg-icon-classes;
        color: inherit;
        line-height: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        .svg-icon {
            margin-right: 9px;
        }
    }

    &__copyright-section {
        @include pnp-make-col-ready;
        @include pnp-make-col-auto;
    }

    &__social-section {
        @include pnp-make-col-ready;
        @include pnp-make-col-auto;
    }

    &__social-section-list {
        @include pnp-list-reset;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__newsletter {
        margin-bottom: 1rem;
    }

    &__newsletter-form {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-top: 16px;
    }

    &__newsletter-input {
        @include pnp-reset-text-input;
        flex: 1;
        padding: 9px 10px;
        background: transparent;
        border: 1px solid #707070;
    }

    &__newsletter-submit {
        @include pnp-svg-icon-classes;
        @include pnp-reset-button;
        @include pnp-button;
        margin-left: 0.5rem;
        @include pnp-media-breakpoint-up(md) {
            padding: 9px 30px;
        }

        .svg-icon {
            display: none;
            @include pnp-media-breakpoint-up(md) {
                display: inline-block;
                margin-left: 1rem;
            }
        }
    }
}

.footer-sub {
    padding: 23px 28px;
    background: $primary-a-light;

    @include pnp-media-breakpoint-up(xl) {
        padding-right: 100px;
        padding-left: 100px;
    }

    &__item {
        @include pnp-make-col-ready;
        @include pnp-make-col-auto;
    }

    a {
        color: inherit;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }
}

.footer__top {
    margin-bottom: 56px;

    .footer__social-section {
        display: flex;
        flex-direction: row;
        margin-left: auto;
    }

    .footer__list-item {
        margin: 0 32px;
    }
}
