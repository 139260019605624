.calendar__banner + .calendar__body .calendar-month-view {
    $cdv-banner-height: 38px;
    /* stylelint-disable-next-line selector-max-compound-selectors */
    thead .fc-scroller {
        padding-top: #{26px + $cdv-banner-height};
    }
}

.calendar-month-view {
    height: 100%;
}
