// DO NOT DEFINE COMPLEX VARS INSIDE MIXIN PARAMS - BREAKS REPLACEMENT REGEX
@mixin header-search-icon-button($main: '', $hover: '') {
    @if $main == '' {
        $main: (
            color: $primary-a,
            background: $white,
            border: none,
        );
    }

    @if $hover == '' {
        $hover: (
            color: $white,
            background: $primary-a,
            border: none,
        );
    }

    @include pnp-reset-button;
    display: inline-flex;
    padding: 0.75rem 1rem;
    color: map_get($main, color);
    background-color: map_get($main, background);
    border: map_get($main, border);
    cursor: pointer;
    transition: background-color 0.25s ease, color 0.25s ease;

    &:hover,
    &:focus {
        color: map_get($hover, color);
        background-color: map_get($hover, background);
        outline-offset: -2px;
    }
    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }
}

.header-search-wrapper {
    @include pnp-print-hide;
    @include pnp-svg-icon-classes;

    &:not(:only-child):not(:last-child) {
        margin-right: 0.75rem;
    }

    &__toggle,
    &__submit {
        @include header-search-icon-button;
    }

    &__submit {
        display: none;
    }

    &__modal-submit {
        flex-shrink: 0;
        color: $white;
        background-color: $primary-a;
    }

    &.header-search-wrapper--has-input {
        .header-search__clear {
            display: block;
        }
    }
}

.header-search {
    &::before {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: $modal;
        background: rgba(0, 0, 0, 0.25);
        transform: translate3d(0, -1px, 0) scale(0);
        visibility: hidden;

        opacity: 0;
        transition: opacity 0.2s ease-in-out, visibility 0s linear 0.2s,
            transform 0s linear 0.2s;
        content: '';

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }

    &__modal-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;

        @include pnp-media-breakpoint-up(lg) {
            margin-bottom: 4rem;
        }
    }

    &__modal-close {
        @include header-search-icon-button;
    }

    &__modal-close {
        padding: calc(0.75rem - 3px) calc(1rem - 4px) !important;
    }

    &__logo-image {
        max-width: 40vw;
        height: 2.5rem;
        object-fit: contain;
        @include pnp-media-breakpoint-up(lg) {
            height: 4rem;
        }
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        opacity: 0;
    }

    &__form {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__bar {
        position: relative;
        flex: 1 1 0;
        margin-right: 0.75rem;
        @include pnp-media-breakpoint-up(md) {
            max-width: 30rem;
        }
    }

    &__input {
        @include pnp-typeface(text-18, false);
        display: block;
        width: 100%;

        padding: 0.5rem;
        padding-right: 42px;
        line-height: 30px;
        border: solid 1px $grey-70;
    }

    &__clear {
        @include pnp-reset-button;
        position: absolute;
        top: calc(50% - 16px);
        right: 8px;
        display: none;
    }

    &__submit {
        @include header-search-icon-button;
    }

    .autocomplete-search__combobox-wrapper {
        width: 100%;
    }
}

.header-search-wrapper--active {
    .header-search {
        // Blocking lay to prevent clicks outside
        &::before {
            transform: none;
            visibility: visible;
            opacity: 1;
            transition: opacity 0.2s ease-in-out;

            @media (prefers-reduced-motion: reduce) {
                transition: none;
            }
        }

        &__content {
            @include pnp-max-width-container;

            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            z-index: $modal-content;
            display: flex;
            flex-direction: column;
            width: 100vw;
            padding: 1.75rem;
            background: $white;
            visibility: visible;
            opacity: 1;
            transition: opacity 0.2s ease-in-out;

            @include pnp-media-breakpoint-up(md) {
                height: 100vh;
            }

            @media (prefers-reduced-motion: reduce) {
                transition: none;
            }
        }

        &__submit {
            color: $white;
            background-color: $primary-a;
        }
    }
}

// Resets to removed the modal features for fixed and flyout on MD and higher
.header-search-wrapper--fadein,
.header-search-wrapper--fixed {
    @include pnp-media-breakpoint-up(md) {
        display: flex;

        .header-search {
            &::before {
                display: none;
            }

            &__modal-header {
                display: none;
            }

            &__content {
                position: static;
                display: block;
                width: auto;
                height: auto;
                padding: 0;
                visibility: visible;
                opacity: 1;
            }

            &__form {
                width: auto;
            }

            &__input {
                width: auto;
            }
        }
    }
}

// Flyout specific rules
@include pnp-media-breakpoint-up(md) {
    .header-search-wrapper--fadein {
        position: relative;

        &.header-search-wrapper--active {
            .header-search__bar {
                visibility: visible;
                opacity: 1;
                transition: visibility 0s, opacity 0.5s linear;

                @media (prefers-reduced-motion: reduce) {
                    transition: none;
                }
            }

            .header-search__submit {
                position: static;
                visibility: visible;
            }

            .header-search-wrapper__toggle {
                display: none;
            }
        }

        .header-search {
            &__bar {
                position: absolute;
                right: calc(
                    24px + 2rem
                ); // Width of search icon + horizontal padding
                left: unset;
                display: block;
                box-shadow: -20px 0 10px 0 $white;
                visibility: hidden;
                opacity: 0;
                transition: visibility 0.25s, opacity 0.25s linear;

                @media (prefers-reduced-motion: reduce) {
                    transition: none;
                }
            }

            &__submit {
                position: absolute;
                visibility: hidden;
                transition: none;
            }
        }
    }
}

// Fixed specific rules
.header-search-wrapper--fixed {
    @include pnp-media-breakpoint-up(md) {
        .header-search-wrapper {
            &__toggle {
                display: none;
            }

            &__submit {
                display: block;
            }
        }

        .header-search {
            &__form {
                align-items: center;
            }

            &__submit {
                color: $white;
                background-color: $primary-a;
            }
        }
    }
}
