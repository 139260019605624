.stencils {
    .result-sessions {
        &__controls {
            margin-top: 1rem;
            padding: 1rem;
            color: $black;
            background-color: rgba(255, 255, 255, 0.6);
            border-radius: 1em;
        }

        /* 
        Style both cart and click and search history toggles
        as normal links instead of buttons.
    */
        &__controls a,
        &__controls button {
            margin: 0.5rem;
            color: $black;
            text-decoration: underline;
            background: none;
            border: 0;
            cursor: pointer;
        }
    }
}
