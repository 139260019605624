/* stylelint-disable selector-no-qualifying-type */
@import '../../modal/css/global';
@import '../../forms/css/global';

.notifications-settings-modal {
    $border-color: $grey-20;
    $text-color: $grey-80;
    $text-color-light: $grey-20;

    @include pnp-svg-icon-classes;
    @include pnp-media-breakpoint-down(sm) {
        .notifications-settings-modal__content {
            width: 100%;
            height: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        max-width: 600px;
    }

    &__header {
        padding: 24px;
        padding-bottom: 16px;
        border-bottom: solid 1px $border-color;
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-18-bold, false);
    }

    &__header-close {
        @include pnp-reset-button;
        position: absolute;
        top: 12px;
        right: 12px;
    }

    &__body {
        padding: 24px 24px 0;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 24px;
    }

    &__footer-button {
        @include pnp-reset-button();
        @include pnp-typeface(text-16-bold, false);
        display: flex;
        justify-content: center;
        min-width: 90px;
        padding: 8px 16px;
        color: $white;
        background-color: $primary-a;
        border: solid 1px transparent;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04),
            0 1px 12px 4px rgba(0, 0, 0, 0.12);

        &:disabled {
            opacity: 0.8;

            &:hover,
            &:focus {
                @include pnp-link-disabled();
            }
        }
    }
}

.notifications-settings {
    &__action {
        @include pnp-button;
        position: relative;
    }

    &__container {
        padding: 0;
    }

    &__switch-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        background-color: $grey-10;
    }

    &__switch-item:not(:first-child) {
        margin-top: 16px;
    }

    &__label,
    input[type='checkbox']:not(.switch__input) + label {
        @include pnp-reset-heading();
        @include pnp-typeface(text-18-bold, false);

        flex: auto;
    }

    &__description {
        margin-left: 28px;
    }

    &__notifications-switch {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
    }

    &__notifications-icon {
        margin-right: 5px;
    }
}
