.az-index {
    @include pnp-typeface(text-16, false);
    padding: pxToRem(24);
    text-align: center;
    border: pxToRem(1) solid $grey-40;

    &__list {
        @include pnp-list-reset();
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        &-item {
            width: pxToRem(45);
            height: pxToRem(45);
            margin: pxToRem(10);
            vertical-align: bottom;
        }
    }

    &__link {
        @include pnp-standard-transition();
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        color: $black;
        text-decoration: none;
        border: pxToRem(1) solid $grey-40;

        &:hover,
        &:focus {
            background: $grey-10;
        }
    }

    .active {
        background: $grey-10;
    }

    .disabled {
        opacity: 0.6;

        &:hover,
        &:focus {
            @include pnp-link-disabled();
            background: none;
        }
    }
}
