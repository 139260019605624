.aside-nav {
    position: relative;
    @include pnp-media-breakpoint-down(xl) {
        display: none;
    }
}

.aside-nav__list {
    @include pnp-list-reset;
    height: 0;
    overflow: hidden;
    background: $white;
    opacity: 0;

    .aside-nav__toggle + .aside-nav__list {
        display: none;
        visibility: hidden;
    }

    .is-expanded + .aside-nav__list {
        display: block;
        height: auto;
        overflow: visible;
        visibility: visible;
        opacity: 1;
    }
}

.aside-nav__list--top,
.aside-nav__list.is-expanded {
    height: auto;
    opacity: 1;
}

.aside-nav__li {
    @include pnp-svg-icon-classes;
    position: relative;
    padding-left: 0;

    .section-icon {
        float: left;
        margin: 10px 15px 12px 5px;
        color: $primary-a;
    }
}

.aside-nav__link {
    @include pnp-typeface(text-18, false);
    display: block;
    line-height: 26px;
    text-decoration: none;

    &:link,
    &:visited {
        color: $black;
    }

    .aside-nav__inner {
        display: block;
        padding: 10px 44px 10px 10px;

        &:hover,
        &:active,
        &:focus {
            background-color: $grey-10;
        }
    }

    &.is-hierarchy .aside-nav__inner,
    &.is-current .aside-nav__inner {
        @include pnp-typeface(text-18-bold, false);
        background-color: $grey-10;
    }
}

.aside-nav__link--l2:not(.is-current),
.aside-nav__link--l2:not(.is-hierarchy) {
    border-bottom: solid 1px $grey-20;
}

.has-icon {
    .aside-nav__inner--l3 {
        margin-left: 60px;
    }

    .aside-nav__inner--l4 {
        margin-left: 90px;
    }

    .aside-nav__inner--l5 {
        margin-left: 120px;
    }
}

.aside-nav__inner--l3 {
    margin-left: 30px;
}

.aside-nav__inner--l4 {
    margin-left: 60px;
}

.aside-nav__inner--l5 {
    margin-left: 90px;
}

.aside-nav__toggle {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    padding: 10px;
    background: none;
    border: 0;
    cursor: pointer;

    .svg-icon {
        display: block;
        transform: rotate(90deg);
    }

    &.is-expanded {
        .svg-icon {
            transform: rotate(270deg);
        }
    }

    &.has-children {
        display: block;
    }
}
