// These placehoder classes are here so they can be easily added to where you need them, idea being that this module is used elsewhere and not as a stand alone component (although you could if you really wanted to)
@mixin pnp-collapse-title() {
    @include pnp-reset-button;
}

@mixin pnp-collapse-content() {
    position: absolute;
    visibility: hidden;
    transition: height 0.35s ease-in;

    &--open {
        position: static;
        visibility: visible;
    }

    &--collapsing {
        height: 0 !important;
        overflow: hidden;

        @include pnp-media-prefers-reduced-motion {
            transition: none;
        }
    }

    &--expanding {
        height: 0;
        overflow: hidden;
        transition: height 0.35s ease-out;

        @include pnp-media-prefers-reduced-motion {
            transition: none;
        }
    }
}

.collapse {
    &__button {
        @include pnp-collapse-title;
    }

    &__content {
        @include pnp-collapse-content;
    }
}

// React versions (probably needs to be moved)
.react-collapse {
    &__chevron {
        @include pnp-svg-icon-classes;

        &--up {
            .svg-icon {
                transform: rotate(90deg);
            }
        }

        &--down {
            .svg-icon {
                transform: rotate(-90deg);
            }
        }
    }

    &__button {
        @include pnp-collapse-title;
        display: flex;
        align-items: center;
    }

    &__content {
        @include pnp-collapse-content;
    }
}
