.search-shortlist {
    &__heading-area {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-26-bold);
    }

    &__hide {
        @include pnp-svg-icon-classes;
        display: inline-flex;
        align-items: center;
        margin-bottom: pxToRem(10);
        padding: 0;
        color: $primary-a;
        text-decoration: underline;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        svg {
            transform: rotate(180deg);
        }
    }

    &__clear {
        @include pnp-svg-icon-classes;
        display: inline-flex;
        height: fit-content;
        padding: pxToRem(5) pxToRem(15);
        color: $white;
        background-color: $primary-a;
        border: 0;
        border-radius: 100px;
    }
}
