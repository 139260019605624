.page-banner {
    $overlay-percentages: 10, 20, 30, 40, 50;

    @include pnp-svg-icon-classes;

    position: relative;
    display: flex;
    width: 100%;
    min-height: 320px;
    color: $black;
    background: $grey-10;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $page-banner-overlay;
        width: 100%;
        height: 100%;
        content: '';
    }

    &__image {
        $x-positions: left, right, center;
        $y-positions: top, bottom, center;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';

        @each $x-position in $x-positions {
            &--#{$x-position} {
                object-position: #{$x-position};
                font-family: 'object-fit: cover; object-position: #{$x-position};';
            }

            @each $y-position in $y-positions {
                &--#{$x-position}-#{$y-position} {
                    object-position: #{$x-position} #{$y-position};
                    font-family: 'object-fit: cover; object-position: #{$x-position} #{$y-position};';
                }
            }
        }

        @each $y-position in $y-positions {
            &--#{$y-position} {
                object-position: #{$y-position};
                font-family: 'object-fit: cover; object-position: #{$y-position};';
            }
        }
    }

    &__body {
        @include pnp-max-width-container;
        position: relative;
        z-index: $page-banner-contents;
        width: 100%;
        max-width: 900px;
        padding: 2.5rem;
        text-align: center;
    }

    &__title {
        margin: 0;
        margin-bottom: 1rem;
        @include pnp-media-breakpoint-up(md) {
            @include pnp-typeface(heading-48-bold, false);
        }
    }

    &--align {
        &-left {
            .page-banner__body {
                text-align: left;
                @include pnp-media-breakpoint-up(xxl) {
                    padding: 0;
                }
            }
        }

        &-center {
            .page-banner__body {
                text-align: center;
            }
        }
    }

    &--with-image {
        .page-banner__body {
            color: $white;
        }
    }

    @each $overlay-percentages in $overlay-percentages {
        &--overlay-#{$overlay-percentages} {
            &::before {
                background-color: transparentize(
                    $black,
                    (100 - $overlay-percentages) * 0.01
                );
            }
        }
    }
}

.page-banner--with-breadcrumb {
    .page-banner {
        min-height: 400px;

        &__breadcrumb {
            position: absolute;
            z-index: $page-banner-breadcrumb;
            width: 100%;
            padding: 1.5rem 0;
        }

        &__body {
            @include pnp-media-breakpoint-down(xl) {
                padding: 18px 16px;
            }

            .page-banner__title {
                margin-top: 80px;
            }
        }
    }
}

.page-banner--with-breadcrumb.page-banner--with-image {
    .page-banner {
        &__breadcrumb {
            background: rgba(250, 251, 255, 0.9);
        }
    }
}

.page-banner--with-breadcrumb {
    .page-banner {
        &__breadcrumb {
            background: transparent;
        }
    }
}

.page-banner-search {
    &__bar {
        display: inline-block;
        width: 100%;
    }

    &__bar-container {
        display: flex;
    }

    &__form {
        display: block;
        width: 100%;
    }

    &__content {
        position: static;
        display: block;
        visibility: visible;
        opacity: 1;
    }

    &__input,
    .autocomplete-search__input {
        @include pnp-typeface(text-14, no-margin);
        display: block;
        width: 100%;
        min-width: 12rem;
        padding: 0.5rem;
        padding-right: 42px;
        line-height: 30px;
        border: solid 1px $white;
        @include pnp-media-breakpoint-up(sm) {
            @include pnp-typeface(text-18, no-margin);
            min-width: 18rem;
            line-height: 30px;
        }
    }

    &__input-label {
        @include pnp-typeface(text-16-bold, no-margin);
        display: block;
        padding-bottom: 10px;
    }

    &__clear {
        @include pnp-reset-button;
        position: absolute;
        top: calc(50% - 16px);
        right: 8px;
        display: none;
    }

    &__submit {
        @include pnp-reset-button;
        display: inline-flex;
        padding: 0.75rem 1rem;
        color: $primary-a;
        background-color: $white;

        cursor: pointer;
        transition: background-color 0.25s ease, color 0.25s ease;

        &:hover,
        &:focus {
            color: $white;
            background-color: $primary-a;
            outline-offset: -2px;
        }
        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }

    .autocomplete-search__combobox-wrapper {
        display: initial;
    }

    .autocomplete-search__input {
        @include pnp-typeface(text-14, no-margin);
        display: block;
        width: 100%;
        min-width: 12rem;
        padding: 0.5rem;
        padding-right: 42px;
        line-height: 30px;
        border: solid 1px $white;
        @include pnp-media-breakpoint-up(sm) {
            @include pnp-typeface(text-18, no-margin);
            min-width: 18rem;
            line-height: 30px;
        }
    }

    .autocomplete-search-listbox__item {
        color: $black;
    }
}
