.breadcrumb {
    @include pnp-svg-icon-classes;
    @include pnp-max-width-container;
    width: 100%;
    padding: 0 1rem;

    @include pnp-media-breakpoint-down(sm) {
        .breadcrumb__list-item {
            display: none;
        }

        .breadcrumb__list-item:nth-last-child(2) {
            display: block;
        }
    }

    &__list {
        @include pnp-list-reset;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 0;
        vertical-align: middle;
        list-style-type: none;

        &-item {
            position: relative;
            padding: 0 20px 0 0;

            &-link {
                display: flex;
                width: 100%;
                color: $primary-a;
                text-decoration: none;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }

    .svg-icon {
        width: 16px;
        height: 16px;
    }

    &--truncated-true {
        .breadcrumb__list-item:not(.breadcrumb__list-item-home) {
            @include pnp-media-breakpoint-between(sm, lg) {
                max-width: 102px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    &--separator-chevron {
        @include pnp-media-breakpoint-down(sm) {
            .breadcrumb__list-item:nth-last-child(2) {
                padding-left: 1rem;

                .breadcrumb__list-item-link {
                    &::after {
                        content: none;
                    }

                    &::before {
                        position: absolute;
                        top: calc(50% - (#{7px} / 2));
                        left: -11px;
                        display: block;
                        width: 7px;
                        height: 7px;
                        border-right: 1px solid $black;
                        border-bottom: 1px solid $black;
                        transform: rotate(135deg);
                        content: '';
                    }
                }
            }
        }

        .breadcrumb__list-item-link {
            position: relative;
            line-height: 1rem;

            &::after {
                position: absolute;
                top: calc(50% - (#{7px} / 2));
                right: -11px;
                display: block;
                width: 7px;
                height: 7px;
                border-right: 1px solid $black;
                border-bottom: 1px solid $black;
                transform: rotate(-45deg);
                content: '';
            }
        }
    }
}

@media print {
    .breadcrumb__list-item {
        display: block !important;
        max-width: unset !important;
    }

    .breadcrumb__list-item-link {
        padding-left: 0 !important;

        &::before {
            display: none !important;
        }

        &::after {
            content: '' !important;
        }
    }
}
