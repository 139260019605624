@import '../../modal/css/global';

.users-loader {
    @include pnp-svg-icon-classes;
    @include pnp-button;
}

.users-wrapper {
    @include pnp-svg-icon-classes;
    display: flex;

    &__action {
        @include pnp-button;
        position: relative;
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: center;
        padding: 8px 12px;
    }

    &__name {
        @include pnp-typeface(text-18, true);
        @include pnp-visuallyhidden;

        @include pnp-media-breakpoint-up(md) {
            @include pnp-visuallyshown;
        }
    }
}

.users-popover {
    &__dialog {
        @include pnp-standard-box-shadow;
        z-index: $popover;
    }
}

.user-details {
    @include pnp-svg-icon-classes;
    @include pnp-typeface(text-16, no-margin);
    display: flex;
    flex-direction: column;
    width: 350px;
    background: $white;

    &__header {
        @include pnp-typeface(heading-18-bold, false);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px 10px 20px;
        border-bottom: 1px solid $grey-20;
    }

    &__header-button {
        @include pnp-reset-button;
        @include pnp-svg-icon-classes;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $grey-70;
    }

    &__header-button-icon {
        width: 24px;
        height: 24px;
        fill: currentColor;
    }

    &__list {
        @include pnp-list-reset;
        padding: 0 10px 10px 20px;
    }

    &__list-item {
        margin: 10px 5px 10px 0;
    }

    &__list-item-link {
        color: inherit;
        text-decoration: none;

        &:visited {
            color: inherit;
        }
    }

    &__list-item-button {
        @include pnp-button-colour-primary-a;
    }
}
