@import 'src/styles/imports/shared-imports';

.google-api-auth {
    display: flex;

    &__action {
        @include pnp-reset-button;
        display: flex;
        align-items: center;
        width: 191px;
        height: 46px;
        background-image: image-set(
            './files/btn_google_signin_light_normal_web.png' 1x,
            './files/btn_google_signin_light_normal_web@2x.png' 2x
        );
        background-repeat: no-repeat;

        &:focus,
        &:hover {
            background-image: image-set(
                './files/btn_google_signin_light_focus_web.png' 1x,
                './files/btn_google_signin_light_focus_web@2x.png' 2x
            );
        }

        &:active {
            background-image: image-set(
                './files/btn_google_signin_light_pressed_web.png' 1x,
                './files/btn_google_signin_light_pressed_web@2x.png' 2x
            );
        }
    }
}
