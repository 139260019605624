.icon-viewer {
    @include pnp-svg-icon-classes;
    @include pnp-make-container();

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-26, false);
    }

    &__list {
        @include pnp-make-row();
        @include pnp-list-reset;
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        @include pnp-make-col-ready();
        @include pnp-make-col(6);

        @include pnp-media-breakpoint-up(sm) {
            @include pnp-make-col(4);
        }
        @include pnp-media-breakpoint-up(md) {
            @include pnp-make-col(3);
        }
        @include pnp-media-breakpoint-up(xl) {
            @include pnp-make-col(2);
        }

        &:hover,
        &:focus {
            background-color: $grey-10;
        }

        .svg-icon {
            margin-bottom: 0.5rem;
        }
    }

    &__copy {
        @include pnp-reset-button;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 2rem 1rem;

        * {
            pointer-events: none;
        }
    }

    &__name {
        text-align: center;
    }

    &__copied-message {
        position: fixed;
        right: 5rem;
        bottom: 2rem;
        padding: 1rem 2rem;
        background: $grey-10;
        visibility: hidden;
        opacity: 0;
        transition: opacity 1s ease;

        @include pnp-media-prefers-reduced-motion {
            transition: none;
        }

        &--active {
            visibility: visible;
            opacity: 1;
        }

        &--hiding {
            visibility: visible;
            opacity: 0;
        }
    }
}
