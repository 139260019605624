.hamburger-landmark {
    @include pnp-svg-icon-classes;

    &:not(:last-child) {
        margin-right: 0.75rem;
    }

    &__action {
        @include pnp-reset-button;
        display: inline-flex;
        padding: 0.75rem 1rem;
        color: $primary-a;
        background-color: $white;

        cursor: pointer;
        transition: background-color 0.25s ease, color 0.25s ease;

        &:hover,
        &:focus {
            color: $white;
            background-color: $primary-a;
            outline-offset: -2px;
        }
        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }
}

.hamburger-menu-wrapper {
    display: none;

    &--active {
        display: block;
    }

    // Blocking layer to prevent clicks outside the hamburger
    &::before {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        /* stylelint-disable scale-unlimited/declaration-strict-value */
        z-index: $hamburger-menu - 1;
        background: rgba(0, 0, 0, 0.25);

        /* fade in */
        transition: opacity 0.2s ease-in-out;
        content: '';

        @include pnp-media-prefers-reduced-motion {
            transition: none;
            animation: none;
        }
    }
}

.hamburger-menu {
    @include pnp-svg-icon-classes;

    position: fixed;
    top: 0;
    right: 0;
    z-index: $hamburger-menu;
    width: 100%;
    max-width: 400px;
    height: 100vh;
    background: $grey-10;
    box-shadow: -40px 0 50px -40px #00000026;

    &__header {
        display: flex;
        justify-content: flex-end;
        background: $white;
        border-bottom: 1px solid $grey-20;
    }

    &__action {
        @include pnp-reset-button;
        padding: 0.75rem 1rem;
        color: $primary-a;
        background-color: $white;
    }

    &__body {
        @include pnp-scroll-y;
        display: flex; // Set for when utility nav is included
        flex-direction: column; // Set for when utility nav is included
        height: calc(100vh - #{50px});
        scrollbar-gutter: stable;
    }

    &__list {
        @include pnp-list-reset();
        flex-grow: 1; // Set for when utility nav is included
    }
}

.hamburger-menu-item {
    @include pnp-svg-icon-classes;

    &__list {
        @include pnp-list-reset();
        display: none;
        margin-left: 1.5rem;

        &--active {
            display: block;
        }
    }

    &__link,
    &__expander {
        @include pnp-reset-button();
        @include pnp-typeface(text-18, false);

        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.75rem 1.5rem;
        color: $primary-a;
        text-decoration: none;

        &:focus {
            outline: solid 2px $primary-a;
            outline-offset: -2px;
        }

        &--decorative-svg {
            gap: 8px;
            justify-content: flex-start;

            .svg-icon {
                margin-left: 0;
            }

            &[aria-expanded] {
                .svg-icon:first-of-type {
                    transform: rotate(0);
                }

                .svg-icon:last-of-type {
                    margin-left: auto;
                }
            }
        }
    }

    // Second teir is one typeface style down
    .hamburger-menu-item {
        &__link,
        &__expander {
            @include pnp-typeface(text-16, false);
        }
    }

    &__expander {
        .svg-icon {
            margin-left: 0.25rem;
            transform: rotate(90deg);
        }

        &[aria-expanded='true'] {
            .svg-icon {
                transform: rotate(270deg);
            }
        }
    }
}
