@import 'src/styles/imports/shared-imports';

.microsoft-auth {
    display: flex;

    &__action {
        @include pnp-reset-button;
        width: 215px;
        height: 41px;
        background-image: url('./files/ms-symbollockup_signin_dark.svg');
        background-repeat: no-repeat;
        border: 1px solid transparent;

        &:active {
            background-image: url('./files/ms-symbollockup_signin_light.svg');
            border-color: $black;
        }
    }
}
