.tabs {
    .tab {
        &__button {
            @include pnp-typeface(text-16-bold);
            margin: 0 0.625rem;
            padding: 1.375rem 2.125rem 1.4375rem;
            color: $white;
            white-space: nowrap;
            text-decoration: none;
            background-color: rgba(0, 0, 0, 0.8);
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            transition: color, background-color 0.3s ease-in-out;

            &:hover,
            &:focus {
                color: $black;
                background-color: rgba(240, 240, 240, 0.8);

                &::after {
                    display: none;
                }
            }

            &--active {
                color: $black;
                background-color: $grey-10;

                &::after {
                    display: none;
                }
            }

            &--disabled {
                color: $grey-40;
                pointer-events: none;
            }

            .fas,
            .far,
            .fab {
                margin-right: pxToRem(10);
            }
        }
        @media screen and (prefers-reduced-motion: reduce) {
            &__button {
                transition: none;
            }
        }
    }

    .tab-list {
        border-radius: 10px;
    }

    .tab-list-nav {
        &__overflow-menu {
            padding: pxToRem(20);
            overflow-x: hidden;
            background-color: rgba(218, 218, 218, 0.9);
            border-radius: 10px 0 10px 10px;

            .tab__button {
                flex-wrap: wrap;
                width: 100%;
                margin: 0;
                color: $white;
                text-decoration: none;
                border-radius: 0;

                &:hover,
                &:focus {
                    color: $primary-a-light;
                    text-decoration: none;
                    background-color: $primary-a;
                    cursor: pointer;
                }

                &--active {
                    background-color: $primary-a;
                }

                &:first-child {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }

                &:last-child {
                    border-bottom-right-radius: 10px;
                    border-bottom-left-radius: 10px;
                }
            }
        }
    }

    .tab-list-nav-overflow-menu {
        &__button {
            border-radius: 10px;

            &:hover,
            &:focus,
            &--open {
                background-color: rgba(218, 218, 218, 0.9);
            }

            &--open {
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
}
