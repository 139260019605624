[data-component='social-feed'] {
    height: 100%;
    max-height: 100vh;
}

.social-feed-wrapper {
    @include pnp-scroll-y;
    overflow-x: hidden;

    // No grey background if in error state due to status-message colouring
    &:not(.social-feed-wrapper--error) {
        background: $grey-10;
    }
}

.social-feed {
    @include pnp-svg-icon-classes;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__header {
        @include pnp-component-heading;
        min-height: 54px;
        margin: -1px;
    }
}

.social-card {
    @include pnp-standard-box-shadow;
    position: relative;
    padding: 12px;
    background: $white;
    border-radius: 0;

    &__open-modal {
        @include pnp-reset-button;
        outline-offset: 12px;
    }

    &__view-external {
        position: absolute;
        top: 12px;
        right: 12px;
        color: $primary-a;

        &--logo-twitter {
            color: $social-twitter;
        }

        &--logo-facebook {
            color: $social-facebook;
        }

        &--logo-youtube {
            color: $social-youtube;
        }
    }
}

.social-card,
.social-card-modal {
    @include pnp-typeface(text-14, false);

    // margin: 12px;
    margin-bottom: 12px;

    &__header {
        display: flex;
        align-items: center;
    }

    &__header-image {
        width: 36px;
        height: 36px;
        overflow: hidden;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 50%;
    }

    &__title {
        margin-left: 12px;
    }

    &__post-created {
        color: $grey-70;
    }

    &__account-name {
        @include pnp-typeface(text-14-bold, false);
    }

    &__body {
        margin-top: 8px;
    }

    &__show-more {
        @include pnp-reset-button;
        text-decoration: underline;
    }

    &__body-image {
        display: block;
        width: 100%;
        margin-top: 12px;
        overflow: hidden;
        border-radius: 0.25rem;
    }
}

.social-card-modal {
    @include pnp-svg-icon-classes;
    max-width: 450px;
    margin: auto;

    @include pnp-media-breakpoint-down(sm) {
        max-width: none;
        margin: 50px 15px;
    }

    &__content {
        border-radius: 4px;
    }

    &__header {
        padding: 24px;
        border-bottom: 1px solid $grey-20;
    }

    &__close-icon {
        @include pnp-reset-button;
        position: absolute;
        top: 12px;
        right: 12px;
    }

    &__body {
        margin: 0;
        padding: 24px;
    }

    &__video-embed-wrapper {
        @include pnp-ratio16by9-wrapper();
        margin-top: 12px;
    }

    &__video-embed {
        @include pnp-ratio16by9-inner();
    }

    &__footer {
        display: flex;
        justify-content: flex-end;
        padding: 24px;
        border-top: 1px solid $grey-20;
    }

    &__open-external-button {
        @include pnp-button;
    }
}

.social-feed-loading {
    &__label {
        @include pnp-visuallyhidden;
    }

    &__card {
        margin-bottom: 12px;
        background: $white;
    }

    &__shadow {
        $loading-backgrounds: (
            (
                //Circle
                'background-item':
                    radial-gradient(circle 18px, $grey-10 99%, transparent 0),
                'background-position': 12px 12px,
                'background-size': 36px 36px
            ),
            (
                // Top two lines
                'background-item': linear-gradient(
                        $grey-10 18px,
                        transparent 0
                    ),
                'background-position': 60px 14px,
                'background-size': 165px 14px
            ),
            (
                'background-item': linear-gradient($grey-10 14px, transparent 0),
                'background-position': 60px 35px,
                'background-size': 100px 14px,
            ),
            (
                // Next two lines
                'background-item': linear-gradient(
                        $grey-10 18px,
                        transparent 0
                    ),
                'background-position': 10px 70px,
                'background-size': calc(100% - 20px) 18px
            ),
            (
                'background-item': linear-gradient($grey-10 18px, transparent 0),
                'background-position': 10px 98px,
                'background-size': calc(40% - 20px) 18px,
            ),
            (
                // Big block
                'background-item':
                    linear-gradient($grey-10 150px, transparent 0),
                'background-position': 10px 128px,
                'background-size': calc(100% - 20px) 180px
            )
        );

        @include pnp-loading(100%, 300px, $loading-backgrounds);
    }
}
