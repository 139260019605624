.button-menu {
    position: relative;

    &__button {
        @include pnp-reset-button;
        display: inline-flex;
    }

    &__menu {
        @include pnp-list-reset;
        @include pnp-transition(opacity $pnp-default-transition-duration);
        position: absolute;

        top: calc(100% - 2px);
        right: 0;
        z-index: $button-menu;
        padding: 4px 8px;
        background: $white;

        border: 1px solid $grey-20;
        visibility: hidden;
        opacity: 0;

        &--open {
            visibility: visible;
            opacity: 1;
        }
    }

    &__item-select {
        @include pnp-reset-button;
        width: 100%;
        padding: 4px 8px;

        &:focus,
        &:hover {
            background-color: $grey-20;
        }
    }
}
