.personal-document-listing {
    @include pnp-svg-icon-classes;
    @include pnp-typeface(text-14, no-margin);

    &__list {
        @include pnp-list-reset;
    }

    &__footer {
        display: flex;
        margin-top: 18px;

        @include pnp-media-breakpoint-up(md) {
            justify-content: flex-end;
        }
    }

    &__all-documents {
        @include pnp-link-text;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        .svg-icon {
            margin-right: 4px;
        }
    }
}

.personal-document-listing-item {
    @include pnp-typeface(text-12, no-margin);
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    color: $grey-80;

    @include pnp-media-breakpoint-up(md) {
        flex-direction: row;
        margin: 10px 0;
    }

    &__icon {
        position: absolute;
        width: 16px;

        @include pnp-media-breakpoint-up(md) {
            position: static;
            margin-right: 16px;
        }
    }

    &__title {
        @include pnp-link-text;
        @include pnp-typeface(text-14, no-margin);
        display: block;
        margin-bottom: 10px;
        margin-left: 20px;
        text-decoration: none;

        @include pnp-media-breakpoint-up(md) {
            flex: 3 1 0;
            margin: 0 25px 0 0;
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__last-modified {
        @include pnp-media-breakpoint-up(md) {
            display: flex;
            flex: 3 1 0;
            flex-direction: column;
            margin-right: 25px;
        }

        &-date {
            display: inline-block;
            margin-right: 6px;
        }

        &-by {
            display: inline-block;
        }
    }

    &__size {
        @include pnp-media-breakpoint-up(md) {
            flex: 1 1 0;
            min-width: 50px;
            text-align: end;
        }
    }
}

.personal-document-listing-loading {
    &__label {
        @include pnp-visuallyhidden;
    }

    &__shadow {
        $loading-backgrounds: (
            (
                'background-item': linear-gradient($grey-10 18px, transparent 0),
                'background-position': 10px 5px,
                'background-size': calc(100% - 20px) 18px,
            ),
            (
                'background-item': linear-gradient($grey-10 18px, transparent 0),
                'background-position': 10px 35px,
                'background-size': calc(100% - 20px) 18px,
            ),
            (
                'background-item': linear-gradient($grey-10 18px, transparent 0),
                'background-position': 10px 65px,
                'background-size': calc(100% - 20px) 18px,
            ),
            (
                'background-item': linear-gradient($grey-10 18px, transparent 0),
                'background-position': 10px 95px,
                'background-size': calc(100% - 20px) 18px,
            )
        );

        @include pnp-loading(100%, 120px, $loading-backgrounds);
    }
}

.personal-document-listing-message {
    @include pnp-typeface(text-16, no-margin);

    &__icon {
        position: absolute;
        top: 0;
        left: -50px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 95px;
        background: $white;

        border: 2px solid $grey-10;
        border-radius: 360px;
        aspect-ratio: 1/1;

        .svg-icon {
            color: $grey-40;
        }

        &-block {
            position: absolute;
            right: 12px;
            bottom: 12px;
            background: $white;
        }
    }

    &__error-retry {
        @include pnp-link-button;
    }
}

.personal-document-listing-sign-in {
    &__message {
        a {
            @include pnp-typeface(text-16, no-margin);
            @include pnp-link-text;
        }

        .google-api-auth,
        .microsoft-auth {
            margin-top: 5px;
        }
    }
}
