.no-results {
    margin-bottom: pxToRem(30);
    padding: pxToRem(50) pxToRem(10) pxToRem(50) pxToRem(10);
    text-align: center;
    background-color: $grey-10;
    border-radius: 10px;
    @include pnp-media-breakpoint-up(md) {
        padding: pxToRem(100) pxToRem(10) pxToRem(100) pxToRem(10);
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-20-bold);
        @include pnp-svg-icon-classes;

        svg {
            display: block;
            margin: 0 auto pxToRem(10) auto;
            color: $primary-a;
        }
    }
}
