@import '../../slider/css/slider-mixins';

// Background overlay percentages
$overlay-percentages: 10, 20, 30, 40, 50;

// Navigation variables
$nav-colour: $grey-20;
$nav-colour--active: $grey-70;

// Define positions for the image
$x-positions: left, right, center;
$y-positions: top, bottom, center;

:root {
    --carousel-animation-duration: 0.5s;
    --carousel-slide-duration: 3s;
}

.carousel {
    @include slider;

    &[data-carousel-show-navigation='false'] {
        .carousel-navigation {
            display: none;
        }
    }

    &[data-carousel-show-buttons='false'] {
        .carousel-button {
            display: none;
        }
    }

    &[data-carousel-should-autoplay='false'] {
        .carousel-button__autoplay-state {
            display: none;
        }
    }
}

.carousel-item {
    @include slider-item;
    @include pnp-svg-icon-classes;
    position: relative;
    align-items: center;
    justify-content: center;

    &--bg-solid {
        // Theme the bg solid based on the palette variable
        .carousel-item__contents {
            background: var(
                --pnp-palette-bg-color
            ); // Set via pnp-palette-colours
        }
    }

    &--palette {
        &-a {
            @include pnp-palette-colours(palette-a);
            @include pnp-media-breakpoint-up(md) {
                &:not(.carousel-item--bg-solid) {
                    background-color: transparent;

                    .carousel-item__contents-container {
                        background-color: transparent;
                    }
                }
            }

            .carousel-item__cta {
                @include pnp-palette-button(palette-a);
            }
        }

        &-b {
            @include pnp-palette-colours(palette-b);
            @include pnp-media-breakpoint-up(md) {
                &:not(.carousel-item--bg-solid) {
                    background-color: transparent;

                    .carousel-item__contents-container {
                        background-color: transparent;
                    }
                }
            }

            .carousel-item__cta {
                @include pnp-palette-button(palette-b);
            }
        }
    }

    &__contents-container {
        @include pnp-max-width-container;

        &--pull-right {
            @include pnp-pull-right;

            .carousel-item__contents {
                @include pnp-media-breakpoint-up(md) {
                    width: 50%;
                }
            }
        }

        &--pull-left {
            @include pnp-pull-left;

            .carousel-item__contents {
                @include pnp-media-breakpoint-up(md) {
                    width: 50%;
                }
            }
        }
    }

    &__contents {
        @include pnp-typeface(text-24, false);
        margin: 62px 0;
        overflow-wrap: break-word;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include pnp-reset-heading;
            @include pnp-typeface(heading-32-bold, false);
            margin-bottom: 25px;

            @include pnp-media-breakpoint-up(md) {
                @include pnp-typeface(heading-48-bold, false);
            }
            @include pnp-media-breakpoint-up(xl) {
                @include pnp-typeface(heading-64-bold, false);
            }
        }
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
    }

    &__cta {
        @include pnp-button;
        display: inline-flex;
        align-items: center;
        margin-right: 36px;
        color: $primary-a;
        background: $white;

        &:hover,
        &:focus {
            @include pnp-button-hover-focus();
            color: $white;
            background: $primary-a;
            border-color: $white;
        }

        .svg-icon {
            margin: 0 0.5rem;
        }
    }

    &__link {
        @include pnp-link-button-size;
        color: inherit;
    }

    &__image {
        // Define positions for the image
        $x-positions: left, right, center;
        $y-positions: top, bottom, center;
        position: absolute;
        top: 0;
        left: 0;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';

        @each $x-position in $x-positions {
            &--#{$x-position} {
                object-position: #{$x-position};
                font-family: 'object-fit: cover; object-position: #{$x-position};';
            }

            @each $y-position in $y-positions {
                &--#{$x-position}-#{$y-position} {
                    object-position: #{$x-position} #{$y-position};
                    font-family: 'object-fit: cover; object-position: #{$x-position} #{$y-position};';
                }
            }
        }

        @each $y-position in $y-positions {
            &--#{$y-position} {
                object-position: #{$y-position};
                font-family: 'object-fit: cover; object-position: #{$y-position};';
            }
        }
    }

    @each $overlay-percentages in $overlay-percentages {
        &--overlay-#{$overlay-percentages} {
            &::after {
                background-color: transparentize(
                    $black,
                    (100 - $overlay-percentages) * 0.01
                );
            }
        }
    }
}

.carousel-button {
    @include slider-button;

    &__autoplay-state {
        position: absolute;
        right: 0;
        bottom: 0;
        height: auto;

        &--playing {
            .carousel-button__autoplay-icon--play {
                display: none;
            }
        }

        &--paused {
            .carousel-button__autoplay-icon--pause {
                display: none;
            }
        }
    }
}

.carousel-navigation {
    @include pnp-reset-button;
    z-index: $carousel-controls;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    margin: auto auto 0;
    margin: auto auto 0;
    padding: 12px 0;

    @include pnp-media-breakpoint-up(lg) {
        display: flex;
    }

    &__list {
        @include pnp-list-reset;
        display: flex;
    }

    &__item {
        @include pnp-reset-button;
        z-index: $carousel-controls;
        display: block;
        width: 42px;
        height: 5px;
        margin: 0 5px;
        background: $white;
        border-radius: 0;
        cursor: pointer;
        transition: background-color 0.3s, border-color 0.3s;
        pointer-events: auto;

        @include pnp-media-prefers-reduced-motion {
            transition: none;
        }

        &::after {
            content: unset;
        }

        &--active {
            background-color: transparentize($white, 0.2);
        }
    }

    &__item-text {
        @include pnp-visuallyhidden;
    }
}
