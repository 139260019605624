.utility-nav {
    @include pnp-print-hide;
    @include pnp-max-width-container;
    @include pnp-svg-icon-classes;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @include pnp-media-breakpoint-down(xl) {
        display: none;
    }

    &__skip-link {
        display: inline-flex;
        align-items: center;
        margin-right: 1rem !important;
        color: $black;
        text-decoration: none;
        background: $primary-a-light;

        &:not(.visuallyhidden) {
            margin-bottom: 0.25rem;
            padding: 0.75rem 1rem !important;
        }

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        .svg-icon {
            margin-right: 0.5rem;
            transform: rotate(90deg);
        }
    }
}

.utility-nav-links {
    display: flex;
    align-items: center;
    padding-bottom: 0.25rem;

    &__group {
        display: flex;
    }

    &__group:last-child {
        .utility-nav-links__link {
            margin-right: 0;
        }
    }

    &__group--socials {
        .utility-nav-links__link {
            margin: 0;
            padding: 1rem 0.25rem;
        }
    }

    &__group--actions {
        margin-left: 2rem;
    }

    &__social-message {
        display: none;
    }

    &__link {
        @include pnp-typeface(text-14, false);
        display: inline-flex;
        align-items: center;
        margin-right: 0.75rem;
        padding: 0.75rem 1rem;
        color: $black;
        text-decoration: none;

        &:not(.utility-nav-links__link--icon-only) {
            .svg-icon {
                margin-right: 0.75rem;
            }
        }

        &--button {
            @include pnp-reset-button;
            @include pnp-button;
            margin: auto;
            color: $white;
        }
    }
}

// On tablet + mobile utility nav is moved to the hamburger menu
.hamburger-menu {
    .utility-nav-links {
        display: block;
        flex-shrink: 0;

        margin-top: 1rem;
        padding: 0 1.5rem 1rem;
        background: rgba($primary-a-light, 0.3);

        &__group {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding-top: 0.5rem;
        }

        &__social-message {
            @include pnp-typeface(text-14, false);
            display: inline;
            margin-left: 0.75rem;
        }

        &__link {
            padding: 0.5rem 0.75rem;

            &--icon-only {
                padding: 0.5rem;
            }
        }
    }
}
