/*
    Plug and play default colour palette.

*/
//$pnp-main-font-url: 'https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap';
$pnp-main-font-url: 'https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap';

$heading-font: sans-serif;

$container-spacing-values: (
    none: 0,
    small: 30px,
    medium: 50px,
    large: 70px,
    x-large: 90px,
    xx-large: 110px,
);

// Branding Colours
$primary-a: #da1007;
$primary-b: #f8cf47;
$primary-c: #445d79;
$primary-a-light: #e3f1ff;
$primary-b-light: #f6f4ec;
$primary-a-superlight: #f4f9ff;

// Branding Secondary
$secondary-a: #e0e1dd;
$secondary-b: #3b2c35;
$secondary-c: #2a1f2d;

// Neutrals
$white: #fff;
$grey-10: #f4f4f4;
$grey-20: #dadada;
$grey-40: #aeaeae;
$grey-70: #707070;
$grey-80: #404040;
$black: #262626;
$real-black: #000;

// Utility
$utility-alert: #c83532;
$utility-light: #f6d25a;
$utility-dark: #05345b;
$utility-link: #0964af;
$utility-focus: #5d9af8;

// Social
$social-twitter: #1da1f2;
$social-youtube: #f00;
$social-facebook: #4267b2;

// Selection highlighting
$content-selection-highlight: #b3d4fc;

$base-font-scale: 1; // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: $black;

/*  Define easy variables for breakpoints & setting bootstrap breakpoints
    IF YOU ALTER ANY OF THESE YOU MUST UPDATE src/modules/_global/js/screenSize.js
*/
$xs: map_get($pnp-grid-breakpoints, xs);
$sm: map_get($pnp-grid-breakpoints, sm);
$md: map_get($pnp-grid-breakpoints, md);
$lg: map_get($pnp-grid-breakpoints, lg);
$xl: map_get($pnp-grid-breakpoints, xl);
$xxl: map_get($pnp-grid-breakpoints, xxl);

$max-width: $xxl;
$content-max-width: 980px;
$sidebar-width: 300px;
$sidebar-margin: 32px;

// Define the grid breakpoints so that these will overwrite the bootstrap breakpoints if the above values are changed
// IF YOU ALTER ANY OF THESE YOU MUST UPDATE src/modules/_global/js/screenSize.js
$grid-breakpoints: (
    xs: $xs,
    sm: $sm,
    md: $md,
    lg: $lg,
    xl: $xl,
    xxl: $xxl,
);

$pnp-buttons: (
    primary-a: (
        bg: $primary-a,
        text: $white,
        border: $primary-a,
        hover-bg: $primary-c,
        hover-text: $white,
        hover-border: $primary-c,
        disabled-text: $grey-40,
    ),
    primary-b: (
        text: $primary-a,
        hover-bg: $primary-a,
        hover-text: $white,
        disabled-text: $grey-40,
    ),
);

$pnp-palettes: (
    palette-a: (
        bg: $primary-a,
        text: $white,
        
        buttons: map_get($pnp-buttons, primary-b),
    ),
    palette-b: (
        bg: $white,
        text: $primary-a,
        buttons: map_get($pnp-buttons, primary-a),
    ),
);

// Helper variables to make changing mixins easier
// DO NOT DIRECTLY USE THESE, ONLY CHANGE IN THEMES; use via MIXIN
$default-button: map_get($pnp-buttons, primary-a);
$mixin-link-color: $primary-a;

/*
    Plug and Play typeface config.

    This is a two dimensional SASS Map. Typeface config can be accessed via map-get,
    to access properties nested map-get calls are required. See 'get-typeface-config' below.
*/
$pnp-typeface: (
    text-24: (
        font-size: 24px,
        line-height: 32px,
        margin-bottom: 16px,
        font-weight: 400,
    ),
    text-18: (
        font-size: 18px,
        line-height: 26px,
        margin-bottom: 12px,
        font-weight: 400,
    ),
    text-16: (
        font-size: 16px,
        line-height: 24px,
        margin-bottom: 12px,
        font-weight: 400,
    ),
    text-14: (
        font-size: 14px,
        line-height: 18px,
        margin-bottom: 8px,
        font-weight: 400,
    ),
    text-12: (
        font-size: 12px,
        line-height: 18px,
        margin-bottom: 8px,
        font-weight: 400,
    ),
    text-24-bold: (
        font-size: 24px,
        line-height: 32px,
        margin-bottom: 16px,
        font-weight: 700,
    ),
    text-18-bold: (
        font-size: 18px,
        line-height: 26px,
        margin-bottom: 12px,
        font-weight: 700,
    ),
    text-16-bold: (
        font-size: 16px,
        line-height: 24px,
        margin-bottom: 12px,
        font-weight: 700,
    ),
    text-14-bold: (
        font-size: 14px,
        line-height: 18px,
        margin-bottom: 8px,
        font-weight: 700,
    ),
    text-12-bold: (
        font-size: 12px,
        line-height: 18px,
        margin-bottom: 8px,
        font-weight: 700,
    ),
    heading-64: (
        font-size: 64px,
        line-height: 84px,
        margin-bottom: 48px,
        font-weight: 400,
    ),
    heading-48: (
        font-size: 48px,
        line-height: 64px,
        margin-bottom: 32px,
        font-weight: 400,
    ),
    heading-32: (
        font-size: 32px,
        line-height: 44px,
        margin-bottom: 16px,
        font-weight: 400,
    ),
    heading-26: (
        font-size: 26px,
        line-height: 36px,
        margin-bottom: 16px,
        font-weight: 400,
    ),
    heading-20: (
        font-size: 20px,
        line-height: 24px,
        margin-bottom: 16px,
        font-weight: 400,
    ),
    heading-18: (
        font-size: 18px,
        line-height: 26px,
        margin-bottom: 16px,
        font-weight: 400,
    ),
    heading-64-bold: (
        font-size: 64px,
        line-height: 84px,
        margin-bottom: 48px,
        font-weight: 700,
    ),
    heading-48-bold: (
        font-size: 48px,
        line-height: 64px,
        margin-bottom: 32px,
        font-weight: 700,
    ),
    heading-32-bold: (
        font-size: 32px,
        line-height: 44px,
        margin-bottom: 16px,
        font-weight: 700,
    ),
    heading-26-bold: (
        font-size: 26px,
        line-height: 36px,
        margin-bottom: 16px,
        font-weight: 700,
    ),
    heading-20-bold: (
        font-size: 20px,
        line-height: 24px,
        margin-bottom: 16px,
        font-weight: 700,
    ),
    heading-18-bold: (
        font-size: 18px,
        line-height: 26px,
        margin-bottom: 16px,
        font-weight: 700,
    ),
);

// Default transition values
$pnp-default-transition-type: ease !default;
$pnp-default-transition-duration: 0.5s !default;
$pnp-default-scale-ratio: 1.05 !default;

$pnp-ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1) !default;

$pnp-button-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out !default;//
/*
    Plug and play Stencils colour palette.

    WARNING: If you change the keys/names of these you will need to update the Matrix CCT
    metadata select field keys where these are used to derive background and text colour classes.
*/

$pnp-primary-colours: (
    primary-a: #10263d,
    primary-b: #f8cf47,
    primary-c: #445d79,
    primary-a-light: #e3f1ff,
    primary-b-light: #f6f4ec,
    primary-a-superlight: #f4f9ff,
);

$pnp-secondary-colours: (
    secondary-a: #e0e1dd,
    secondary-b: #3b2c35,
    secondary-c: #2a1f2d,
);

$pnp-neutral-colours: (
    white: #fff,
    grey10: #f4f4f4,
    grey20: #dadada,
    grey40: #aeaeae,
    grey70: #707070,
    grey80: #404040,
    black: #262626,
);
