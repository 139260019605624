.shortlist-drawer {
    .drawer {
        &__controllers {
            @include pnp-svg-icon-classes;
            display: flex;
            justify-content: space-between;
            padding: 0 pxToRem(20);
            color: $white;
            background-color: $black;
        }

        &__close {
            padding: 0;
            color: $primary-a;
            background-color: transparent;
        }

        &__content {
            .search-shortlist__hide {
                display: none;
            }
        }
    }
}
