.facet-drawer {
    .drawer {
        &__controllers {
            @include pnp-svg-icon-classes;
            display: flex;
            justify-content: space-between;
            padding: 0 pxToRem(20);
            color: $white;
            background-color: $black;
        }

        &__close {
            padding: 0;
            color: $primary-a;
            background-color: transparent;
        }

        &__content {
            .facet {
                padding: 0;
                border: 0;
                @include pnp-media-breakpoint-down(xl) {
                    max-width: 100%;
                }
            }

            .facet-group {
                padding-right: pxToRem(20);
                padding-bottom: pxToRem(20);
                padding-left: pxToRem(20);
                border-bottom: 1px solid $grey-10;

                &__title {
                    @include pnp-typeface(heading-18-bold);
                    padding: 0;
                    color: $black;
                    background-color: transparent;
                }

                &__list-item {
                    color: $black;
                }
            }

            .facet-groups-controller {
                display: none;
            }
        }
    }
}
