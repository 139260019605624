@use 'sass:string';

@import './mixins/background';
@import './mixins/positioning';
@import './mixins/maps';
@import './mixins/transitions';
@import './mixins/loading';
@import './accessibility';

// CSS arrow helper
// $direction: top, left, right, bottom
@mixin arrow($direction: top, $color: #000, $size: 5px, $height: 0) {
    $_height: $size + $height;
    width: 0;

    height: 0;
    border-style: solid;
    content: ' ';

    @if $direction== 'top' {
        border-color: transparent transparent $color transparent;
        border-width: $size $size $_height $size;
    } @else if $direction== 'right' {
        border-color: transparent transparent transparent $color;
        border-width: $size $size $size $_height;
    } @else if $direction== 'bottom' {
        border-color: $color transparent transparent transparent;
        border-width: $_height $size $size $size;
    } @else if $direction== 'left' {
        border-color: transparent $color transparent transparent;
        border-width: $size $_height $size $size;
    }
}

@mixin pnp-list-reset() {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin pnp-visuallyhidden() {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    border: 0;
    clip: rect(0 0 0 0);
}

@mixin pnp-visuallyshown() {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
}

/* P&P template custom */
@mixin pnp-max-width-container {
    max-width: $max-width;
    margin: auto;
}

@mixin pnp-background-breakout {
    position: relative;
    left: calc(-1 * (100vw - 100%) / 2);
    width: 100vw;
}

@mixin pnp-reset-heading {
    margin: 0;
    padding: 0;
    font: inherit;
    /* stylelint-disable-next-line property-disallowed-list */
    font-size: 100%;
    vertical-align: baseline;
    border: 0;
}

@mixin pnp-reset-button {
    padding: 0;
    overflow: visible;
    color: inherit;
    font: inherit;
    line-height: inherit;
    text-align: inherit;
    background: none;
    border: 0;
    cursor: pointer; // Still need to indicate interactivity
}

@mixin pnp-reset-text-input {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
    box-shadow: none;
}

@mixin pnp-link-text {
    @include pnp-print-scheme;
    color: $mixin-link-color;
}

/* Button that looks like a link */
@mixin pnp-link-button {
    @include pnp-print-hide;
    @include pnp-reset-button;
    color: $mixin-link-color;
}

@mixin pnp-button-hover-focus($style: $default-button) {
    color: map_get($style, hover-text);
    background-color: map_get($style, hover-bg);
    border-color: map_get($style, hover-border);
}

@mixin pnp-button($style: $default-button) {
    @include pnp-print-hide;
    @include pnp-typeface(text-18, false);
    display: inline-block;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    border: 1px solid transparent;
    outline-offset: 3px;
    cursor: pointer;
    transition: $pnp-button-transition;
    user-select: none;

    @if ($style != no-style) {
        color: map_get($style, text);
        background-color: map_get($style, bg);
        border-color: map_get($style, border);

        &:hover {
            @include pnp-button-hover-focus;
        }

        &:focus {
            @include pnp-button-hover-focus;
        }
    }

    @media (prefers-reduced-motion: reduce) {
        transition: none;
    }
}

// Style a link to be the same size as a button, same padding, border ect
@mixin pnp-link-button-size {
    @include pnp-typeface(heading-18, false);
    display: inline-block;
    padding: 0.5rem 1rem;
    color: $mixin-link-color;
    text-decoration: none;
    border: 1px solid transparent; // Fake border to match the one the button has

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}

@mixin pnp-ratio16by9-wrapper {
    position: relative;
    height: 0;
    // Set the height to a percent which makes it the correct aspect ratio. Videos are 16:9 and 9/16 = 0.5625
    padding-bottom: 56.25%;
}

@mixin pnp-ratio16by9-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin pnp-svg-icon-classes {
    .svg-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        vertical-align: middle;
        fill: currentColor;

        &--x-small {
            width: 14px;
            height: 14px;
        }

        &--small {
            width: 16px;
            height: 16px;
        }

        &--large {
            width: 32px;
            height: 32px;
        }

        &--xlarge {
            width: 48px;
            height: 48px;
        }

        &--xxlarge {
            width: 64px;
            height: 64px;
        }
    }
}

@mixin pnp-standard-box-shadow {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}

@mixin pnp-standard-transition {
    transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

@mixin pnp-link-disabled {
    cursor: not-allowed;
}

// Checkbox and radio button shared styles
%pnp-shared-input-checkbox-radio-button-styles {
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;

    + label {
        @include pnp-typeface(text-18, true);
        position: relative;
        display: inline-flex;
        align-items: center;
        margin-left: 10px;
        cursor: pointer;
        user-select: none;

        &::before {
            position: relative;
            display: block;
            flex-shrink: 0;
            box-sizing: border-box;
            width: 20px;
            height: 20px;
            margin-right: 8px;
            background-color: $grey-10;
            border: 1px solid $grey-40;
            transition: border-color 0.3s ease-in-out;
            content: '';
            @media (prefers-reduced-motion: reduce) {
                transition: none;
            }
        }

        &::after {
            position: absolute;
            display: none;
            border-color: $grey-40;
            border-style: solid;
            transition: border-color 0.3s ease-in-out,
                background-color 0.3s ease-in-out;
            content: '';
            @media (prefers-reduced-motion: reduce) {
                transition: none;
            }
        }
    }

    &:checked {
        + label {
            &::before {
                border-color: $grey-70;
            }

            &::after {
                display: block;
                border-color: $grey-70;
            }
        }

        &:hover,
        &:focus {
            + label {
                &::before {
                    border-color: $black;
                }

                &::after {
                    display: block;
                    border-color: $black;
                }
            }
        }
    }

    &:disabled {
        + label {
            color: $grey-70;

            &::before {
                background-color: $grey-10;
                border-color: $grey-20;
            }

            &::after {
                border-color: $grey-40;
            }
        }

        &:hover,
        &:focus {
            + label {
                color: $grey-70;

                &::before {
                    background-color: $grey-10;
                    border-color: $grey-20;
                }

                &::after {
                    border-color: $grey-40;
                }
            }
        }
    }

    &:hover,
    &:focus {
        + label {
            &::before {
                background-color: transparent;
                border-color: $black;
            }
        }
    }

    &:focus {
        + label {
            outline: 2px solid $utility-focus;
            outline-offset: 2px;
        }
    }
}

/**
 * Example markup
 * <input type="checkbox" checked="checked">
 * <label class="checkbox-container">One
 * </label>
 */
@mixin pnp-custom-checkbox {
    @extend %pnp-shared-input-checkbox-radio-button-styles;

    + label {
        &::after {
            top: calc(50% - 6px);
            left: 7px;
            width: 5px;
            height: 10px;
            border-width: 0 1px 1px 0;
            transform: rotate(45deg);
        }
    }
}

/**
 * Example markup
 * <input type="checkbox" checked="checked">
 * <label class="radio-container">One
 * </label>
 */
@mixin pnp-custom-radio-button {
    @extend %pnp-shared-input-checkbox-radio-button-styles;

    + label {
        &::before {
            border-color: $grey-70;
            border-radius: 50%;
        }

        &::after {
            left: 5px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }
    }

    &:checked {
        + label {
            &::after {
                background-color: $grey-70;
            }
        }

        &:hover,
        &:focus {
            + label {
                &::after {
                    background-color: $black;
                }
            }
        }

        &:disabled {
            + label {
                &::after {
                    background-color: $grey-40;
                }
            }

            &:hover,
            &:focus {
                + label {
                    background-color: $grey-40;
                }
            }
        }
    }
}

@mixin pnp-custom-select {
    &:not([multiple]) {
        position: relative;
        padding-right: 40px;
        background: url('data:image/svg+xml;utf8,<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8 9.5001L0.5 2.0001L1.55 0.9501L8 7.4001L14.45 0.9501L15.5 2.0001L8 9.5001Z" fill="black"/> </svg>')
            calc(100% - 0.75rem);
        background-repeat: no-repeat;
        border-radius: 0;
        appearance: none;
    }
}

/*
    Wraps the style in some additional constrains to make directly changing
    the HTML tag for wysiwyg editors safe on the rest of the framework.
*/
@mixin wysiwyg-safe() {
    $content-class: '.content';
    $wysiwyg-class: '.wysiwyg-allowed';
    $sel: '';

    @include pnp-print-scheme;

    // Disallow wysiwyg styling within a CCT and within Matrix form fields markup
    &:not(.no-wysiwyg *, .sq-form-question *, .pagination *) {
        @content;
    }

    /*
        Insert $wysiwyg-class before the last selector to make a .content .content-wysiwyg <tag> chain
        to allow the wysiwyg styling inline a CCT if in a .content-wysiwyg div
    */
    @each $selector in & {
        $selector-string: '' + $selector;
        $content-index: string.index($selector-string, $content-class);
        $content-length: string.length($content-class);
        $new-selector: string.insert(
            $selector-string,
            ' ' + $wysiwyg-class,
            $content-index + $content-length
        );

        @if $sel == '' {
            $sel: $new-selector;
        } @else {
            $sel: $sel + ',' + $new-selector;
        }
    }
    @at-root #{$sel} {
        @content;
    }
}

// Hides it for printers.
@mixin pnp-print-hide() {
    @media print {
        @include pnp-visuallyhidden;
    }
}

// Unify colors, backgrounds and borders for printers.
@mixin pnp-print-scheme() {
    @media print {
        color: $black !important;
        background: transparent !important;
        border: transparent !important;
    }
}

/*
   Auto generate overlay percentages based on storybook options. Resolves to:
   &--overlay-10 {
       background-color: transparentize(
               $black,
               (100 - 10) * 0.01
           );
   }
*/
@mixin pnp-generate-overlay(
    $zIndex: 2,
    $overlayPercentages: [10,
    20,
    30,
    40,
    50,
    60,
    70,
    80,
    90]
) {
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: $zIndex;
        width: 100%;
        height: 100%;
        content: '';
    }

    @each $overlay-percentages in $overlayPercentages {
        &--overlay-#{$overlay-percentages} {
            &::after {
                background-color: transparentize(
                    $black,
                    (100 - $overlay-percentages) * 0.01
                );
            }
        }
    }
}

@mixin pnp-component-heading {
    $padding: 14px;
    $height: 24px;
    @include pnp-reset-heading();
    @include pnp-typeface(text-14, false);
    @include pnp-svg-icon-classes();
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: #{$padding * 2 + $height};
    padding: 14px;
    color: $grey-80;
    line-height: 24px;
    background-color: $white;
    border: 1px solid $grey-20;

    &button {
        @include pnp-reset-button();
    }

    &-right {
        display: flex;
        align-items: inherit;
    }

    &-text {
        @include pnp-reset-heading();
        margin-right: 14px;
        color: $grey-80;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

@mixin pnp-focus-reset {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
}

@mixin pnp-scroll-y($scrollbar: auto) {
    overflow-y: $scrollbar;
    scrollbar-gutter: stable;
    overscroll-behavior-y: contain;
}

@mixin pnp-scroll-x($scrollbar: auto) {
    overflow-x: $scrollbar;
    scrollbar-gutter: stable;
    overscroll-behavior-x: contain;
}
