.event {
    @include pnp-svg-icon-classes;

    &__title {
        margin-bottom: 2rem;

        .svg-icon {
            position: relative;
            top: -4px;
            margin-right: 0.75rem;
            color: $primary-a;
        }

        &--banner {
            margin-right: calc(48px + 0.75rem);

            .svg-icon {
                color: $white;
            }
        }
    }

    &__title + &__info {
        margin-top: 2rem;
    }

    &__info {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        background: $grey-10;

        @include pnp-media-breakpoint-up(md) {
            flex-direction: row;
        }

        strong {
            @include pnp-typeface(text-18-bold, true);
        }
    }

    &__image-wrapper {
        flex-shrink: 0;
        width: 100%;
        min-height: 115px;
        margin-bottom: 1rem;

        @include pnp-media-breakpoint-up(md) {
            width: 220px;
            height: auto;
            margin-bottom: 0;
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        max-height: 280px;
        object-fit: cover;

        @include pnp-media-breakpoint-up(md) {
            max-height: none;
        }
    }

    &__time-location {
        @include pnp-typeface(text-18, false);
        flex: 2 1 0;
        align-self: center;

        @include pnp-media-breakpoint-up(md) {
            padding-left: 1rem;
        }
        @include pnp-media-breakpoint-up(lg) {
            @include pnp-typeface(text-24, false);
        }

        .svg-icon {
            flex: 0 0 auto;
            margin-right: 0.75rem;
            color: $primary-a;
        }
    }

    &__time {
        display: flex;
        margin-bottom: 0.5rem;
    }

    &__location {
        display: flex;
    }

    &__location-link {
        display: inline-block;
        color: $black;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__booking {
        display: flex;
        flex: 0 0 auto;
        align-self: center;
        justify-content: center;
        width: 100%;
        padding: 1.5rem;

        @include pnp-media-breakpoint-up(md) {
            display: block;
            width: auto;
        }
        @include pnp-media-breakpoint-up(lg) {
            padding: 2.5rem;
        }
    }

    &__booking-link {
        @include pnp-button;

        .svg-icon {
            margin-left: 0.75rem;
        }
    }

    &__content {
        margin-bottom: 70px;
    }

    &__summary {
        margin-bottom: 1.5rem;

        @at-root .content & *:not(strong) {
            @include wysiwyg-safe {
                @include pnp-typeface(text-24, false);

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
