@import '../../modal/css/global';

.credits-loader {
    @include pnp-svg-icon-classes;
    @include pnp-button;
}

.credits-wrapper {
    @include pnp-svg-icon-classes;
    display: flex;

    &__action {
        @include pnp-button;
        position: relative;
        padding: 8px 12px;
    }
}

.credits-popover {
    &__dialog {
        @include pnp-standard-box-shadow;
        z-index: $popover;
    }
}

.credit-balance {
    @include pnp-svg-icon-classes;
    width: 350px;
    background: $white;

    &__header {
        @include pnp-typeface(heading-18-bold, false);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
    }

    &__header-button {
        @include pnp-reset-button;
        @include pnp-svg-icon-classes;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $grey-70;
    }

    &__header-button-icon {
        width: 24px;
        height: 24px;
        fill: currentColor;
    }

    &__balance {
        display: flex;
        padding: 10px 20px;
        background: $grey-10;
        border-top: solid 1px $grey-20;
        border-bottom: solid 1px $grey-20;

        &--low {
            padding-bottom: 30px;
            color: $utility-alert;
        }
    }

    &__balance-amount {
        @include pnp-typeface(text-16-bold, false);
        position: relative;
        flex: 1 1 0;
    }

    &__balance-amount-low {
        @include pnp-typeface(text-14, false);
        position: absolute;
        display: flex;

        .svg-icon {
            margin-right: 6px;
        }
    }

    &__balance-add {
        @include pnp-typeface(text-14, false);
        @include pnp-link-text;
        display: flex;
        flex: 1 1 0;
        align-items: center;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        .svg-icon {
            margin-right: 8px;
        }
    }

    &__transactions {
        padding: 20px;
    }

    &__transactions-list {
        @include pnp-list-reset;
    }

    &__all-transactions {
        @include pnp-link-text;
        display: block;
        margin-top: 15px;
        text-align: end;
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: underline;
        }

        .svg-icon {
            margin-right: 6px;
        }
    }
}

.credit-transaction {
    @include pnp-typeface(text-14, false);
    display: flex;
    margin-bottom: 10px;

    &__details {
        display: flex;
        flex: 1 1 0;
        flex-direction: column;
    }

    &__date {
        color: $grey-70;
    }

    &__amount {
        flex: 1 1 0;
        text-align: end;
    }
}
