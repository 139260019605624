@import '../../circular-progress/css/global';
@import './loading';

[data-component='analytics'] {
    height: 100%;
}

.analytics {
    &__container {
        display: flex;
        justify-content: space-around;
    }

    &__progress-details {
        @include pnp-typeface(text-14, false);

        display: flex;
        flex-direction: column;
        color: $grey-80;

        @include pnp-media-breakpoint-up(md) {
            flex-direction: row;
        }

        h4 {
            @include pnp-reset-heading;
            @include pnp-typeface(text-14-bold, false);
        }
    }

    &__progress-description {
        margin-left: 15px;
    }
}

.circular-progress-circle__chart--progress {
    stroke: $primary-a;
}

.circular-progress-circle__description {
    @include pnp-typeface(text-24-bold, false);
}
