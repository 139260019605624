.custom-form.search-tools {
    @include pnp-svg-icon-classes;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-top: pxToRem(5) 0;
    padding: 0 0.25rem;

    @include pnp-media-breakpoint-up(sm) {
        padding: 0;
    }

    .sq-form-question {
        margin-top: 0;
    }

    // Dropdown used for things like number of results and sort.
    .sq-form-question-select {
        width: 100%;
        @include pnp-media-breakpoint-up(sm) {
            width: fit-content;
            min-width: pxToRem(30);

            select {
                width: fit-content;
            }

            &:not(:last-child) {
                margin-right: pxToRem(5);
            }
        }
    }

    // Used to display the display mode; grid or list.
    .search-tools__button-group {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        @include pnp-media-breakpoint-up(sm) {
            width: fit-content;
        }
    }

    .search-tools__button {
        margin-right: pxToRem(10);
        color: $black;
        transition: color 0.3s ease-in-out, color 0.3s ease-in-out;

        &--inactive {
            color: $grey-20;

            &:hover,
            &:focus {
                color: $black;
            }
        }
    }
    @media screen and (prefers-reduced-motion: reduce) {
        .search-tools__button {
            margin-right: pxToRem(10);
            color: $black;
            transition: none;

            &--inactive {
                color: $grey-20;
                transition: none;

                &:hover,
                &:focus {
                    color: $black;
                }
            }
        }
    }
}
