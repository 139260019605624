.search-history {
    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-26-bold);
    }

    &__hide {
        @include pnp-svg-icon-classes;
        display: inline-flex;
        align-items: center;
        margin-bottom: pxToRem(10);
        padding: 0;
        color: $primary-a;
        text-decoration: underline;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        svg {
            transform: rotate(180deg);
        }
    }

    &__items {
        @include pnp-media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__item {
        margin: pxToRem(20px);
    }

    .history-panel {
        display: flex;
        flex-direction: column;
        padding: pxToRem(30);
        background-color: $grey-10;
        border-radius: 20px;
        @include pnp-media-breakpoint-up(md) {
            width: 45%;
        }

        &__title {
            @include pnp-svg-icon-classes;
            @include pnp-reset-heading;
            @include pnp-typeface(heading-20-bold);
            padding-bottom: pxToRem(30);
            border-bottom: 1px solid $grey-20;

            svg {
                margin-right: pxToRem(5);
            }
        }

        &__items {
            padding-left: 0;
            @include pnp-media-breakpoint-up(md) {
                padding-left: inherit;
            }
        }

        &__item {
            margin-top: pxToRem(20);
            list-style: none;

            a {
                color: $primary-a;
            }
        }

        &__last-visited {
            @include pnp-typeface(text-14);
            margin-top: pxToRem(5);
            color: $grey-70;
        }

        &__query {
            margin-left: pxToRem(5);
        }

        &__clear {
            @include pnp-svg-icon-classes;
            @include pnp-typeface(text-18-bold);
            display: inline-flex;
            width: fit-content;
            margin-top: auto;
            color: $primary-a;
            background-color: transparent;
            border: 0;
            cursor: pointer;
        }
    }
    display: block;
}
