/*
    Mixins related to the variables maps defined in the framework
*/
@mixin pnp-generate-background-for-colour-map($map) {
    @each $colour-name, $colour in $map {
        &#{'-' + $colour-name} {
            background: $colour;
        }
    }
}

@mixin pnp-generate-foreground-for-colour-map($map) {
    @each $colour-name, $colour in $map {
        &#{'-' + $colour-name} {
            color: $colour;
        }
    }
}
