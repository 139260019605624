.combobox {
    &__combobox-wrapper {
        position: relative;
        display: inline-block;
    }

    &__input {
        width: 200px;
        padding-right: 30px;
        line-height: 1.5em;
        border: 1px solid #aaa;
        border-radius: 2px;
    }

    &__status {
        @include pnp-visuallyhidden;

        &[data-active='false'] {
            display: none;
        }
    }
}

.combobox-listbox {
    @include pnp-list-reset;
    position: absolute;
    top: 1.7em;
    z-index: $min-z-index;
    display: none;
    min-width: 230px;
    background: $white;
    border: 1px solid #ccc;

    &[data-active='true'] {
        display: block;
    }

    &__item {
        margin: 0;
        cursor: default;

        &:hover,
        &:focus,
        &[aria-selected='true'] {
            background: rgb(139, 189, 225);
        }
    }
}
