@use 'sass:math';

.my-courses__card--loading {
    // Loading state variables
    $item-height: 54px;
    $title-height: 17px;

    // Loop through so that we make sections of differing widths
    @for $i from 0 through 14 {
        &:nth-of-type(#{$i}) {
            $state: (
                // Gray box 17px high
                'background-item':
                    linear-gradient($grey-10 17px, transparent 0),
                // Position the box 16px to the left of the margin and border width, and vertically in the center
                'background-position':
                    16px
                    center,
                // Make the width of the box between 0 and 75%
                'background-size':
                    math.div(random(75), 100) *
                    100%
                    17px
            );

            $inner: (
                // Make the white inner box
                'background-item':
                    linear-gradient($white $item-height, transparent 0),
                // Offset the verticle height to be verticle margin and border width
                'background-position':
                    0
                    center,
                // Set the size to be 100% width - the margin and the border, and the height to be the item height
                'background-size':
                    100%
                    $item-height
            );
            $background-border: (
                // Gray box height of the item plus the border
                'background-item':
                    linear-gradient($grey-10 $item-height, transparent 0),
                // Make the verticle offset 0 and horozontal centered
                'background-position':
                    0
                    center,
                // Set the size to be 100% width - horozontal margin, and the height to be height + the border
                'background-size':
                    100%
                    $item-height
            );

            // Loading state
            $loading-backgrounds: ($state, $inner, $background-border);

            @include pnp-loading(
                calc(100% - 26px),
                $item-height,
                $loading-backgrounds
            );
        }
    }
}
