@function get-typeface-config($name, $property) {
    @return map-get(map-get($pnp-typeface, $name), $property);
}

/**
* Mixin to include one of the Plug and Play typefaces.
* @param $name - The name of the typeface to use
* @param $include-margin - defaults to not including a margin, if a margin is needed pass true (passing false is also valid)
*/
@mixin pnp-typeface($name, $include-margin: false) {
    /* stylelint-disable property-disallowed-list */
    // prettier-ignore
    --pnp-typeface-margin: #{get-typeface-config($name, margin-bottom)}; // Yes include even if margin isn't included
    font-weight: get-typeface-config($name, font-weight);
    font-size: get-typeface-config($name, font-size);
    font-family: get-typeface-config($name, font-family);
    line-height: get-typeface-config($name, line-height);
    @if $include-margin == false {
        // Dont include
    } @else if $include-margin == true {
        margin-bottom: get-typeface-config($name, margin-bottom);
    }
    /* stylelint-enable property-disallowed-list */
}
