// Specific styles for template wrappers
.template-margin-top--under-nav {
    .hero-banner {
        padding-top: 112px;
    }
}

.template-margin-bottom--overlap-bottom {
    .hero-banner {
        padding-bottom: 125px;

        &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: $hero-banner-overlap-bottom;
            width: 100%;
            height: 125px;
            background: linear-gradient(
                0deg,
                rgba($white, 1) 0%,
                rgba($white, 0) 100%
            );
            content: '';
        }
    }
}

.template {
    // Apply a minimal top padding if no template padding class is present for desktops.
    @include pnp-media-breakpoint-up(lg) {
        &:not([class*='template-padding-top'], .template-margin-top--under-nav) {
            .hero-banner--bg-compact {
                padding-top: 20px;
            }
        }
    }

    &:not([class*='template-padding-bottom'], .template-margin-bottom--overlap-bottom) {
        .hero-banner--bg-compact {
            padding-bottom: 20px;
        }
    }
}

// Specific banner styles
.hero-banner-wrapper {
    @include pnp-max-width-container;
    @include pnp-print-hide;
}

.hero-banner {
    @include pnp-svg-icon-classes;
    @include pnp-print-hide;
    @include pnp-generate-overlay($hero-banner-overlay, [10, 20, 30, 40, 50]);

    position: relative;
    z-index: $component;
    height: 100vh;
    color: $white;
    background: $primary-a;

    // Dont breakout if its in a content-with-nav layout
    &:not(.page-layout--content-with-nav *) {
        @include pnp-background-breakout;
    }

    .hero-banner--settings,
    .hero-banner-wrapper {
        height: 100%;
    }

    /*
        By default the Hero Banner is 100vh, use &--bg-fixed to specify a fixed height
    */
    &--bg-fixed {
        @include pnp-background-fixed(400px);

        @include pnp-media-breakpoint-up(md) {
            @include pnp-background-fixed(600px);
        }
    }

    &--bg-solid {
        .hero-banner__contents {
            background: $primary-a;
        }
    }

    &--bg-compact {
        height: auto;
        max-height: 100%;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &--pull-left,
    &--pull-right,
    &--pull-centered {
        .hero-banner__contents {
            @include pnp-media-breakpoint-up(md) {
                width: 60%;
            }
        }
    }

    &--pull-left {
        .hero-banner__contents-container {
            @include pnp-media-breakpoint-up(md) {
                @include pnp-pull-left;
            }
        }
    }

    &--pull-right {
        .hero-banner__contents-container {
            @include pnp-media-breakpoint-up(md) {
                @include pnp-pull-right;
            }
        }
    }

    &--pull-centered {
        @include pnp-center;

        .hero-banner-search {
            &__form {
                justify-content: center;
            }
        }

        .hero-banner__actions {
            @include pnp-center;
            @include pnp-media-breakpoint-down(md) {
                justify-content: space-around;
            }
        }

        .hero-banner__cta {
            @include pnp-media-breakpoint-down(md) {
                margin-right: 0;
            }
        }
    }

    &__contents-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0 15px;
    }

    &__contents {
        @include pnp-typeface(text-24, false);
        position: relative;
        z-index: $hero-banner-contents;
        padding: 32px 28px;
        @include pnp-media-breakpoint-up(lg) {
            padding: 32px 45px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include pnp-reset-heading;
            @include pnp-typeface(heading-32-bold, false);
            margin-bottom: 1.5rem;
            @include pnp-media-breakpoint-up(md) {
                @include pnp-typeface(heading-48-bold, false);
            }
            @include pnp-media-breakpoint-up(xl) {
                @include pnp-typeface(heading-64-bold, false);
            }
        }
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
    }

    &__cta {
        @include pnp-button;
        display: flex;
        align-items: center;
        margin-right: 36px;
        margin-bottom: 0.5rem;
        color: $primary-a;
        background: $white;

        &:hover,
        &:focus {
            @include pnp-button-hover-focus();
            color: $white;
            background: $primary-a;
            border-color: $white;
        }

        .svg-icon {
            margin: 0 0.5rem;
        }
    }

    &__link {
        @include pnp-link-button-size;
        display: inline-block;
        margin-bottom: 0.5rem;
        color: inherit;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &__image {
        // Define positions for the image
        $x-positions: left, right, center;
        $y-positions: top, bottom, center;
        position: absolute;
        top: 0;
        left: 0;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        font-family: 'object-fit: cover;';

        @each $x-position in $x-positions {
            &--#{$x-position} {
                object-position: #{$x-position};
                font-family: 'object-fit: cover; object-position: #{$x-position};';
            }

            @each $y-position in $y-positions {
                &--#{$x-position}-#{$y-position} {
                    object-position: #{$x-position} #{$y-position};
                    font-family: 'object-fit: cover; object-position: #{$x-position} #{$y-position};';
                }
            }
        }

        @each $y-position in $y-positions {
            &--#{$y-position} {
                object-position: #{$y-position};
                font-family: 'object-fit: cover; object-position: #{$y-position};';
            }
        }
    }

    &__arrow-down {
        position: absolute;
        bottom: 1%;
        left: 50%;
        z-index: $hero-banner-contents;
        width: 43px;
        height: 43px;
        transform: translate(-50%, -50%);
    }

    &__js-scroll-btn {
        width: 43px;
        height: 43px;
        color: inherit;
        background: transparent;
        border: 0;
        opacity: 0.6;

        &:focus,
        &:hover {
            cursor: pointer;
            opacity: 1;
        }
    }

    &--palette {
        &-a {
            @include pnp-palette-colours(palette-a);

            .hero-banner__cta {
                @include pnp-palette-button(palette-a);
            }
        }

        &-b {
            @include pnp-palette-colours(palette-b);

            .hero-banner__cta {
                @include pnp-palette-button(palette-b);
            }
        }
    }
}

/*
    Hero Banner Search Variation
*/
.hero-banner {
    &-search {
        &__bar {
            width: 100%;
        }

        &__form {
            display: flex;
            width: 100%;
        }

        &__content {
            position: static;
            display: block;
            visibility: visible;
            opacity: 1;
        }

        .autocomplete-search__combobox-wrapper {
            display: initial;
        }

        &__input,
        .autocomplete-search__input {
            @include pnp-typeface(text-14, false);
            display: block;
            width: 100%;
            min-width: 12rem;
            padding: 0.5rem;
            padding-right: 42px;
            line-height: 30px;
            border: solid 1px $white;
            @include pnp-media-breakpoint-up(sm) {
                @include pnp-typeface(text-18, false);
                min-width: 18rem;
                line-height: 30px;
            }
        }

        &__input-label {
            @include pnp-typeface(text-16-bold, no-margin);
            display: block;
        }

        .autocomplete-search-listbox__item {
            color: $black;
        }

        &__clear {
            @include pnp-reset-button;
            position: absolute;
            top: calc(50% - 16px);
            right: 8px;
            display: none;
        }

        &__submit {
            @include pnp-reset-button;
            display: inline-flex;
            padding: 0.75rem 1rem;
            color: $primary-a;
            background-color: $white;

            cursor: pointer;
            transition: background-color 0.25s ease, color 0.25s ease;

            &:hover,
            &:focus {
                color: $white;
                background-color: $primary-a;
                outline-offset: -2px;
            }
            @media (prefers-reduced-motion: reduce) {
                transition: none;
            }
        }
    }
}
