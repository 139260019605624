.emails-list--loading {
    $title: (
        'background-item': linear-gradient($grey-10 18px, transparent 0),
        'background-position': 0 0,
        'background-size': 200px 18px,
    );

    $subtitle: (
        'background-item': linear-gradient($grey-10 14px, transparent 0),
        'background-position': 0 20px,
        'background-size': 300px 14px,
    );

    $loading-backgrounds: ($title, $subtitle);
    @include pnp-loading(300px, 50px, $loading-backgrounds);
}
