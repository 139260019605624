/* stylelint-disable selector-max-compound-selectors, max-nesting-depth, selector-no-qualifying-type */
@import '../../buttons/css/button-mixins';

.content {
    @include wysiwyg-safe {
        // If this is changed, update the P and LI tags which use the margin for this size
        @include pnp-typeface(text-18, false);
    }

    // These button classes should only be used by content editors, never by developers, to use the buttons you should include the appropriate mixin from src/modules/buttons/css/button-mixins.scss
    @include wysiwyg-safe {
        .button {
            @include button;

            &--dark-bg {
                @include pnp-button-dark-bg;
            }

            &--light-bg {
                @include pnp-button-light-bg;
            }

            &--dark-ghost {
                @include pnp-button-dark-ghost;
            }

            &--light-ghost {
                @include pnp-button-light-ghost;
            }

            &--dark-borderless {
                @include pnp-button-dark-borderless;
            }

            &--light-borderless {
                @include pnp-button-light-borderless;
            }

            &--colour-primary-a {
                @include pnp-button-colour-primary-a;
            }

            &--colour-primary-b {
                @include pnp-button-colour-primary-b;
            }

            &--colour-primary-c {
                @include pnp-button-colour-primary-c;
            }
        }
    }

    h1 {
        @include wysiwyg-safe {
            @include pnp-reset-heading;
            @include pnp-typeface(heading-32-bold, true);
        }
    }

    h2 {
        @include wysiwyg-safe {
            @include pnp-reset-heading;
            @include pnp-typeface(heading-26-bold, true);
        }
    }

    h3 {
        @include wysiwyg-safe {
            @include pnp-reset-heading;
            @include pnp-typeface(heading-26, true);
        }
    }

    h4 {
        @include wysiwyg-safe {
            @include pnp-reset-heading;
            @include pnp-typeface(heading-20-bold, true);
        }
    }

    h5 {
        @include wysiwyg-safe {
            @include pnp-reset-heading;
            @include pnp-typeface(heading-20, true);
        }
    }

    h6 {
        @include wysiwyg-safe {
            @include pnp-reset-heading;
            @include pnp-typeface(heading-18-bold, true);
        }
    }

    p {
        @include wysiwyg-safe {
            // Set margin top matching with typeface bottom margin
            margin-top: var(--pnp-typeface-margin);
            margin-bottom: var(--pnp-typeface-margin);
        }
    }

    a {
        @include wysiwyg-safe {
            color: $primary-a;
        }
    }

    img {
        @include wysiwyg-safe {
            height: auto;
        }
    }

    .button {
        @include wysiwyg-safe {
            @include pnp-reset-button();
            @include pnp-button();
        }
    }

    ul,
    ol {
        @include wysiwyg-safe {
            margin: 0.75rem 0;
            padding: 0;
            list-style: none;

            @include pnp-media-breakpoint-up(md) {
                margin: 1.5rem 0;
            }
        }
    }

    li {
        @include wysiwyg-safe {
            position: relative;
            margin-bottom: var(--pnp-typeface-margin);
            padding-left: 1.25rem;

            @include pnp-media-breakpoint-up(md) {
                padding-left: 2rem;
            }

            &::before {
                position: absolute;
                left: 0.25rem;
                color: $primary-a;

                @include pnp-media-breakpoint-up(md) {
                    left: 0.75rem;
                }
            }
        }
    }

    ul {
        @include wysiwyg-safe {
            li {
                &::before {
                    content: '\25CF'; //●
                }

                > ul > li::before {
                    content: '\25CB'; //○
                }

                > ul > li > ul > li::before {
                    content: '\25CF'; //●
                }

                > ul > li > ul > li > ul > li::before {
                    content: '\25CB'; //○
                }
            }
        }
    }

    ol {
        @include wysiwyg-safe {
            // Use a counter to keep track of the numbering
            counter-reset: ol-section;

            > li {
                counter-increment: ol-section;
            }

            ol li::before {
                content: counters(ol-section, '.') ' ';
            }

            li {
                //counter-increment: ol-section;
                &::before {
                    content: counter(ol-section) '';
                }

                > ol > li {
                    padding-left: 2rem;
                    @include pnp-media-breakpoint-up(md) {
                        padding-left: 3rem;
                    }
                }

                > ol > li > ol > li {
                    padding-left: 3rem;
                    @include pnp-media-breakpoint-up(md) {
                        padding-left: 4rem;
                    }
                }

                > ol > li > ol > li > ol > li {
                    padding-left: 4rem;
                    @include pnp-media-breakpoint-up(md) {
                        padding-left: 5rem;
                    }
                }

                > ol > li > ol > li > ol > li > ol > li {
                    padding-left: 5rem;
                    @include pnp-media-breakpoint-up(md) {
                        padding-left: 6rem;
                    }
                }

                ul > li {
                    &::before {
                        content: '\25CF'; //●
                    }
                }
            }
        }
    }

    /*
        For some reason the CSS counter doesn't reset correctly when an OL is placed
        next to a UL which has an OL inside it; so use a different counter inside ULs
    */
    ul ol {
        @include wysiwyg-safe {
            counter-reset: ul-ol-section;

            > li {
                counter-increment: ul-ol-section;

                &::before {
                    content: counter(ul-ol-section) ' ';
                }
            }

            ol li::before {
                content: counters(ul-ol-section, '.') ' ';
            }
        }
    }

    table {
        @include wysiwyg-safe {
            @include pnp-scroll-x;
            display: block;
            width: 100%;
            margin: 1.5rem 0;
            border-collapse: collapse;

            @include pnp-media-breakpoint-down(lg) {
                white-space: nowrap;
            }

            th {
                padding: 0.75rem 1.25rem;
                font-weight: bold; /* stylelint-disable-line property-disallowed-list */ //Allow here to let font-size cascade correctly
                text-align: left;
                border-bottom: 1px solid $black;
            }

            td {
                padding: 0.75rem 1.25rem;
                border-bottom: 1px solid $grey-20;
            }

            &.table--striped {
                tbody {
                    tr:nth-child(odd) {
                        td {
                            background-color: $grey-10;
                        }
                    }
                }
            }

            &.table--bordered {
                th,
                td {
                    border: 1px solid $grey-20;
                }
            }

            @each $palette-name, $palette in $pnp-palettes {
                &#{'.table--head-' + $palette-name} {
                    thead {
                        th {
                            color: map-get($palette, text);
                            background-color: map-get($palette, bg);
                            border-bottom-color: map-get($palette, bg);
                        }
                    }
                }
            }
        }
    }

    @each $typefaceName, $styles in $pnp-typeface {
        /*
            $styles is defined just so we can get the key, use the existing functions that match
            the existing typeface mixins to build for consistency.
        */
        .typeface-#{$typefaceName} {
            @include wysiwyg-safe {
                /* stylelint-disable property-disallowed-list */
                // prettier-ignore
                --pnp-typeface-margin: #{get-typeface-config($typefaceName, margin-bottom)};
                margin-bottom: get-typeface-config(
                    $typefaceName,
                    margin-bottom
                );
                font-weight: get-typeface-config($typefaceName, font-weight);
                font-size: get-typeface-config($typefaceName, font-size);
                line-height: get-typeface-config($typefaceName, line-height);
                /* stylelint-enable property-disallowed-list */
            }
        }
    }
}
