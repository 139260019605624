.stencils__main {
    .hero-banner {
        z-index: auto;
        height: pxToRem(300);
    }

    .tabs {
        position: relative;
        z-index: $stencils-tabs;
        margin-top: pxToRem(-70);
    }

    .funnelback-search {
        @include pnp-max-width-container();
        display: flex;
        flex-direction: column;
        max-width: $xxl;
        margin-top: 2rem;

        @include pnp-media-breakpoint-up(xl) {
            flex-direction: row-reverse;
        }

        &__body {
            padding: 0 pxToRem(10px);
            @include pnp-media-breakpoint-up(xl) {
                width: 85%;
            }
        }

        &__side {
            width: pxToRem(450);
            @include pnp-media-breakpoint-down(xl) {
                width: 100%;
            }
        }

        &__title {
            @include pnp-reset-heading;
            @include pnp-typeface(heading-26-bold);
        }
    }

    .stencils-summary-and-search-tools {
        margin-bottom: pxToRem(10);

        @include pnp-media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 0;
        }
    }

    .funnelback-search-result-spellcheck {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-20-bold);

        a,
        a:visited {
            color: $primary-a;
        }
    }

    .funnelback-search-facets-breadcrumb {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        &__label {
            @include pnp-typeface(text-16, no-margin);
        }

        &__facets-applied {
            display: flex;
            margin: 0;
            margin-left: 0.75rem;
            padding: 0;
            list-style-type: none;
        }

        &__facet {
            display: inline-flex;
            align-items: center;
        }

        &__button {
            @include pnp-reset-button;
            @include pnp-svg-icon-classes;
            @include pnp-typeface(text-14-bold, no-margin);
            padding: pxToRem(2) pxToRem(8);
            color: $black;
            text-decoration: none;
            background-color: $primary-a-light;

            &-all {
                color: $white;
                background-color: $primary-a;
            }

            &-reset {
                margin-left: pxToRem(16);
                color: $black;
                background-color: $grey-40;
            }
        }
    }
}

// Presentation logic specific for Funnelback for Higher Education.
.higher-education {
}
