// Mixins for specific positioning

@mixin pnp-pull-right {
    justify-content: flex-end;
}

@mixin pnp-pull-left {
    justify-content: flex-start;
}

@mixin pnp-center {
    justify-content: center;
    text-align: center;
}

// Adds negative margin to the top of an element while also adding the same amount of padding to the element
@mixin pnp-negative-margin-top($padding) {
    margin-top: -$padding;
    padding-top: $padding;
}

// Adds negative margin to the bottom of an element while also adding the same amount of padding to the element
@mixin pnp-negative-margin-bottom($padding) {
    margin-bottom: -$padding;
    padding-bottom: $padding;
}
