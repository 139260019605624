// Presentation logic specific for Program Finder.
.program-finder {
    .hero-banner {
        height: fit-content;
    }

    .funnelback-search__header {
        @include pnp-typeface(text-18, no-margin);
        color: $white;
        background-color: $black;
    }

    .funnelback-search__filters-button {
        margin-left: auto;
    }
}
