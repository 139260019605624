.calendar-modal {
    @include pnp-svg-icon-classes;

    &__content {
        min-width: 400px;
    }

    &__header {
        padding: 24px;
        padding-bottom: 16px;
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-20-bold, false);
    }

    &__header-close {
        @include pnp-reset-button;
        position: absolute;
        top: 12px;
        right: 12px;
    }

    &__body {
        padding: 0 24px;
    }

    &__footer {
        display: flex;
        justify-content: flex-end;

        padding: 24px;
    }

    &__close {
        @include pnp-button;
        color: $black;
        background: $grey-10;
        border-color: $grey-10;

        &:hover,
        &:focus {
            color: $black;
            background: $grey-20;
            border-color: $grey-20;
        }
    }

    &__link {
        @include pnp-button;
        margin-left: 8px;
        outline-offset: -2px;

        &:hover,
        &:focus {
            border-color: $primary-a;
        }

        .svg-icon {
            margin-right: 8px;
        }
    }
}

.calendar-event-details {
    &__time {
        @include pnp-typeface(text-14-bold, false);
        margin-bottom: 8px;
        color: $grey-70;
    }
}

// Assignment type
.calendar-event-details--assignment {
    .calendar-event-details {
        &__due {
            @include pnp-typeface(text-14-bold, false);
            position: relative;
            margin-bottom: 16px;
            padding: 16px;
            background: $primary-a-light;

            .svg-icon {
                position: absolute;
                top: 8px;
                right: 8px;
                color: $primary-a;
            }
        }

        &__due-title {
            @include pnp-reset-heading;
            @include pnp-typeface(heading-18-bold, false);
            margin-bottom: 4px;
        }

        &__sub-title {
            @include pnp-reset-heading;
            @include pnp-typeface(heading-18-bold, false);
            margin-bottom: 8px;
        }

        &__description {
            @include pnp-typeface(text-14, false);
        }

        &__submit-method {
            @include pnp-typeface(text-14, false);
            display: flex;
            align-items: center;
            margin-top: 20px;
            padding: 12px;
            background: $grey-10;

            svg {
                margin-right: 8px;
            }
        }

        &__submit-note {
            @include pnp-typeface(text-14, false);
            margin-top: 16px;
            font-style: italic;
        }
    }
}
