/* stylelint-disable */
.feature-branch-manager {
    &__actions {
        display: flex;
        flex-wrap: wrap;
    }

    &__reset,
    &__set {
        @include pnp-button;
        margin-right: 16px;
        margin-bottom: 4px;
    }
}

.feature-branch-manager-link-creator {
    width: 500px;
    margin-top: 16px;
    background: $grey-10;
    padding: 16px;

    div {
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;

        &:first-child {
            margin-top: 0;
        }
    }

    label {
        display: inline-block;
        min-width: 100px;
        font-weight: bold;
    }

    input,
    select {
        width: 100%;
    }

    button {
        @include pnp-button;
    }
}

.feature-branch-manager-output {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 500px;
    margin-top: 16px;
    padding: 16px;
    background: $grey-10;

    &__link {
        width: 100%;
    }

    &__copy {
        @include pnp-button;
        width: fit-content;
        margin-top: 16px;
    }
}

.feature-branch-alert {
    @include pnp-svg-icon-classes;
    //background: $grey-10;
    color: $black;

    &--show{
        display: block;

        .feature-branch-alert__footer{
            display: flex;
        }
    }

    &--toggled{
        background: $grey-10;
        .feature-branch-alert{
            &__header{
                display: block;
            }

            &__actions{
                display: flex
            }

            &__footer{
                padding: 16px;
                svg{
                    transform: rotate(-90deg)
                }
            }
            &__toggle{
                border-top: 1px solid $black;
            }
        }
    }

    &__header {
        @include pnp-max-width-container;
        display: none;
        font-size: 32px;
        text-align: center;
        padding: 32px 32px 0 32px;
    }

    &__actions {
        @include pnp-max-width-container;
        display: none;
        justify-content: center;
        margin-top: 16px;
    }

    &__switcher {
        @include pnp-button;
        margin-right: 16px;
    }

    &__reset,
    &__hide {
        @include pnp-button;
        margin-right: 16px;
    }

    &__footer{
        display: none;
        justify-content: center;

        svg{
            transform: rotate(90deg)
        }
    }
    &__toggle{
        @include pnp-reset-button;
        display: flex;
        justify-content: center;
        width: 100px;
        background: $grey-10;
        border: 1px solid $black;
        border-top: 0;
    }
}

.feature-branch-pop-out {
    @include pnp-svg-icon-classes;

    display: flex;
    flex-direction: row-reverse;
    position: fixed;
    top: 100px;
    right: 10px;
    z-index: $feature-branch-picker;

    &--active {
        .feature-branch-pop-out__contents {
            display: flex;
        }
    }

    &__trigger {
        @include pnp-button;
        width: 64px;
        height: 48px;
    }

    &__contents {
        @include pnp-scroll-y;
        left: -500px;
        display: none;
        flex-direction: column;
        margin-right: 16px;
        border: 2px solid $grey-10;
        background: $white;
        padding: 16px;
    }

    &__reset,
    &__set {
        @include pnp-button;
        margin-bottom: 16px;
    }
}
