// Common transition options for any primary nav transitions
@mixin anchor-nav-transition() {
    transition: background-color 0.25s ease, color 0.25s ease;
    @include pnp-media-prefers-reduced-motion {
        transition: none;
    }
}

// Common hover and focus effect for navigation items and actions
@mixin anchor-nav-hover-focus($backgroundColor: $grey-10) {
    background: $backgroundColor;
    outline-offset: -2px;
}

.anchor-nav-wrapper {
    @include pnp-print-hide;
    position: relative; // This is the anchor point of the mega-menu
    z-index: $anchor-nav;
    padding: 1.5rem 0;
    color: $primary-a;
    background-color: $white;

    &--compact {
        .anchor-nav-item {
            position: relative;

            &--active,
            &--has-children:hover,
            &--active,
            &--has-children:focus {
                // Arrow connector for the menu
                &::after {
                    position: absolute;
                    bottom: -20px;
                    left: calc(50% - 6px);
                    width: 0;
                    height: 0;
                    border-right: 12px solid transparent;
                    border-bottom: 15px solid $grey-10;
                    border-left: 12px solid transparent;
                    content: '';
                }
            }

            .anchor-nav-item__child-menu {
                top: 61px;
                left: var(--menuOffsetNeeded, 0);
            }
        }
    }

    &--transparent {
        background-color: transparent;

        .anchor-nav-item {
            &:hover,
            &:focus {
                .anchor-nav-item {
                    &__link {
                        background-color: transparent;
                    }
                }
            }

            &__link {
                background-color: transparent;
            }
        }
    }
}

.anchor-nav {
    @include pnp-max-width-container;
    @include pnp-svg-icon-classes;

    display: flex;
    flex-wrap: wrap; // Better to wrap than hide controls
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;

    @include pnp-media-breakpoint-up(xl) {
        // If desktop and no nav items are being shown space the logo and actions apart
        &:not(.anchor-nav--desktop-burger) {
            justify-content: initial;
        }
    }

    // Hide primary nav if desktop burger is enabled
    &--desktop-burger {
        .anchor-nav {
            &__landmark {
                display: none;
            }

            &__left,
            &__right {
                flex: inherit;
            }
        }
    }
    // If a desktop burger is not being shown, hide the menu on desktop breakpoint
    @at-root .anchor-nav:not(.anchor-nav--desktop-burger) {
        .hamburger-landmark {
            @include pnp-media-breakpoint-up(xl) {
                display: none;
            }
        }
    }

    &__left {
        // Flex growth ratio to center the level 1 navigation
        @include pnp-media-breakpoint-up(xl) {
            flex: 1 1 0;
        }
    }

    // Site logo
    &__logo {
        display: block;

        &-image {
            max-width: 40vw;
            height: 2.5rem;
            object-fit: contain;
            @include pnp-media-breakpoint-up(lg) {
                height: 4rem;
            }
        }
    }

    // Level 1 navigation list
    &__landmark {
        display: none;
        flex: 6 1 0;
        @include pnp-media-breakpoint-up(xl) {
            display: block;
        }
    }

    &__list {
        @include pnp-list-reset;
        display: flex;
        align-items: center;

        // Alignment options
        &--center {
            justify-content: center;
        }

        &--right {
            justify-content: flex-end;
        }
    }

    // Right side action area for search, hamburger, login ect
    &__right {
        display: flex;
        justify-content: flex-end;

        // If the right side contains more than the hamburger menu or the menu is set to align center. This makes the left and right elements the same width.
        &--with-items {
            // Flex growth ratio to center the level 1 navigation
            @include pnp-media-breakpoint-up(xl) {
                flex: 1 1 0;
            }
        }
    }

    // Left or right side action buttons / links
    &__action {
        @include pnp-reset-button; // Assumed most 'actions' will be buttons, but this mixin also works on links
        @include anchor-nav-transition; // Use the common primary nav background and colour transition
        padding: 0.75rem 1rem;
        color: $primary-a;
        background-color: $white;
        cursor: pointer;

        @include pnp-media-breakpoint-down(md) {
            &:not(.anchor-nav__action--menu):not(.anchor-nav__action--search) {
                display: none;
            }
        }

        &:not(:only-child):not(:last-child) {
            margin-right: 0.75rem;
        }

        &:hover,
        &:focus {
            @include anchor-nav-hover-focus; // Use common hover & focus effect
        }

        // Make the action look like a button
        &--button {
            @include pnp-button;
            /* margin: auto; */
        }

        // Add an icon to the button
        &--with-icon {
            display: inline-flex;
            align-items: center;

            svg {
                margin-right: 1rem;
            }
        }
    }
}

.anchor-nav-icon {
    width: 24px;
    height: 24px;
    fill: currentColor;
}

// Level 1 navigation item
.anchor-nav-item {
    display: flex;

    &--active,
    &:hover,
    &:focus-within {
        .anchor-nav-item__link {
            @include anchor-nav-hover-focus; // Use common hover & focus effect
        }

        .anchor-nav-item__link:not(.anchor-nav-item__link--home) {
            &::after {
                width: 100%;
                opacity: 1;
            }
        }
    }

    // Link to the level 1 page
    &__link {
        @include pnp-typeface(text-18, false);
        @include anchor-nav-transition; // Use the common primary nav background and colour transition

        position: relative;
        display: block;
        padding: 1rem 1.5rem;
        color: $primary-a;

        text-decoration: none;
        background-color: $white;

        @at-root .anchor-nav-item--has-children & {
            // Add extra right padding to fit the 'has child' chevron
            padding-right: 2.5rem;
        }

        // Colour accent under nav items
        &:not(.anchor-nav-item__link--home) {
            &::after {
                position: absolute;
                bottom: -5px;
                left: 50%;
                width: 0%;
                height: 5px;
                background: $primary-a;
                transform: translate(-50%, 0);
                opacity: 0;
                transition: background-color, transform 0.25s ease;
                content: '';

                @include pnp-media-prefers-reduced-motion {
                    transition: none;
                    animation: none;
                }
            }
        }
    }

    // Expander accessibility control to open mega-menu via keyboard
    &__expander-wrapper {
        position: relative; // Relative anchor for the expander icon
        display: flex;
        align-items: center; // Center the icon in the middle of the text

        svg {
            transform: rotate(90deg);
        }
    }

    &__expander {
        @include pnp-reset-button;
        position: absolute;
        right: 10px;
        pointer-events: none;
    }
}

.anchor-nav-item__child-menu {
    position: absolute;
    top: 85px;
    left: 0;
    z-index: $mega-menu;
    visibility: hidden;
    opacity: 0;
}

.anchor-nav-item:hover .anchor-nav-item__child-menu,
.anchor-nav-item--active .anchor-nav-item__child-menu,
.anchor-nav-item:focus .anchor-nav-item__child-menu,
.anchor-nav-item--active .anchor-nav-item__child-menu {
    visibility: visible;
    opacity: 1;
}
