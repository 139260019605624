.card {
    @include pnp-svg-icon-classes;

    &__inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
        color: $black;
        background: $grey-10;
        cursor: pointer;

        &:hover,
        &:focus,
        &:focus-within {
            .card__image {
                transform: scale(1.05);
            }
        }

        &--palette {
            &-a {
                @include pnp-palette-colours(palette-a);
            }

            &-b {
                @include pnp-palette-colours(palette-b);
            }
        }
    }

    &-intro {
        display: flex;
        justify-content: space-between;
    }

    &__link-all {
        @include pnp-link-text;
        min-width: 6rem;
        text-align: right;
    }

    &__image-wrapper {
        @include pnp-generate-overlay();
        position: relative;
        height: 180px;
        overflow: hidden;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.5s ease;

        @include pnp-media-prefers-reduced-motion {
            transition: none;
            animation: none;
        }
    }

    &__contents {
        flex: 1 1 auto;
        padding: 1.75rem;
    }

    &__link {
        color: inherit;
        text-decoration: none;
    }

    &__title {
        @include pnp-reset-heading;
        @include pnp-typeface(heading-32-bold, true);
        color: inherit;
    }

    &__teaser {
        @include pnp-typeface(text-18, true);
    }

    &__footer {
        display: flex;
        padding-top: 25px;

        .card__cta-arrow {
            @include pnp-typeface(text-18, false);
            margin-left: 10px;
        }
    }

    &__cta {
        @include pnp-typeface(text-18, false);
        display: flex;
        align-items: center;
        text-decoration: underline;

        .svg-icon {
            width: 14px;
            height: 14px;
            margin-left: 16px;
        }
    }
}

.card--image-behind {
    position: relative;

    .card__image-wrapper {
        position: absolute;
        top: 0;
        left: calc(var(--pnp-gutter-x) * 0.5);
        z-index: $card-image-behind;
        width: calc(100% - (var(--pnp-gutter-x)));
        height: 100%;
    }

    .card__contents,
    .card__footer {
        z-index: $card-text-infront;
        color: $white;
    }
}

.card-grid-wrapper {
    @include pnp-make-container();
    padding-right: 0;
    padding-left: 0;

    &--palette-bg {
        &-a {
            @include pnp-palette-colours(palette-a);
        }

        &-b {
            @include pnp-palette-colours(palette-b);
        }
    }
}

.card-grid {
    @include pnp-list-reset();
    @include pnp-make-row();

    .card {
        @include pnp-make-col-ready();
        @include no-gutter-first-row(1);
        // Add gutter to the top as that is more predictable for removing later
        margin-top: $pnp-grid-gutter-width;
    }
}

.card-grid--row-automatic .card {
    @include pnp-media-breakpoint-up(sm) {
        @include pnp-make-col(6);
        @include no-gutter-first-row(2);
    }

    @include pnp-media-breakpoint-up(lg) {
        @include pnp-make-col(4);
        @include no-gutter-first-row(3);
    }

    @include pnp-media-breakpoint-up(xl) {
        @include pnp-make-col(3);
        @include no-gutter-first-row(4);

        // prettier-ignore
        @at-root .page-layout--content, .page-layout--content-with-nav {
            .card-grid .card {
                @include pnp-make-col(4);
                @include no-gutter-first-row(3);

                //Restore gutter for 4th row that was removed in base xl style
                &:nth-child(4) {
                    margin-top: $pnp-grid-gutter-width;
                }
            }
        }
    }
}

.card-grid--row-two .card {
    @include pnp-media-breakpoint-up(sm) {
        @include pnp-make-col(6);
        @include no-gutter-first-row(2);
    }
}

.card-grid--row-three .card {
    @include pnp-media-breakpoint-up(sm) {
        @include pnp-make-col(6);
        @include no-gutter-first-row(2);
    }

    @include pnp-media-breakpoint-up(lg) {
        @include pnp-make-col(4);
        @include no-gutter-first-row(3);
    }
}

.page-layout--landing {
    .template {
        .card-grid-wrapper {
            @include pnp-background-breakout;

            .card-grid-container {
                max-width: 1400px;
                margin: 0 auto;
                padding: 0 1rem;
            }
        }
    }
}
